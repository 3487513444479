import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import InputText from "../Fields/InputText";
import InputDate from "../Fields/InputDate";

export default class CreditCheckNotSSN extends React.Component {
  static propTypes = {
    customerInformation: PropTypes.object,
    labels: PropTypes.object,
    changeHandler: PropTypes.func,
    formInformation: PropTypes.object,
    checkHandler: PropTypes.func,
    params: PropTypes.object,
  };

  state = {
    customerFirstName: false,
    customerLastName: false,
    customerStreet: false,
    customerHouseNumber: false,
    customerCity: false,
    customerZipCode: false,
    dateOfBirth: false,
  };

  componentDidMount() {
    this.setState(this.checkFieldsFinished());
  }

  checkFieldsFinished = () => {
    let newState = this.state;
    for (let key in newState) {
      if (
        this.props.customerInformation[key] !== undefined &&
        this.props.customerInformation[key] !== "" &&
        this.props.customerInformation[key] !== null
      ) {
        newState[key] = true;
      }
    }
    return newState;
  };

  handleChange = (value, id) => {
    let newState = this.state;
    let data = {};
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Customer Information</Typography>
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="customerFirstName"
            label="First name"
            defaultValue={this.props.customerInformation.customerFirstName}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerFirstName
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="customerLastName"
            label="Last name"
            defaultValue={this.props.customerInformation.customerLastName}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerLastName
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="customerStreet"
            label="Street"
            defaultValue={this.props.customerInformation.customerStreet}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerStreet
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="customerHouseNumber"
            label="House Number"
            defaultValue={this.props.customerInformation.customerHouseNumber}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerHouseNumber
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <InputText
            id="customerCity"
            label="City"
            defaultValue={this.props.customerInformation.customerCity}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid && !this.state.customerCity
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <InputText
            id="customerZipCode"
            label="Zip code"
            defaultValue={this.props.customerInformation.customerZipCode}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerZipCode
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <InputDate
            id={"dateOfBirth"}
            label={"Date of birth"}
            changeHandler={this.handleChange}
            date={this.props.customerInformation.dateOfBirth}
            required={true}
            maxDate={new Date()}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid && !this.state.dateOfBirth
                ? "invalid"
                : ""
            }
          />
        </Grid>
      </Grid>
    );
  }
}
