import React from "react";
import TextBox from "../Fields/TextBox";
import "./css/FormRes.css";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];

export default class Business extends React.Component {
  static propTypes = {
    labels: PropTypes.object,
    newDealerInformation: PropTypes.object,
    params: PropTypes.object,
    dateToString: PropTypes.func,
    payment: PropTypes.bool,
  };

  getSignatories = () => {
    for (const [key, value] of Object.entries(
      this.props.newDealerInformation.newDealerSignatories
    )) {
      console.log(`Key: ${key}, Value: ${value}`);
    }
  };

  render() {
    const size1 =
      this.props.params.lang.toLowerCase() === "uk" ||
      this.props.params.lang.toLowerCase() === "dk"
        ? 4
        : 3;
    const size2 =
      this.props.params.lang.toLowerCase() === "uk" ||
      this.props.params.lang.toLowerCase() === "dk"
        ? 6
        : 3;
    const noSize = this.props.params.lang.toLowerCase() === "no" ? 4 : 3;
    const ukSize = this.props.params.lang.toLowerCase() === "uk" ? 3 : 4;
    return (
      <>
        <Grid container spacing={2} className="CustomerInformation formPadding">
          <Grid item xs={noSize}>
            <TextBox
              title={this.props.labels.official_name}
              content={this.props.newDealerInformation.newDealerName}
            />
          </Grid>
          {this.props.params.lang.toLowerCase() !== "no" && (
            <Grid item xs={noSize}>
              <TextBox
                title={this.props.labels.tax_number}
                content={this.props.newDealerInformation.taxNumber}
              />
            </Grid>
          )}
          <Grid item xs={noSize}>
            <TextBox
              title={this.props.labels.org_number}
              content={this.props.newDealerInformation.organizationNumber}
            />
          </Grid>
          <Grid item xs={noSize}>
            <TextBox
              title={this.props.labels.husqvarna_number}
              content={this.props.newDealerInformation.husqvarnaNumber}
            />
          </Grid>
          {this.props.params.lang.toLowerCase() === "uk" && (
            <Grid item xs={3}>
              <TextBox
                title={this.props.labels.fca_number}
                content={this.props.newDealerInformation.fcaNumber}
              />
            </Grid>
          )}
          <Grid item xs={ukSize}>
            <TextBox
              title={this.props.labels.dealer_address}
              content={this.props.newDealerInformation.dealerAddress}
            />
          </Grid>
          <Grid item xs={ukSize}>
            <TextBox
              title={this.props.labels.label_zip_code}
              content={this.props.newDealerInformation.nd_zipCode}
            />
          </Grid>
          <Grid item xs={ukSize}>
            <TextBox
              title={this.props.labels.label_city}
              content={this.props.newDealerInformation.nd_city}
            />
          </Grid>
          {this.props.payment && (
            <>
              <Grid item xs={size1}>
                <TextBox
                  title={this.props.labels.bank_name}
                  content={this.props.newDealerInformation.bankName}
                />
              </Grid>
              <Grid item xs={size1}>
                <TextBox
                  title={this.props.labels.iban_number}
                  content={this.props.newDealerInformation.ibanNumber}
                />
              </Grid>
              <Grid item xs={size1}>
                <TextBox
                  title={this.props.labels.bic_swift}
                  content={this.props.newDealerInformation.bicSwiftCode}
                />
              </Grid>
              <Grid item xs={size2}>
                <TextBox
                  title={this.props.labels.bank_number}
                  content={this.props.newDealerInformation.bankNumber}
                />
              </Grid>
            </>
          )}
          {this.props.params.lang.toLowerCase() === "uk" &&
            this.props.payment && (
              <Grid item xs={6}>
                <TextBox
                  title={this.props.labels.sort_code}
                  content={this.props.newDealerInformation.sortCode}
                />
              </Grid>
            )}
          {this.props.params.lang.toLowerCase() === "dk" &&
            this.props.payment && (
              <Grid item xs={6}>
                <TextBox
                  title={this.props.labels.registration_number}
                  content={this.props.newDealerInformation.registrationNumber}
                />
              </Grid>
            )}

          <Grid item xs={3}>
            <TextBox
              title={this.props.labels.label_invoice_email}
              content={this.props.newDealerInformation.invoiceEmail}
            />
          </Grid>
          <Grid item xs={3}>
            <TextBox
              title={
                this.props.labels.label_customer_name +
                " " +
                this.props.labels.contact_person
              }
              content={this.props.newDealerInformation.contactPerson}
            />
          </Grid>
          <Grid item xs={3}>
            <TextBox
              title={
                this.props.labels.label_email +
                " " +
                this.props.labels.contact_person
              }
              content={this.props.newDealerInformation.emailContactPerson}
            />
          </Grid>
          <Grid item xs={3}>
            <TextBox
              title={
                this.props.labels.phone_number +
                " " +
                this.props.labels.contact_person
              }
              content={this.props.newDealerInformation.phoneContactPerson}
            />
          </Grid>
        </Grid>
        {Object.keys(this.props.newDealerInformation.newDealerSignatories).map(
          (id) => {
            return (
              <Grid
                container
                spacing={2}
                className="CustomerInformation formPadding"
              >
                <Grid item xs={4}>
                  <TextBox
                    title={this.props.labels.label_signatory_name}
                    content={
                      this.props.newDealerInformation.newDealerSignatories[id]
                        .nameSignatoryNewDealer
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    title={
                      this.props.labels.label_email +
                      " " +
                      this.props.labels.label_signatory_name
                    }
                    content={
                      this.props.newDealerInformation.newDealerSignatories[id]
                        .emailSignatoryNewDealer
                    }
                  />
                </Grid>
                {socialSecurityNumberCountries.includes(
                  this.props.params.lang.toLowerCase()
                ) ? (
                  <Grid item xs={4}>
                    <TextBox
                      title={
                        this.props.labels.social_security_number +
                        " " +
                        this.props.labels.label_signatory_name
                      }
                      content={
                        this.props.newDealerInformation.newDealerSignatories[id]
                          .socialSecurityNumberSignatory
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <TextBox
                      title={
                        this.props.labels.social_security_number +
                        " " +
                        this.props.labels.label_signatory_name
                      }
                      content={this.props.dateToString(
                        this.props.newDealerInformation.newDealerSignatories[id]
                          .dateOfBirthSignatory
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            );
          }
        )}
      </>
    );
  }
}
