export const cssStyles = `
.contract-body {
  width: 100%;
  font-family: Arial, sans-serif;
}

.contract-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contract-td {
  padding: 4px;
  border: 1px solid black;
}

.contract-table-header{
  margin-bottom: 2px;
  margin-top: 0px;
  text-align: left;
}

.contract-parties {
  width: 100%;
  margin-top: 20px;
}

.contract-align-center{
    text-align: center;
}

.contract-border-thick {
  border-left: 2px solid black;
}

.contract-table{
  border-collapse: collapse;
  width: 100%;
}

.contract-third-space{
  width: 33%;
}

.contract-half-space{
  width: 50%;
}

.contract-quater-space{
  width: 25%;
}

.contract-three-quater-space{
  width: 75%;
}

.contract-half-quater-space{
    width: 12.5%;
}

.contract-helper-text {
  font-size: 10px;
  margin-bottom: -10px;
  margin-top: -2px;
}

.contract-small-margin-top{
  margin-top: 5px;
}

.contract-no-margin-top{
  margin-top: -20px;
}

.margin-top-large{
  margin-top: 40px;
}

.page-break{
   page-break-after: always;
}

.contract-min-width{
  min-width: 100px;
}

p {
  font-size: 12px;
  min-height: 12px;
}


`;
