import React from "react";

import "./css/NotFound.css";

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="notFoundWrapper">
        <h1 className="largeText">404</h1>
        <h3>Page not found</h3>
        <p className="smallText">
          The entered url is not recognized by the server
        </p>
      </div>
    );
  }
}
