import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Button,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import InputText from "../../Fields/InputText";
import Signatory from "../Customer/Signatory";
import "../css/NewDealer.css";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];

export default class NewDealer extends React.Component {
  static propTypes = {
    newDealer: PropTypes.object,
    labels: PropTypes.object,
    changeHandler: PropTypes.func,
    formInformation: PropTypes.object,
    checkHandler: PropTypes.func,
    params: PropTypes.object,
    addSignatory: PropTypes.func,
    removeSignatory: PropTypes.func,
    payment: PropTypes.bool,
  };

  state = {
    newDealerName: false,
    organizationNumber: false,
    taxNumber: false,
    emailContactPerson: false,
    husqvarnaNumber: false,
    dealerAddress: false,
    nd_zipCode: false,
    nd_city: false,
    bankName: false,
    ibanNumber: false,
    bicSwiftCode: false,
    bankNumber: false,
    sortCode: false,
    registrationNumber: false,
    signatory: false,
    fcaNumber: false,
  };

  componentDidMount() {
    this.setState(this.checkFieldsFinished());
  }

  checkFieldsFinished = () => {
    let newState = this.state;
    for (let key in newState) {
      if (
        (this.props.newDealer[key] !== undefined &&
          this.props.newDealer[key] !== "" &&
          this.props.newDealer[key] !== null) ||
        (key === "fcaNumber" &&
          this.props.params.lang.toLowerCase() !== "uk") ||
        (key === "sortCode" && this.props.params.lang.toLowerCase() !== "uk") ||
        (key === "taxNumber" &&
          this.props.params.lang.toLowerCase() === "no") ||
        (key === "registrationNumber" &&
          this.props.params.lang.toLowerCase() !== "dk") ||
        ((key === "bankName" ||
          key === "ibanNumber" ||
          key === "bicSwiftCode" ||
          key === "bankNumber" ||
          key === "sortCode" ||
          key === "registrationNumber") &&
          !this.props.payment)
      ) {
        newState[key] = true;
      }
    }
    let signatories = this.props.newDealer.newDealerSignatories;
    newState["signatory"] = this.signatoryFinished(signatories);
    return newState;
  };

  signatoryFinished = (signatories) => {
    let signatory = true;
    for (const key1 in signatories) {
      for (const key2 in signatories[key1]) {
        if (
          signatories[key1][key2] === undefined ||
          signatories[key1][key2] === "" ||
          signatories[key1][key2] === null
        ) {
          if (
            (key2 === "dateOfBirthSignatory" &&
              socialSecurityNumberCountries.includes(
                this.props.params.lang.toLowerCase()
              )) ||
            (key2 === "socialSecurityNumberSignatory" &&
              !socialSecurityNumberCountries.includes(
                this.props.params.lang.toLowerCase()
              ))
          ) {
            continue;
          }
          signatory = false;
        }
      }
    }
    return signatory;
  };

  generateSignatory = (id) => {
    return (
      <Signatory
        signatory={this.props.newDealer.newDealerSignatories[id]}
        formInformation={this.props.formInformation}
        labels={this.props.labels}
        id={id}
        changeHandler={this.handleSignatoryChange}
        key={id}
        className={"invalid"}
        formType={this.props.formType}
        onBoarding={true}
        newDealer={this.props.newDealer}
        params={this.props.params}
      />
    );
  };

  handleChange = (value, id) => {
    if (typeof id !== "string") {
      if (value.startsWith(id.dialCode + "0")) {
        value = id.dialCode + value.slice((id.dialCode + "0").length);
      }
      id = "phoneContactPerson";
      if (!value.startsWith("+")) {
        value = "+" + value;
      }
    }
    let newState = this.checkFieldsFinished();
    if (newState.hasOwnProperty(id)) {
      newState[id] = value !== "";
    }
    let data = {};
    data["newDealerFinished"] =
      Object.values(newState).every((finished) => {
        return finished;
      }) && this.props.newDealer.verifyNewDealer;
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  handleCheck = (event) => {
    let data = {};
    let newState = this.checkFieldsFinished();
    this.props.checkHandler(event);
    data["newDealerFinished"] =
      Object.values(newState).every((finished) => {
        return finished;
      }) && this.props.newDealer.verifyNewDealer;
    this.props.changeHandler(data);
  };

  handleSignatoryChange = (value, id, signatoryId) => {
    let data = {};
    let newState = this.checkFieldsFinished();

    let signatories = this.props.newDealer.newDealerSignatories;
    let signatory = signatories[signatoryId];
    signatory[id] = value;
    signatories[signatoryId] = signatory;
    data["newDealerSignatories"] = signatories;

    newState["signatory"] = this.signatoryFinished(signatories);
    data["newDealerFinished"] =
      Object.values(newState).every((finished) => {
        return finished;
      }) && this.props.newDealer.verifyNewDealer;

    this.props.changeHandler(data);
    this.setState(newState);
  };

  addRemoveSignatory = (id) => {
    if (id === "remove") {
      this.props.removeSignatory(true);
    }
    if (id === "add") {
      this.props.addSignatory(true);
    }
    let data = {};
    let newState = this.checkFieldsFinished();
    let signatories = this.props.newDealer.newDealerSignatories;
    newState["signatory"] = this.signatoryFinished(signatories);
    data["newDealerFinished"] =
      Object.values(newState).every((finished) => {
        return finished;
      }) && this.props.newDealer.verifyNewDealer;

    this.props.changeHandler(data);
    this.setState(newState);
  };

  render() {
    const size1 =
      this.props.params.lang.toLowerCase() === "uk" ||
      this.props.params.lang.toLowerCase() === "dk"
        ? 4
        : 3;
    const size2 =
      this.props.params.lang.toLowerCase() === "uk" ||
      this.props.params.lang.toLowerCase() === "dk"
        ? 6
        : 3;
    const size3 = this.props.params.lang.toLowerCase() === "uk" ? 3 : 4;
    const countryCode =
      this.props.params.lang.toLowerCase() === "uk"
        ? "gb"
        : this.props.params.lang.toLowerCase();
    const noSize = this.props.params.lang.toLowerCase() === "no" ? 4 : 3;
    return (
      <div>
        <Grid container spacing={2} className="customer_info">
          <Grid item xs={12}>
            <Typography variant="h5">
              {this.props.labels.company_information}
            </Typography>
            <FormHelperText>
              {this.props.labels.company_information_helper}
            </FormHelperText>
          </Grid>
          <Grid item xs={noSize}>
            <InputText
              id="newDealerName"
              label={this.props.labels.official_name}
              defaultValue={this.props.newDealer.newDealerName}
              tooltip={this.props.labels.tooltip_official_name}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.newDealerName
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          {this.props.params.lang.toLowerCase() !== "no" && (
            <Grid item xs={noSize}>
              <InputText
                id="taxNumber"
                label={this.props.labels.tax_number}
                defaultValue={this.props.newDealer.taxNumber}
                changeHandler={this.handleChange}
                sx={{ width: "100%" }}
                required={true}
                className={
                  this.props.formInformation.showInvalid &&
                  !this.state.taxNumber
                    ? "invalid"
                    : ""
                }
              />
            </Grid>
          )}
          <Grid item xs={noSize}>
            <InputText
              id="organizationNumber"
              label={this.props.labels.org_number}
              defaultValue={this.props.newDealer.organizationNumber}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.organizationNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={noSize}>
            <InputText
              id="husqvarnaNumber"
              label={this.props.labels.husqvarna_number}
              defaultValue={this.props.newDealer.husqvarnaNumber}
              tooltip={this.props.labels.tooltip_husqvarna_number}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.husqvarnaNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          {this.props.params.lang.toLowerCase() === "uk" && (
            <Grid item xs={3}>
              <InputText
                id="fcaNumber"
                label={this.props.labels.fca_number}
                defaultValue={this.props.newDealer.fcaNumber}
                tooltip={this.props.labels.tooltip_fca_number}
                changeHandler={this.handleChange}
                sx={{ width: "100%" }}
                required={true}
                className={
                  this.props.formInformation.showInvalid &&
                  !this.state.fcaNumber
                    ? "invalid"
                    : ""
                }
              />
            </Grid>
          )}
          <Grid item xs={size3}>
            <InputText
              id="dealerAddress"
              label={this.props.labels.dealer_address}
              defaultValue={this.props.newDealer.dealerAddress}
              tooltip={this.props.labels.tooltip_dealer_address}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.dealerAddress
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={size3}>
            <InputText
              id="nd_zipCode"
              label={this.props.labels.label_zip_code}
              defaultValue={this.props.newDealer.nd_zipCode}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid && !this.state.nd_zipCode
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={size3}>
            <InputText
              id="nd_city"
              label={this.props.labels.label_city}
              defaultValue={this.props.newDealer.nd_city}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid && !this.state.nd_city
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          {this.props.payment && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {this.props.labels.payment_information}
                </Typography>
                <FormHelperText>
                  {this.props.labels.payment_information_helper}
                </FormHelperText>
              </Grid>
              <Grid item xs={size1}>
                <InputText
                  id="bankName"
                  label={this.props.labels.bank_name}
                  defaultValue={this.props.newDealer.bankName}
                  changeHandler={this.handleChange}
                  sx={{ width: "100%" }}
                  required={true}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.bankName
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={size1}>
                <InputText
                  id="ibanNumber"
                  label={this.props.labels.iban_number}
                  defaultValue={this.props.newDealer.ibanNumber}
                  changeHandler={this.handleChange}
                  sx={{ width: "100%" }}
                  required={true}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.ibanNumber
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={size1}>
                <InputText
                  id="bicSwiftCode"
                  label={this.props.labels.bic_swift}
                  defaultValue={this.props.newDealer.bicSwiftCode}
                  changeHandler={this.handleChange}
                  sx={{ width: "100%" }}
                  required={true}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.bicSwiftCode
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={size2}>
                <InputText
                  id="bankNumber"
                  label={this.props.labels.bank_number}
                  defaultValue={this.props.newDealer.bankNumber}
                  changeHandler={this.handleChange}
                  sx={{ width: "100%" }}
                  required={true}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.bankNumber
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              {this.props.params.lang.toLowerCase() === "uk" && (
                <Grid item xs={6}>
                  <InputText
                    id="sortCode"
                    label={this.props.labels.sort_code}
                    defaultValue={this.props.newDealer.sortCode}
                    changeHandler={this.handleChange}
                    sx={{ width: "100%" }}
                    required={true}
                    className={
                      this.props.formInformation.showInvalid &&
                      !this.state.sortCode
                        ? "invalid"
                        : ""
                    }
                  />
                </Grid>
              )}
              {this.props.params.lang.toLowerCase() === "dk" && (
                <Grid item xs={6}>
                  <InputText
                    id="registrationNumber"
                    label={this.props.labels.registration_number}
                    defaultValue={this.props.newDealer.registrationNumber}
                    changeHandler={this.handleChange}
                    sx={{ width: "100%" }}
                    required={true}
                    className={
                      this.props.formInformation.showInvalid &&
                      !this.state.registrationNumber
                        ? "invalid"
                        : ""
                    }
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h5">{this.props.labels.contact}</Typography>
            <FormHelperText>{this.props.labels.contact_helper}</FormHelperText>
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="invoiceEmail"
              label={this.props.labels.invoice_email}
              defaultValue={this.props.newDealer.invoiceEmail}
              tooltip={this.props.labels.tooltip_invoice_email}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="contactPerson"
              label={
                this.props.labels.label_customer_name +
                " " +
                this.props.labels.contact_person
              }
              defaultValue={this.props.newDealer.contactPerson}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="emailContactPerson"
              label={
                this.props.labels.label_email +
                " " +
                this.props.labels.contact_person
              }
              defaultValue={this.props.newDealer.emailContactPerson}
              tooltip={this.props.labels.tooltip_contact_person_email}
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.emailContactPerson
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={3}>
            <PhoneInput
              onlyCountries={[countryCode]}
              country={countryCode}
              countryCodeEditable={false}
              autoFormat={true}
              specialLabel={
                this.props.labels.phone_number +
                " " +
                this.props.labels.contact_person
              }
              value={this.props.newDealer.phoneContactPerson}
              onChange={this.handleChange}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">
              {this.props.labels.label_signatory_name}
            </Typography>
            <FormHelperText>
              {this.props.labels.label_signatory_name_helper}
            </FormHelperText>
          </Grid>
        </Grid>
        <br></br>
        <div>
          {Object.keys(this.props.newDealer.newDealerSignatories).map((id) => {
            return this.generateSignatory(id);
          })}
        </div>
        <div className="row padding-Top paddingBottom">
          <div className="buttonPadding">
            <Button
              onClick={() => {
                this.addRemoveSignatory("add");
              }}
              variant="contained"
              sx={{
                backgroundColor: "#273A60",
              }}
              startIcon={<AddCircleOutlineIcon />}
            >
              {this.props.labels.label_add_signatory}
            </Button>
          </div>
          {Object.keys(this.props.newDealer.newDealerSignatories).length >
            1 && (
            <div className="buttonPadding">
              <Button
                onClick={() => {
                  this.addRemoveSignatory("remove");
                }}
                variant="contained"
                color="error"
                startIcon={<RemoveCircleOutlineIcon />}
              >
                {this.props.labels.label_remove_signatory}
              </Button>
            </div>
          )}
        </div>

        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              sx={{ width: "50%" }}
              control={
                <Checkbox
                  id="verify_new_dealer"
                  checked={this.props.newDealer.verifyNewDealer}
                  onChange={this.handleCheck}
                />
              }
              label={this.props.labels.verify + " *"}
            />
          </FormGroup>
        </Grid>
      </div>
    );
  }
}
