import React from "react";
import PropTypes from "prop-types";
import InputNumber from "../../component/Fields/InputNumber";
import ProductList from "../../component/Fields/ProductList";
import {
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import debounce from "lodash.debounce";

export default class FlexProProduct extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    number: PropTypes.string,
    productValues: PropTypes.object,
    formInformation: PropTypes.object,
    labels: PropTypes.object,
    variables: PropTypes.object,
    products: PropTypes.object,
    updateProduct: PropTypes.func,
    calculateNewFee: PropTypes.func,
  };

  productFinished = debounce(() => {
    const data = {
      finished:
        this.props.productValues.quantity > 0 &&
        this.props.productValues.price > 0 &&
        this.props.productValues.name !== null &&
        (this.props.productValues.upCare ||
          this.props.productValues.service > 0 ||
          this.props.productValues.ownServiceIncludes["noService"]),
    };
    this.props.updateProduct(data, this.props.id);
  }, 100);

  finished = (fieldId) => {
    switch (fieldId) {
      case "quantity":
        return this.props.productValues.quantity > 0;
      case "price":
        return this.props.productValues.price > 0;
      case "name":
        return this.props.productValues.name !== null;
      case "service":
        if (
          this.props.productValues.upCare ||
          this.props.productValues.ownServiceIncludes["noService"]
        ) {
          return true;
        } else {
          return this.props.productValues.service > 0;
        }
      default:
        return -1;
    }
  };

  /* Test to push again */

  /**
   *
   * @param {Number} value - The new value of the field
   * @param {String} id    - id of the field the value is from
   *
   * Updates the field with new value.
   */

  handleChange = (value, id) => {
    const products = this.props.products;
    const fieldId = id.split("_")[1];
    let data = { ownServiceIncludes: {} };
    if (fieldId === "quantity") {
      data["totalProductPrice"] = value * this.props.productValues.price;
    } else if (fieldId === "price") {
      data["totalProductPrice"] = value * this.props.productValues.quantity;
    } else if (fieldId === "name") {
      if (value === null || value === undefined) {
        data["service"] = 0;
        data["upCare"] = false;
        data.ownServiceIncludes["storage"] = false;
      } else {
        data["upCare"] = products[value].UpCare;
        data["service"] = products[value].UpCare
          ? parseFloat(products[value].service[0])
          : 0;
        data.ownServiceIncludes["storage"] = false;
      }
    }
    data[fieldId] = value;
    this.props.updateProduct(data, this.props.id);
    this.productFinished();
    this.props.calculateNewFee();
  };

  handleClick = (e) => {
    const id = e.target.id.split("_")[0];
    let data = { ownServiceIncludes: {} };
    if (
      id === "noService" ||
      !this.props.productValues.ownServiceIncludes.noService
    ) {
      data.ownServiceIncludes[id] = e.target.checked;
    }
    if (id === "noService" && e.target.checked) {
      data.ownServiceIncludes["consumables"] = false;
      data.ownServiceIncludes["other"] = false;
      data.ownServiceIncludes["education"] = false;
      data.ownServiceIncludes["storage"] = false;
    }
    this.props.updateProduct(data, this.props.id);
    this.productFinished();
    this.props.calculateNewFee();
  };

  render() {
    return (
      <Grid container spacing={2} className="Product">
        <Grid item xs={12}>
          <TextField
            id={this.props.id + "_title"}
            value={this.props.labels.product + " " + this.props.number}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={2}>
          <InputNumber
            label={this.props.labels.quantity}
            id={this.props.id + "_quantity"}
            defaultValue={this.props.productValues.quantity}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_quantity}
            className={
              this.props.formInformation.showInvalid &&
              !this.finished("quantity")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={8}>
          <ProductList
            id={this.props.id + "_name"}
            defaultValue={this.props.productValues.name}
            changeHandler={this.handleChange}
            label={this.props.labels.choose_a_product}
            products={this.props.products}
            tooltip={this.props.labels.tooltip_product}
            required={true}
            className={
              this.props.formInformation.showInvalid && !this.finished("name")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={2}>
          <InputNumber
            label={this.props.labels.your_chosen_price}
            id={this.props.id + "_price"}
            defaultValue={this.props.productValues.price}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_product_price}
            endAdornment={this.props.labels.currency}
            className={
              this.props.formInformation.showInvalid && !this.finished("price")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={2}>
          <InputNumber
            label={this.props.labels.residual_value}
            id={this.props.id + "_residual"}
            defaultValue={this.props.productValues.residual}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_residual_value}
            endAdornment="%"
            min={5}
            max={40}
          />
        </Grid>
        {!this.props.productValues.upCare &&
          !this.props.productValues.ownServiceIncludes["noService"] && (
            <Grid item xs={3}>
              <InputNumber
                label={this.props.labels.service_per_year}
                id={this.props.id + "_service"}
                defaultValue={this.props.productValues.service}
                changeHandler={this.handleChange}
                tooltip={this.props.labels.tooltip_service_per_year}
                endAdornment={this.props.labels.currency}
                required={true}
                className={
                  this.props.formInformation.showInvalid &&
                  !this.finished("service")
                    ? "invalid"
                    : ""
                }
              />
            </Grid>
          )}
        <Grid item xs={4}>
          <InputNumber
            label={this.props.labels.display_installation_title}
            id={this.props.id + "_installation"}
            defaultValue={this.props.productValues.installation}
            changeHandler={this.handleChange}
            endAdornment={this.props.labels.currency}
          />
        </Grid>
        {!this.props.productValues.upCare &&
        this.props.productValues.name !== null ? (
          <Grid item xs={3}>
            <TextField
              id={this.props.id + "_title"}
              value={this.props.labels.no_upcare}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        ) : null}
        {this.props.productValues.upCare ? (
          <Grid item xs={3}>
            <TextField
              id={this.props.id + "_title"}
              value={this.props.labels.upCare}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        ) : null}
        {!this.props.productValues.upCare && (
          <Grid item xs={12}>
            <Typography variant="h6">
              {this.props.labels.pick_service}
            </Typography>
          </Grid>
        )}
        <Box width="100%" />
        <Grid item xs={4}>
          {!this.props.productValues.upCare && (
            <FormGroup row={true}>
              <FormControlLabel
                control={<Checkbox id="storage_" onClick={this.handleClick} />}
                label={this.props.labels.winter}
                sx={{ width: "180px" }}
                checked={this.props.productValues.ownServiceIncludes["storage"]}
              />
              <FormControlLabel
                control={
                  <Checkbox id="consumables_" onClick={this.handleClick} />
                }
                label={this.props.labels.consumables}
                checked={
                  this.props.productValues.ownServiceIncludes["consumables"]
                }
              />
              <FormControlLabel
                control={
                  <Checkbox id="education_" onClick={this.handleClick} />
                }
                label={this.props.labels.education}
                sx={{ width: "180px" }}
                checked={
                  this.props.productValues.ownServiceIncludes["education"]
                }
              />
              <FormControlLabel
                control={<Checkbox id="other_" onClick={this.handleClick} />}
                label={this.props.labels.other}
                checked={this.props.productValues.ownServiceIncludes["other"]}
              />
              <FormControlLabel
                control={
                  <Checkbox id="noService_" onClick={this.handleClick} />
                }
                label={this.props.labels.no_service}
                checked={
                  this.props.productValues.ownServiceIncludes["noService"]
                }
              />
            </FormGroup>
          )}
        </Grid>
      </Grid>
    );
  }
}
