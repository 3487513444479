import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import InputText from "../../Fields/InputText";
import PhoneInput from "react-phone-input-2";
import LocationInput from "../../Fields/Location";
import "react-phone-input-2/lib/material.css";

const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export default class CustomerInformationPrivate extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func,
    customerInformation: PropTypes.object,
    labels: PropTypes.object,
    params: PropTypes.object,
  };

  state = {
    customerFirstName: false,
    customerLastName: false,
    customerEmail: false,
    customerPhone: false,
    customerStreet: false,
    customerHouseNumber: false,
    customerZipCode: false,
    customerCity: false,
  };

  componentDidMount() {
    this.setState(this.checkFieldsFinsished());
  }
  checkFieldsFinsished = () => {
    let newState = this.state;
    for (let key in newState) {
      if (
        this.props.customerInformation[key] !== undefined &&
        this.props.customerInformation[key] !== ""
      ) {
        newState[key] = true;
      }
    }
    return newState;
  };

  locationChangeHandler = async (info) => {
    this.props.changeHandler(info);
    let newState = this.checkFieldsFinsished();

    const allCustomerInfoValid = Object.values(newState).every(
      (value) => value === true
    );

    info["privateFinished"] = allCustomerInfoValid;
    this.props.changeHandler(info);
    this.setState(newState);
  };

  handleChange = (value, id) => {
    if (typeof id !== "string") {
      if (value.startsWith(id.dialCode + "0")) {
        value = id.dialCode + value.slice((id.dialCode + "0").length);
      }
      id = "customerPhone";
      if (!value.startsWith("+")) {
        value = "+" + value;
      }
    }
    let newState = this.checkFieldsFinsished();
    let subString1 = "";
    let subString2 = "";
    newState[id] = id.includes("email") ? mailformat.test(value) : value !== "";
    let data = {};
    data["privateFinished"] = Object.values(newState).every((finished) => {
      return finished;
    });
    if (
      id === "customerZipCode" &&
      value.length > 4 &&
      this.props.params.lang.toLowerCase() === "uk"
    ) {
      subString1 = value.substring(0, 4);
      subString2 = value.substring(4);
      value = subString1 + " " + subString2;
    }
    if (id !== "customerEmail") {
      value = value.toUpperCase();
    }
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  render() {
    const countryCode =
      this.props.params.lang.toLowerCase() === "uk"
        ? "gb"
        : this.props.params.lang.toLowerCase();
    return (
      <Grid container spacing={2} className="customer_info">
        <Grid item xs={3}>
          <InputText
            id="customerFirstName"
            label={this.props.labels.customer_first_name}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerFirstName}
            tooltip={this.props.labels.tooltip_customer_name}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerFirstName
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="customerLastName"
            label={this.props.labels.customer_last_name}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerLastName}
            tooltip={this.props.labels.tooltip_customer_name}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerLastName
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="customerEmail"
            label={this.props.labels.label_email}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerEmail}
            tooltip={this.props.labels.tooltip_customer_email}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerEmail
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <PhoneInput
            onlyCountries={[countryCode]}
            country={countryCode}
            countryCodeEditable={false}
            autoFormat={true}
            specialLabel={this.props.labels.label_phonenumber + " *"}
            value={this.props.customerInformation.customerPhone}
            onChange={this.handleChange}
            inputStyle={{
              width: "100%",

              borderColor:
                this.props.formInformation.showInvalid &&
                this.props.customerInformation.customerPhone.length < 5
                  ? "red"
                  : "",
            }}
          />
        </Grid>
        {this.props.params.lang.toLowerCase() === "fr" && (
          <Grid item xs={12}>
            <LocationInput
              id="address"
              label={this.props.labels.search_address}
              changeHandler={this.locationChangeHandler}
              tooltip={this.props.labels.tooltip_customer_address}
              params={this.props.params}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <InputText
            id="customerStreet"
            label={this.props.labels.customer_street}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerStreet}
            tooltip={this.props.labels.tooltip_customer_address}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerStreet
                ? "invalid"
                : ""
            }
          />
        </Grid>

        <Grid item xs={3}>
          <InputText
            id="customerHouseNumber"
            label={this.props.labels.customer_house_number}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerHouseNumber}
            tooltip={this.props.labels.tooltip_customer_address}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerHouseNumber
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="customerZipCode"
            label={this.props.labels.label_zip_code}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerZipCode}
            tooltip={this.props.labels.tooltip_zipcode}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerZipCode
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="customerCity"
            label={this.props.labels.label_city}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerCity}
            tooltip={this.props.labels.tooltip_city}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid && !this.state.customerCity
                ? "invalid"
                : ""
            }
          />
        </Grid>
      </Grid>
    );
  }
}
