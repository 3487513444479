import React from "react";
import PropTypes from "prop-types";
import { Grid, FormControlLabel, Checkbox, Typography } from "@mui/material";
import InputText from "../../Fields/InputText";

const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const hcsforms = ["leaseplus", "upcare", "flexpro"];

export default class DealerInformation extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func,
    customerInformation: PropTypes.object,
    formInformation: PropTypes.object,
    labels: PropTypes.object,
    params: PropTypes.object,
    checkHandler: PropTypes.func,
  };

  state = {
    dealerName: false,
    dealerEmail: false,
    dealerAdress: false,
    dealerZipCode: false,
    dealerCity: false,
  };

  componentDidMount() {
    let newState = this.state;
    if (
      hcsforms.includes(this.props.params.formType.toLowerCase()) &&
      this.props.params.comp === "hcs" &&
      this.props.formInformation.orderChecked
    ) {
      newState["customerNumber"] = false;
      newState["sellerName"] = false;
    }
    for (let key in newState) {
      if (
        this.props.customerInformation[key] !== undefined &&
        this.props.customerInformation[key] !== ""
      ) {
        newState[key] = true;
      }
    }
    this.setState(newState);
  }

  checkFieldsFinished() {
    let newState = this.state;
    Object.keys(this.state).forEach((key) => {
      if (
        (this.props.customerInformation[key] !== undefined &&
          this.props.customerInformation[key] !== null &&
          this.props.customerInformation[key] !== "") ||
        (this.props.formInformation.quoteChecked &&
          (key === "dealerAdress" ||
            key === "dealerZipCode" ||
            key === "dealerCity"))
      ) {
        newState[key] = true;
      }
    });
    return newState;
  }

  componentDidUpdate() {
    if (
      hcsforms.includes(this.props.params.formType.toLowerCase()) &&
      this.props.params.comp === "hcs" &&
      this.props.formInformation.orderChecked &&
      !this.state.hasOwnProperty("customerNumber") &&
      !this.state.hasOwnProperty("sellerName")
    ) {
      let data = {};
      let newState = this.checkFieldsFinished();

      newState["customerNumber"] = this.props.customerInformation.customerNumber
        ? true
        : false;
      newState["sellerName"] = this.props.customerInformation.sellerName
        ? true
        : false;
      this.setState(newState);

      data["dealerFinished"] = Object.values(newState).every((finished) => {
        return finished;
      });
      this.props.changeHandler(data);
    } else if (
      this.props.formInformation.quoteChecked &&
      this.state.hasOwnProperty("customerNumber") &&
      this.state.hasOwnProperty("sellerName")
    ) {
      let data = {};
      let newState = this.checkFieldsFinished();

      delete newState.customerNumber;
      delete newState.sellerName;
      this.setState(newState);

      data["dealerFinished"] = Object.values(newState).every((finished) => {
        return finished;
      });
      this.props.changeHandler(data);
    }
  }

  handleChange = (value, id) => {
    let newState = this.checkFieldsFinished();
    newState[id] = id.includes("email") ? mailformat.test(value) : value !== "";
    let data = {};
    data["dealerFinished"] = Object.values(newState).every((finished) => {
      return finished;
    });
    if (id !== "dealerEmail") {
      value = value.toUpperCase();
    }
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  handleChecked = (event) => {
    this.props.checkHandler(event);
  };

  render() {
    return (
      <Grid container spacing={2} className="customer_info">
        {hcsforms.includes(this.props.params.formType.toLowerCase()) &&
          this.props.params.comp === "hcs" &&
          this.props.formInformation.orderChecked && (
            <Grid item xs={12}>
              <Typography variant="h5">
                {this.props.labels.company_information + " "}
                <strong>
                  {this.props.labels.company_information_addition}
                </strong>
              </Typography>
            </Grid>
          )}
        <Grid item xs={6}>
          <InputText
            id="dealerName"
            label={
              this.props.params.formType.toLowerCase() === "construction" ||
              this.props.params.comp.toLowerCase() === "arjo"
                ? this.props.labels.label_customer_name +
                  " " +
                  this.props.labels.dealer_name
                : this.props.labels.dealer
            }
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.dealerName}
            tooltip={this.props.labels.tooltip_dealer}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid && !this.state.dealerName
                ? "invalid"
                : ""
            }
          />
        </Grid>
        {hcsforms.includes(this.props.params.formType.toLowerCase()) &&
          this.props.params.comp === "hcs" &&
          this.props.formInformation.orderChecked && (
            <>
              <Grid item xs={6}>
                <InputText
                  id="customerNumber"
                  label={this.props.labels.dealer_number}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.customerNumber}
                  tooltip={this.props.labels.tooltip_husqvarna_number}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.customerNumber
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id="dealerAdress"
                  label={this.props.labels.dealer_address}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.dealerAdress}
                  tooltip={this.props.labels.tooltip_dealer_address}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.dealerAdress
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id="dealerZipCode"
                  label={this.props.labels.zip_code}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.dealerZipCode}
                  tooltip={this.props.labels.tooltip_zipcode}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.dealerZipCode
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id="dealerCity"
                  label={this.props.labels.label_city}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.dealerCity}
                  tooltip={""}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.dealerCity
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {this.props.labels.seller_information}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputText
                  id="sellerName"
                  label={this.props.labels.dealer_name}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.sellerName}
                  tooltip={""}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.sellerName
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
            </>
          )}
        <Grid item xs={6}>
          <InputText
            id="dealerEmail"
            label={
              this.props.params.formType.toLowerCase() === "construction" ||
              this.props.params.comp.toLowerCase() === "arjo"
                ? this.props.labels.label_email +
                  " " +
                  this.props.labels.dealer_name
                : this.props.labels.email_dealer
            }
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.dealerEmail}
            tooltip={this.props.labels.tooltip_dealer_email}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid && !this.state.dealerEmail
                ? "invalid"
                : ""
            }
          />
        </Grid>
        {this.props.params.formType.toLowerCase() === "leaseplus" &&
          (this.props.params.lang.toLowerCase() === "de" ||
            this.props.params.lang.toLowerCase() === "se") &&
          this.props.formInformation.orderChecked && (
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="amp_checked"
                    checked={this.props.formInformation.ampChecked}
                    onChange={this.handleChecked}
                  />
                }
                label={this.props.labels.amp}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
      </Grid>
    );
  }
}
