import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField, Tooltip } from "@mui/material";
import debounce from "lodash.debounce";

import "./css/InputText.css";

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue || "",
    };

    this.handleChangeDebounced = debounce(
      this.handleChangeDebounced.bind(this),
      props.debounce
    );
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue || "" });
    }
  }

  handleChangeDebounced(newValue) {
    const { id, changeHandler } = this.props;
    if (id.split("_")[1] === "serial") {
      const arr = newValue
        .split(",")
        .filter((serial) => serial.trim() !== "")
        .map((serial) => serial.trim());
      changeHandler(arr, id);
    } else {
      changeHandler(newValue, id);
    }
  }

  handleChange(event) {
    const newValue = event.target.value;
    this.setState({ value: newValue });
    this.handleChangeDebounced(newValue);
  }

  render() {
    const { id, tooltip, multiline, label, password, sx, required, className } =
      this.props;
    const { value } = this.state;

    return (
      <Tooltip title={tooltip} placement="top">
        <TextField
          id={id}
          className={`input_${id.split("_")[1]} ${className}`}
          multiline={multiline}
          label={label}
          value={value}
          onChange={this.handleChange}
          type={password ? "password" : "text"}
          sx={sx}
          autoComplete="new-password"
          required={required}
        />
      </Tooltip>
    );
  }
}

InputText.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  sx: PropTypes.object,
  debounce: PropTypes.number,
  tooltip: PropTypes.string,
  password: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
};

InputText.defaultProps = {
  multiline: false,
  debounce: 1000,
  tooltip: "",
  required: false,
  sx: {},
  className: "",
};

export default InputText;
