import React from "react";
import PropTypes from "prop-types";
import InputNumber from "../../component/Fields/InputNumber";
import DisplayField from "../../component/Fields/DisplayField";
import ProductList from "../../component/Fields/ProductList";
import {
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
} from "@mui/material";
import InputDate from "../../component/Fields/InputDate";
import InputText from "../../component/Fields/InputText";
import debounce from "lodash.debounce";

import "./css/UpCare.css";

const countries = [];

export default class UpCareProduct extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    number: PropTypes.string,
    prouctValues: PropTypes.object,
    formInformation: PropTypes.object,
    labels: PropTypes.object,
    variables: PropTypes.object,
    products: PropTypes.object,
    updateProduct: PropTypes.func,
    updateTotalMonthlyFee: PropTypes.func,
    params: PropTypes.object,
  };

  state = {
    productList: "productList1",
  };

  productFinished = debounce(() => {
    const data = {
      finished:
        this.props.productValues.name !== null &&
        this.props.productValues.quantity > 0,
    };
    this.props.updateProduct(data, this.props.id);
  }, 100);

  finished = (fieldId) => {
    switch (fieldId) {
      case "quantity":
        return this.props.productValues.quantity > 0;
      case "name":
        return this.props.productValues.name !== null;
      default:
        return -1;
    }
  };

  componentDidMount() {
    let data = { ownServiceIncludes: {} };
    if (this.props.params.lang === "fr" || this.props.params.lang === "de") {
      data.ownServiceIncludes["storage"] = false;
    } else if (this.props.params.lang === "se") {
      data.ownServiceIncludes["storage"] = true;
    }
    this.props.updateProduct(data, this.props.id);
  }

  parseSerial(serials) {
    if (serials === undefined) return "";
    return serials.join(", ");
  }

  handleChange = (value, id) => {
    const fieldId = id.split("_")[1];
    let data = {};
    data[fieldId] = value;
    this.props.updateProduct(data, this.props.id);
    this.productFinished();
    if (
      fieldId === "name" ||
      fieldId === "quantity" ||
      fieldId === "accessory" ||
      fieldId === "installation"
    ) {
      this.props.updateTotalMonthlyFee();
    }
  };

  handleClick = (e) => {
    const id = e.target.id.split("_")[0];
    const type = e.target.id.split("_")[1];
    let newState = this.state;
    let data = { ownServiceIncludes: {} };
    if (type === "yes") {
      data.ownServiceIncludes[id] = true;
      newState.productList = "productList2";
      data["productList"] = "productList2";
    } else {
      data.ownServiceIncludes[id] = false;
      newState.productList = "productList1";
      data["productList"] = "productList1";
    }
    this.setState(newState);
    this.props.updateProduct(data, this.props.id);
    this.props.updateTotalMonthlyFee();
  };

  render() {
    return (
      <Grid container spacing={2} className="Product">
        <Grid item xs={12}>
          <TextField
            id={this.props.id + "_title"}
            value={this.props.labels.product + " " + this.props.number}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={2}>
          <InputNumber
            label={this.props.labels.quantity}
            id={this.props.id + "_quantity"}
            defaultValue={this.props.productValues.quantity}
            changeHandler={this.handleChange}
            required={true}
            readOnly={false}
            tooltip={this.props.labels.tooltip_quantity}
            className={
              this.props.formInformation.showInvalid &&
              !this.finished("quantity")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={5}>
          <ProductList
            id={this.props.id + "_name"}
            changeHandler={this.handleChange}
            defaultValue={this.props.productValues.name}
            label={this.props.labels.choose_a_product}
            products={this.props.products[this.state.productList]}
            tooltip={this.props.labels.tooltip_product}
            required={true}
            className={
              this.props.formInformation.showInvalid && !this.finished("name")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={5}>
          <InputText
            id={this.props.id + "_serial"}
            key={this.props.serial}
            label={this.props.labels.serial_numbers}
            multiline={true}
            changeHandler={this.handleChange}
            defaultValue={this.parseSerial(this.props.productValues.serial)}
            tooltip={this.props.labels.tooltip_serialnumber}
          />
        </Grid>
        <Grid item xs={12}>
          <InputDate
            id={this.props.id + "_deliveryDate"}
            label={this.props.labels.delivery_date}
            changeHandler={this.handleChange}
            date={this.props.productValues.deliveryDate}
            minDate={new Date()}
            helperText={
              this.props.params.lang.toLowerCase() === "at"
                ? this.props.labels.delivery_information
                : ""
            }
          />
        </Grid>
        <Grid item xs={2}>
          <DisplayField
            id={this.props.id + "_monthlyFee"}
            label={String(
              this.props.labels.product_fee + this.props.labels.ex_vat
            )}
            value={this.props.productValues.totalProductPrice.toString()}
            currency={this.props.labels.currency}
          />
        </Grid>
        {this.props.params.lang === "at" && (
          <Grid item xs={2}>
            <DisplayField
              label={this.props.labels.extra_fee}
              value={
                this.props.productValues.totalProductPrice *
                (this.props.formInformation.contractLength + 1) *
                0.01 *
                1.2
              }
              currency={this.props.labels.currency}
              tooltip={""}
            />
          </Grid>
        )}
        <Grid item xs={8}></Grid>
        <Grid item xs={3}>
          <DisplayField
            label={this.props.labels.original_amount + this.props.labels.ex_vat}
            id={this.props.id + "_displayPrice"}
            value={this.props.productValues.purchasePrice}
            currency={this.props.labels.currency}
            tooltip={""}
          />
        </Grid>
        <Grid item xs={3}>
          <InputNumber
            label={this.props.labels.display_installation_title}
            id={this.props.id + "_installation"}
            defaultValue={this.props.productValues.installation}
            changeHandler={this.handleChange}
            readOnly={false}
            startAdornment={this.props.labels.currency}
          />
        </Grid>
        <Grid item xs={3}>
          <DisplayField
            label={
              this.props.labels.total_product_price_per_product +
              " " +
              this.props.labels.ex_vat
            }
            id={this.props.id + "_displayTotalProductPrice"}
            value={
              this.props.productValues.installation +
              this.props.productValues.installationAccessory +
              this.props.productValues.purchasePrice +
              this.props.productValues.purchasePriceAccessory
            }
            currency={this.props.labels.currency}
            tooltip={this.props.labels.total_price_installation}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#273A60", // You can customize the color
                  borderWidth: "2px", // Adjust the thickness as needed
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <DisplayField
            label={this.props.labels.residual_value + this.props.labels.ex_vat}
            id={this.props.id + "_displayResidual"}
            value={this.props.productValues.residualPrice}
            currency={this.props.labels.currency}
            tooltip={""}
          />
        </Grid>
        {this.props.productValues.quantity > 1 && (
          <>
            <Grid item xs={3}>
              <DisplayField
                label={
                  this.props.labels.total_purchase_price +
                  this.props.labels.ex_vat
                }
                id={this.props.id + "_displayPrice"}
                value={
                  this.props.productValues.purchasePrice *
                  this.props.productValues.quantity
                }
                currency={this.props.labels.currency}
                tooltip={""}
              />
            </Grid>
            <Grid item xs={3}>
              <InputNumber
                label={
                  this.props.labels.total_installation +
                  this.props.labels.ex_vat
                }
                id={this.props.id + "_installation"}
                defaultValue={
                  this.props.productValues.installation *
                  this.props.productValues.quantity
                }
                changeHandler={this.handleChange}
                readOnly={false}
                startAdornment={this.props.labels.currency}
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayField
                label={
                  this.props.labels.total_product_price +
                  " " +
                  this.props.labels.ex_vat
                }
                id={this.props.id + "_displayTotalProductPrice"}
                value={
                  (this.props.productValues.installation +
                    this.props.productValues.installationAccessory +
                    this.props.productValues.purchasePrice +
                    this.props.productValues.purchasePriceAccessory) *
                  this.props.productValues.quantity
                }
                currency={this.props.labels.currency}
                tooltip={this.props.labels.total_price_installation}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#273A60",
                      borderWidth: "2px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayField
                label={
                  this.props.labels.total_residual_value +
                  this.props.labels.ex_vat
                }
                id={this.props.id + "_displayResidual"}
                value={
                  this.props.productValues.residualPrice *
                  this.props.productValues.quantity
                }
                currency={this.props.labels.currency}
                tooltip={""}
              />
            </Grid>
          </>
        )}
        {countries.includes(this.props.params.lang) &&
        this.props.params.comp !== "logisnext" ? (
          <Grid item xs={12}>
            <Typography variant="h6">
              {this.props.labels.service_winter}
            </Typography>
          </Grid>
        ) : null}
        {countries.includes(this.props.params.lang) &&
        this.props.params.comp !== "logisnext" ? (
          <Grid item xs={6}>
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Checkbox id="storage_yes" onClick={this.handleClick} />
                }
                label={this.props.labels.positive_answer}
                checked={this.props.productValues.ownServiceIncludes["storage"]}
              />
              <FormControlLabel
                control={
                  <Checkbox id="storage_no" onClick={this.handleClick} />
                }
                label={this.props.labels.negative_answer}
                checked={
                  !this.props.productValues.ownServiceIncludes["storage"]
                }
              />
            </FormGroup>
          </Grid>
        ) : null}
      </Grid>
    );
  }
}
