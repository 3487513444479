import React from "react";
import { jsPDF } from "jspdf";
import { PDFDocument } from "pdf-lib";
import HcsQuote from "./Quotes/HcsQuote";
import ArjoQuote from "./Quotes/ArjoQuote";
import { calculateTotalFee } from "../logic/Calculations";

import { getLabels, onSubmitDB } from "../logic/ServerCalls";
import "./css/pdf.css";
import FormRes from "./FormRes/FormRes.js";
import Header from "./Header.js";
const ReactDomServer = require("react-dom/server");
const config = require("../res/config.json");

/**
 *
 * @param {Array} otherFiles - Other files uploaded by seller that they want hcs to have
 * @param {Object} info - info about company, country and form-type
 * @param {Object} props - the props from formRes
 * @param {Boolean} ownCopy - If we should create a copy of the order for the seller to download
 * @param {Boolean} quote - If we should create a quoute that the seller can download
 * @param {Boolean} order - If we should send a order to hcs
 *
 * Creates two diffrent pdf's, two order and one quote pdf.
 */

export async function generatePDFs(
  props,
  ownCopy,
  serverResponce,
  state,
  createContract
) {
  if (props.formInformation.orderChecked && !createContract) {
    //if order is true then we will create a uk version on the order pdf and send it to hcs.
    (async () => {
      let data = await getLabels(
        props.params.comp.toLowerCase(),
        props.params.lang.toLowerCase()
      );
      let labels = {};
      for (const label of data) {
        labels[label.Field.name] = label.value;
      }
      state.labels = labels;
      let quotePDF = new jsPDF("p", "px", "a4");

      if (props.params.lang.toLowerCase() === "at") {
        let index = props.labels.id_select.indexOf(
          props.customerInformation.id_type
        );
        let id_type = labels.id_select[index];
        props.customerInformation.id_type = id_type;

        index = props.labels.pep.indexOf(props.customerInformation.pep);
        let pep = labels.pep[index];
        props.customerInformation.pep = pep;
      }

      const filename = props.params.formType + "-" + props.labels.order;

      const formRes = ReactDomServer.renderToString(
        <>
          <Header
            imageSrc={props.headerImgSrc}
            params={props.params}
            imageWidth={"100px"}
            headerType={"header-order"}
          />
          <FormRes
            formInformation={props.formInformation}
            productsValues={props.productsValues}
            customerInformation={props.customerInformation}
            labels={props.labels}
            accessories={props.accessories}
            params={props.params}
            totalResidue={props.totalResidue}
            totalPrice={props.totalPrice}
            extraFiles={props.extraFiles}
            pdf={true}
            products={props.products}
            productAccessories={props.productAccessories}
            variables={props.variables}
            newDealerInformation={props.newDealerInformation}
          />
        </>
      );
      quotePDF.html(formRes, {
        callback: async function (pdf) {
          props.extraFiles.unshift(pdf.output("blob"));
          const files = await convertFileToBase64(props.extraFiles);
          let res = await onSubmitDB(
            files,
            props.params,
            props.formInformation.useCampaign,
            "order",
            {
              dealerName: props.customerInformation.dealerName,
              dealerEmail: props.customerInformation.dealerEmail,
            },
            state
          );
          if (res.status === 200 && ownCopy) {
            pdf.save(filename);
          }
          serverResponce(res);
        },
        x: 0,
        y: 0,
      });
    })();
  }
  if (props.formInformation.quoteChecked) {
    //if we want a quote only.
    if (props.formInformation.allContractLengths) {
      for (const element of props.formInformation.contractLengths) {
        let fee = calculateTotalFee(
          props.productsValues,
          element,
          props.variables,
          props.accessories,
          props.params.formType
        );
        props.formInformation.totalMonthlyFee = fee.totalMonthlyFee;
        props.formInformation.contractLength = element;
        await generateQuote(
          props.productsValues,
          props.params,
          props.labels,
          props.customerInformation,
          props.formInformation,
          props.accessories
        );
      }
      serverResponce({ status: 200 });
    } else {
      generateQuote(
        props.productsValues,
        props.params,
        props.labels,
        props.customerInformation,
        props.formInformation,
        props.accessories,
        props.headerImgSrc
      );
      serverResponce({ status: 200 });
    }
  }
  if (props.formInformation.orderChecked && createContract) {
    generateContractQuote(
      props.productsValues,
      props.params,
      props.labels,
      props.customerInformation,
      props.formInformation,
      props.accessories,
      props.changeHandlerContractFile
    );
    serverResponce({ status: 201 });
  }
}

const convertFileToBase64 = async (files) => {
  const base64Strings = await Promise.all(
    files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = btoa(
            new Uint8Array(reader.result).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          resolve({ data: base64String, type: file.type, name: file.name });
        };
        reader.readAsArrayBuffer(file);
      });
    })
  );

  return base64Strings;
};

/**
 *
 * @param {Object} productsValues
 * @param {Object} accessories
 * @param {String} formType
 * @param {Object} labels
 * @param {number} contractLength
 * @param {Object} customerInformation
 * @param {number} totalMonthlyFee
 * @param {String} customer
 * @param {Boolean} upCareService
 *
 * Renders ReactElement HcsQuote to HTML and saves it as pdf to users device
 */
async function generateQuote(
  productsValues,
  params,
  labels,
  customerInformation,
  formInformation,
  accessories,
  headerImgSrc
) {
  const fileName = params.comp + "-" + params.formType + "-" + labels.quote;
  let component;
  switch (params.comp.toLowerCase()) {
    case "hcs":
      component = HcsQuote;
      break;
    case "arjo":
      component = ArjoQuote;
      break;
    default:
      break;
  }

  const quote = ReactDomServer.renderToStaticMarkup(
    React.createElement(component, {
      productsValues: productsValues,
      labels: labels,
      customerInformation: customerInformation,
      params: params,
      formInformation: formInformation,
      accessories: accessories,
      headerImgSrc: headerImgSrc,
    })
  );

  let pdf = new jsPDF("p", "px", "a4");
  await pdf.html(quote, {
    callback: async function (pdf) {
      if (
        params.comp.toLowerCase() === "arjo" &&
        formInformation.leasingType === "Financial lease"
      ) {
        await arjoPdf(pdf.output("arraybuffer"), params);
      } else {
        pdf.save(fileName);
      }
    },
    x: 0,
    y: 0,
  });
}

/**
 *
 * @param {Object} productsValues
 * @param {Object} accessories
 * @param {String} formType
 * @param {Object} labels
 * @param {number} contractLength
 * @param {Object} customerInformation
 * @param {number} totalMonthlyFee
 * @param {String} customer
 * @param {Boolean} upCareService
 *
 * Renders ReactElement HcsQuote to HTML and saves it as pdf to users device
 */
async function generateContractQuote(
  productsValues,
  params,
  labels,
  customerInformation,
  formInformation,
  accessories,
  changeHandlerContractFile
) {
  const quote = ReactDomServer.renderToStaticMarkup(
    React.createElement(HcsQuote, {
      productsValues: productsValues,
      labels: labels,
      customerInformation: customerInformation,
      params: params,
      formInformation: formInformation,
      accessories: accessories,
    })
  );

  let pdf = new jsPDF("p", "px", "a4");
  await pdf.html(quote, {
    callback: async function (pdf) {
      let data = {};
      data["file"] = pdf.output("blob");
      data["fileSize"] = data["file"].byteLength;
      data["fileName"] = "contract.pdf";
      data["fileDisplay"] = pdf.output("datauristring");
      await changeHandlerContractFile(data);
    },
    x: 0,
    y: 0,
  });
}

async function arjoPdf(pdf) {
  const pdf1 = await PDFDocument.load(pdf);
  const tos = await fetch(config.ROOT + "arjotos").then((res) =>
    res.arrayBuffer()
  );
  const pdf2 = await PDFDocument.load(tos);

  const doc = await PDFDocument.create();

  const pagesFirstPdf = await doc.copyPages(pdf1, pdf1.getPageIndices());
  for (const page of pagesFirstPdf) {
    doc.addPage(page);
  }

  const pagesSecondPdf = await doc.copyPages(pdf2, pdf2.getPageIndices());
  for (const page of pagesSecondPdf) {
    doc.addPage(page);
  }

  const pdfBytes = await doc.save();
  const a = document.createElement("a");
  const file = new Blob([pdfBytes], {
    type: "pdf",
  });
  a.href = URL.createObjectURL(file);
  a.download = "quote.pdf";
  a.click();

  URL.revokeObjectURL(a.href);

  return file;
}
