import React from "react";
import TextBox from "../Fields/TextBox";
import "./css/FormRes.css";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

export default class Private extends React.Component {
  static propTypes = {
    labels: PropTypes.object,
    customerInformation: PropTypes.object,
    lang: PropTypes.string,
    dateToString: PropTypes.func,
    productsValues: PropTypes.object,
    params: PropTypes.object,
    orderChecked: PropTypes.bool,
  };

  render() {
    const showRepurchase =
      this.props.params.formType.toLowerCase() === "leaseplus" &&
      this.props.params.comp.toLowerCase() === "hcs" &&
      (Object.values(this.props.productsValues).some(
        (obj) => obj.name && obj.name.includes("AM")
      ) ||
        Object.values(this.props.productsValues).some(
          (obj) => obj.name && obj.name.includes("Aspire R4")
        ));
    const size = showRepurchase ? 6 : 12;
    const orderSize = this.props.orderChecked ? 3 : 6;
    return (
      <Grid container spacing={2} className="CustomerInformation">
        {this.props.orderChecked ? (
          <>
            <Grid item xs={orderSize}>
              <TextBox
                title={this.props.labels.customer_first_name}
                content={this.props.customerInformation.customerFirstName}
              />
            </Grid>
            <Grid item xs={orderSize}>
              <TextBox
                title={this.props.labels.customer_last_name}
                content={this.props.customerInformation.customerLastName}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={orderSize}>
            <TextBox
              title={this.props.labels.customer_name}
              content={this.props.customerInformation.customerName}
            />
          </Grid>
        )}
        <Grid item xs={orderSize}>
          <TextBox
            title={this.props.labels.label_email}
            content={this.props.customerInformation.customerEmail}
          />
        </Grid>
        {this.props.orderChecked && (
          <>
            <Grid item xs={3}>
              <TextBox
                title={this.props.labels.label_phonenumber}
                content={this.props.customerInformation.customerPhone}
              />
            </Grid>
            <Grid item xs={3}>
              <TextBox
                title={this.props.labels.customer_street}
                content={this.props.customerInformation.customerStreet}
              />
            </Grid>
            <Grid item xs={3}>
              <TextBox
                title={this.props.labels.customer_house_number}
                content={this.props.customerInformation.customerHouseNumber}
              />
            </Grid>
            <Grid item xs={3}>
              <TextBox
                title={this.props.labels.label_zip_code}
                content={this.props.customerInformation.customerZipCode}
              />
            </Grid>
            <Grid item xs={3}>
              <TextBox
                title={this.props.labels.city}
                content={this.props.customerInformation.customerCity}
              />
            </Grid>

            {this.props.lang === "at" ? (
              <>
                <Grid item xs={4}>
                  <TextBox
                    title={this.props.labels.label_id_type}
                    content={this.props.customerInformation.idType}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    title={this.props.labels.label_id_number}
                    content={this.props.customerInformation.idNumber}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    title={this.props.labels.social_security_number}
                    content={this.props.dateToString(
                      this.props.customerInformation.dateOfBirth
                    )}
                  />
                </Grid>
                <Grid item xs={size}>
                  <TextBox
                    title={this.props.labels.pep_label}
                    content={this.props.customerInformation.pep}
                  />
                </Grid>
                {showRepurchase && (
                  <Grid item xs={6}>
                    <TextBox
                      title={this.props.labels.repurchase}
                      content={this.props.customerInformation.repurchase}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
                {this.props.customerInformation.dateOfBirth === null ? (
                  <>
                    <Grid item xs={size}>
                      <TextBox
                        title={this.props.labels.social_security_number}
                        content={
                          this.props.customerInformation.socialSecurityNumber
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={size}>
                      <TextBox
                        title={this.props.labels.social_security_number}
                        content={this.props.dateToString(
                          this.props.customerInformation.dateOfBirth
                        )}
                      />
                    </Grid>
                  </>
                )}
                {showRepurchase && (
                  <Grid item xs={6}>
                    <TextBox
                      title={this.props.labels.repurchase}
                      content={this.props.customerInformation.repurchase}
                    />
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    );
  }
}
