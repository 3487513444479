import React from "react";
import { jsPDF } from "jspdf";

import { getLabels, onSubmitDB } from "../logic/ServerCalls";
import "./css/pdf.css";
import FormRes from "./FormRes/FormRes.js";
import Header from "./Header.js";
const ReactDomServer = require("react-dom/server");

/**
 *
 * @param {Array} otherFiles - Other files uploaded by seller that they want hcs to have
 * @param {Object} info - info about company, country and form-type
 * @param {Object} props - the props from formRes
 * @param {Boolean} ownCopy - If we should create a copy of the order for the seller to download
 * @param {Boolean} quote - If we should create a quoute that the seller can download
 * @param {Boolean} order - If we should send a order to hcs
 *
 * Creates two diffrent pdf's, two order and one quote pdf.
 */

export async function sendOrder(data, ownCopy, serverResponce) {
  let labels = await getLabels("se");

  let orderPDF = new jsPDF("p", "px", "a4");

  const filename = data.params.formType + "_order.pdf";

  const formRes = ReactDomServer.renderToString(
    <>
      <Header
        imageSrc={data.headerImgSrc}
        params={data.params}
        imageWidth={"100px"}
        headerType={"header-order"}
      />
      <FormRes
        formInformation={data.formInformation}
        productsValues={data.productsValues}
        customerInformation={data.customerInformation}
        labels={labels}
        accessories={data.accessories}
        params={data.params}
        totalResidue={data.totalResidue}
        totalPrice={data.totalPrice}
        extraFiles={data.extraFiles}
        pdf={true}
        products={data.products}
        productAccessories={data.productAccessories}
        variables={data.variables}
        newDealerInformation={data.newDealerInformation}
      />
    </>
  );
  orderPDF.html(formRes, {
    callback: async function (pdf) {
      let filenames = [
        filename,
        data.contractFile.fileName,
        data.creditFile.fileName,
      ];

      data.extraFiles.unshift(data.creditFile.file);
      data.extraFiles.unshift(data.contractFile.file);
      data.extraFiles.unshift(pdf.output("blob"));
      const files = await convertFileToBase64(data.extraFiles, filenames);
      let res = await onSubmitDB(files, data.params, data);
      if (res.status === 200 && ownCopy) {
        pdf.save(filename);
      }
      serverResponce(res);
    },
    x: 0,
    y: 0,
  });
}

const convertFileToBase64 = async (files, filenames) => {
  let i = 0;
  const base64Strings = await Promise.all(
    files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = btoa(
            new Uint8Array(reader.result).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          if (file.name === undefined && i < 3) {
            file.name = filenames[i];
            i += 1;
          }
          resolve({ data: base64String, type: file.type, fileName: file.name });
        };
        reader.readAsArrayBuffer(file);
      });
    })
  );

  return base64Strings;
};
