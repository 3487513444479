import React from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@mui/material";
import { formatNumber } from "../../logic/GeneralFunctions";

export default class DisplayField extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    label: PropTypes.string,
    currency: PropTypes.string,
    sx: PropTypes.object,
    className: PropTypes.string,
    countryCode: PropTypes.string,
  };

  render() {
    return (
      <TextField
        id={this.props.id}
        label={this.props.label}
        value={formatNumber(this.props.value, this.props.countryCode)}
        className={this.props.className}
        sx={Object.assign(this.props.sx, { width: "100%" })}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              {this.props.currency}
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

DisplayField.defaultProps = {
  sx: {},
};
