import React from "react";
import PropTypes from "prop-types";
import InputNumber from "../../component/Fields/InputNumber";
import ProductList from "../../component/Fields/ProductList";
import {
  Checkbox,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import debounce from "lodash.debounce";

export default class FlexPrivateProduct extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    number: PropTypes.string,
    productValues: PropTypes.object,
    labels: PropTypes.object,
    variables: PropTypes.object,
    products: PropTypes.object,
    updateProduct: PropTypes.func,
    calculateNewFee: PropTypes.func,
  };

  productFinished = debounce(() => {
    const data = {
      finished:
        this.props.productValues.quantity > 0 &&
        this.props.productValues.price > 0 &&
        this.props.productValues.name !== null,
    };
    this.props.updateProduct(data, this.props.id);
  }, 100);

  finished = (fieldId) => {
    switch (fieldId) {
      case "quantity":
        return this.props.productValues.quantity > 0;
      case "price":
        return this.props.productValues.price > 0;
      case "name":
        return this.props.productValues.name !== null;
      default:
        return -1;
    }
  };

  /**
   *
   * @param {Number} value - The new value of the field
   * @param {String} id    - id of the field the value is from
   *
   * Updates the field with new value.
   */

  handleChange = (value, id) => {
    const fieldId = id.split("_")[1];
    let data = {};
    data[fieldId] = value;
    if (fieldId === "quantity") {
      data["totalProductPrice"] = value * this.props.productValues.price;
    }
    if (fieldId === "price") {
      data["totalProductPrice"] = value * this.props.productValues.quantity;
    }
    this.props.updateProduct(data, this.props.id);
    this.productFinished();
    this.props.calculateNewFee();
  };

  handleClick = (e) => {
    let data = { ownServiceIncludes: {} };
    data.ownServiceIncludes[e.target.id] = e.target.checked;
    this.props.updateProduct(data, this.props.id);
  };

  render() {
    return (
      <Grid container spacing={2} className="Product">
        <Grid item xs={12}>
          <TextField
            id={this.props.id + "_title"}
            value={this.props.labels.product + " " + this.props.number}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={2}>
          <InputNumber
            label={this.props.labels.quantity}
            id={this.props.id + "_quantity"}
            defaultValue={this.props.productValues.quantity}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_quantity}
            className={
              this.props.formInformation.showInvalid &&
              !this.finished("quantity")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={8}>
          <ProductList
            id={this.props.id + "_name"}
            defaultValue={this.props.productValues.name}
            changeHandler={this.handleChange}
            label={this.props.labels.choose_a_product}
            products={this.props.products}
            tooltip={this.props.labels.tooltip_product}
            required={true}
            className={
              this.props.formInformation.showInvalid && !this.finished("name")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={2}>
          <InputNumber
            label={this.props.labels.your_chosen_price}
            id={this.props.id + "_price"}
            defaultValue={this.props.productValues.price}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_product_price}
            endAdornment={this.props.labels.currency}
            className={
              this.props.formInformation.showInvalid && !this.finished("price")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <InputNumber
            label={this.props.labels.residual_value}
            id={this.props.id + "_residual"}
            defaultValue={this.props.productValues.residual}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_residual_value}
            endAdornment="%"
            min={5}
            max={40}
          />
        </Grid>
        <Grid item xs={4}>
          <InputNumber
            label={this.props.labels.service_per_year}
            id={this.props.id + "_service"}
            defaultValue={this.props.productValues.service}
            changeHandler={this.handleChange}
            tooltip={this.props.labels.tooltip_service_per_year}
            endAdornment={this.props.labels.currency}
          />
        </Grid>
        <Grid item xs={4}>
          <InputNumber
            label={this.props.labels.first_increased_rent}
            id={this.props.id + "_firstIncresedRent"}
            defaultValue={this.props.productValues.firstIncresedRent}
            changeHandler={this.handleChange}
            tooltip={this.props.labels.tooltip_first_increased_rent}
            endAdornment={this.props.labels.currency}
          />
        </Grid>
        <Grid item xs={4}>
          <FormGroup row={true}>
            <FormControlLabel
              control={<Checkbox id="storage" onClick={this.handleClick} />}
              label={this.props.labels.storage}
              sx={{ width: "130px" }}
              checked={this.props.productValues.ownServiceIncludes["storage"]}
            />
            <FormControlLabel
              control={<Checkbox id="consumables" onClick={this.handleClick} />}
              label={this.props.labels.consumables}
              checked={
                this.props.productValues.ownServiceIncludes["consumables"]
              }
            />
            <FormControlLabel
              control={<Checkbox id="education" onClick={this.handleClick} />}
              label={this.props.labels.education}
              sx={{ width: "130px" }}
              checked={this.props.productValues.ownServiceIncludes["education"]}
            />
            <FormControlLabel
              control={<Checkbox id="other" onClick={this.handleClick} />}
              label={this.props.labels.other}
              checked={this.props.productValues.ownServiceIncludes["other"]}
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
  }
}
