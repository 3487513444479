import React from "react";
import Information from "../component/Information";
import { useParams } from "react-router-dom";
import { getLabels, postOnboarding } from "../logic/ServerCalls";
import { customizeDataForOnboarding } from "../logic/GeneralFunctions";

import { Stack, Button } from "@mui/material";
import NewDealer from "../component/Information/Dealer/NewDealer";
import NotFound from "../component/NotFound";
import configSource from "../res/config.json";
import config from "./res/config.json";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import "./css/onboarding.css";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Hcs extends React.Component {
  state = {
    labels: {},
    headerText: "",
    params: {},
    newDealerInformation: {
      newDealerFinished: false,
      verifyNewDealer: false,
      newDealerName: "",
      organizationNumber: "",
      taxNumber: "",
      husqvarnaNumber: "",
      invoiceEmail: "",
      contactPerson: "",
      emailContactPerson: "",
      phoneContactPerson: "",
      dealerAddress: "",
      nd_zipCode: "",
      nd_city: "",
      bankName: "",
      ibanNumber: "",
      bicSwiftCode: "",
      bankNumber: "",
      sortCode: "",
      registrationNumber: "",
      newDealerSignatories: {
        newDealerSignatory1: {
          nameSignatoryNewDealer: "",
          emailSignatoryNewDealer: "",
          dateOfBirthSignatory: null,
          socialSecurityNumberSignatory: "",
        },
      },
    },
    formInformation: {
      formState: "edit",
      showInvalid: false,
      useCampaign: false,
    },
    extraFiles: [],
    firstRender: true,
  };

  /**
   *  Gets called after first render.
   */
  componentDidMount() {
    if (this.validateParams()) {
      this.setState({
        firstRender: false,
        params: { ...this.props.params, comp: "hcs" },
      });
      this.SetFormInformation();
    }
  }

  /**
   * Requests labels, headerText, productlist and variables from server and puts them in state.
   */
  SetFormInformation = () => {
    (async () => {
      try {
        const lang = this.props.params.lang.toLowerCase();
        let labels;
        labels = await getLabels(lang);
        let state = this.state;

        state.labels = labels;
        state.isVerified = true;

        this.setState(state);
      } catch (error) {
        //TODO redirect
        console.log(error);
      }
    })();
  };

  /**
   *
   * @param {Array<File>} files
   *
   * Puts the files in the array in state.
   */
  addExtraFiles = (files) => {
    let extraFiles = this.state.extraFiles;
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      extraFiles.push(element);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * @param {File} file
   *
   * Removes given file from state if it exists.
   */
  removeExtraFiles = (file) => {
    let extraFiles = this.state.extraFiles;
    let index = extraFiles.indexOf(file);
    if (index !== -1) {
      extraFiles.splice(index, 1);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   * Adds a new object to signatories.
   */
  addSignatory = (newDealer) => {
    if (newDealer) {
      const key =
        Object.keys(this.state.newDealerInformation.newDealerSignatories)
          .length + 1;
      const id = "newDealerSignatory" + key;
      let signatories = this.state.newDealerInformation.newDealerSignatories;
      signatories[id] = {
        nameSignatoryNewDealer: "",
        emailSignatoryNewDealer: "",
        dateOfBirthSignatory: null,
        socialSecurityNumberSignatory: "",
      };
      this.setState({
        signatories: signatories,
      });
    } else {
      const key =
        Object.keys(this.state.customerInformation.signatories).length + 1;
      const id = "signatory" + key;
      let signatories = this.state.customerInformation.signatories;
      signatories[id] = {
        nameSignatory: "",
        emailSignatory: "",
        phoneSignatory: "",
      };
      this.setState({
        signatories: signatories,
      });
    }
  };

  /**
   * Remove last object from signatories.
   */
  removeSignatory = (newDealer) => {
    if (newDealer) {
      const id = Object.keys(
        this.state.newDealerInformation.newDealerSignatories
      ).at(-1);
      let signatories = this.state.newDealerInformation.newDealerSignatories;
      delete signatories[id];
      this.setState({
        signatories: signatories,
      });
    } else {
      const id = Object.keys(this.state.customerInformation.signatories).at(-1);
      let signatories = this.state.customerInformation.signatories;
      delete signatories[id];
      this.setState({
        signatories: signatories,
      });
    }
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of newDealerInformation based on keys in info.
   */
  handleNewDealerInfoChange = (info) => {
    let newDealerInformation = this.state.newDealerInformation;
    for (let id in info) {
      newDealerInformation[id] = info[id];
    }
    this.setState({
      newDealerInformation: newDealerInformation,
    });
  };

  /**
   *
   * @param {Object} event
   *
   * Handles checkbox check depending on case.
   */
  handleChecked = async (event) => {
    let newState;
    let newDealerInformation = this.state.newDealerInformation;
    switch (event.target.id) {
      case "verify_new_dealer":
        newState = this.state.newDealerInformation.verifyNewDealer
          ? false
          : true;
        newDealerInformation.verifyNewDealer = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;
      default:
        throw Error("Unknown button ID");
    }
  };

  /* 
    Uploads data to the Onboaring excel file stored in s3 bucket amplio-shared-files.
  */

  uploadData = async () => {
    const csvData = customizeDataForOnboarding(
      this.state.newDealerInformation,
      this.props.params.lang.toLowerCase()
    );
    const res = await postOnboarding(
      this.props.params.lang.toLowerCase(),
      csvData
    );
    if (res?.status === 200) {
      this.switchFormState("completed");
    } else {
      alert(
        "Information could not be sent, please reload the page and try again."
      );
    }
  };

  /**
   *
   * @param {String} value
   *
   * Switches the current formState to new value.
   */
  switchFormState = (value) => {
    let formInformation = this.state.formInformation;
    formInformation.formState = value;
    this.setState(formInformation);
  };

  showInvalid = (newValue = true) => {
    let newFormInformation = this.state.formInformation;
    newFormInformation.showInvalid = newValue;
    this.setState({
      formInformation: newFormInformation,
    });
  };

  /**
   *
   * @returns Boolean
   */
  validateFormInputs = () => {
    if (configSource["DEBUG"]) return true;
    if (!this.state.newDealerInformation.newDealerFinished) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    this.showInvalid(false);
    return true;
  };

  /**
   *
   * @returns Boolean
   *
   * Check the params to validate that the form requested exists.
   */
  validateParams = () => {
    const allowedArgs = config.ALLOWED_ARGS;
    if (allowedArgs["hcs"].includes(this.props.params.lang.toLowerCase())) {
      return true;
    }
    return false;
  };

  render() {
    if (!this.validateParams()) return <NotFound />;
    if (this.state.firstRender) return;
    const disabled = this.state.newDealerInformation.verifyNewDealer
      ? false
      : true;
    return (
      <Stack className="form-wrapper" alignItems="stretch">
        <div>
          <div className="header">
            <p>{this.state.headerText?.text}</p>
          </div>
          {this.state.formInformation.formState === "edit" && (
            <div>
              {this.state.labels.hcs_information !== "" &&
                this.state.labels?.hcs_information !== undefined && (
                  <div>
                    <Information
                      label={this.state.labels.hcs_information}
                      changeHandler={this.handleFormInformationChange}
                    ></Information>
                  </div>
                )}
              <div className="titles-wrapp">
                <h1 className="titles">{this.state.labels.onboarding}</h1>
                <hr />
              </div>
              <div className="order_info">
                <Information
                  label={this.state.labels.onboarding_information}
                  changeHandler={this.handleFormInformationChange}
                ></Information>
              </div>
              <div className="padding-top">
                <NewDealer
                  newDealer={this.state.newDealerInformation}
                  labels={this.state?.labels}
                  changeHandler={this.handleNewDealerInfoChange}
                  formInformation={this.state.formInformation}
                  checkHandler={this.handleChecked}
                  params={this.state.params}
                  addSignatory={this.addSignatory}
                  removeSignatory={this.removeSignatory}
                  payment={false}
                />
              </div>
              <div className="button-container-send">
                <Button
                  sx={{
                    minWidth: "350px",
                    minHeight: "45px",
                  }}
                  onClick={() => {
                    if (this.validateFormInputs()) {
                      this.uploadData();
                    }
                  }}
                  variant="contained"
                  disabled={disabled}
                >
                  <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
                  <span className="app-span-text">
                    {this.state.labels.send}{" "}
                  </span>
                </Button>
              </div>
            </div>
          )}

          {this.state.formInformation.formState === "completed" && (
            <div className="completedText">
              <h1>{this.state.labels.thank_you}</h1>

              <div>
                <p>{this.state.labels.information_sent}</p>
              </div>
            </div>
          )}
          <br></br>
        </div>
      </Stack>
    );
  }
}

export default withParams(Hcs);
