import React, { Component } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import PropTypes from "prop-types";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const buttonStyle = {
  mt: 2,
  px: 4,
  py: 1,
  backgroundColor: "#273A60",
  "&:hover": {
    backgroundColor: "#1e2d4d",
  },
};

class InvoiceModal extends Component {
  handleClose = () => {
    this.props.handleChange(false);
  };

  handleConfirm = () => {
    this.props.handleChange(true);
  };

  render() {
    const { invoiceDidNotMatch, labels } = this.props;

    return (
      <Modal
        open={invoiceDidNotMatch}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-title"
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            Confirmation Needed
          </Typography>
          <Typography
            id="modal-description"
            variant="body1"
            sx={{ mb: 3, textAlign: "center" }}
          >
            {labels.invoice_did_not_match}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-evenly", gap: 2 }}>
            <Button
              variant="contained"
              size="large"
              onClick={this.handleClose}
              sx={buttonStyle}
            >
              {labels.negative_answer}
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={buttonStyle}
              onClick={this.handleConfirm}
            >
              {labels.positive_answer}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }
}

InvoiceModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  invoiceDidNotMatch: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
};

export default InvoiceModal;
