import React from "react";
import { generatePDFs } from "../GeneratePDF";
import TextBox from "../Fields/TextBox";
import Business from "./FormResBusiness";
import Private from "./FormResPrivate";
import NewDealer from "./FormResNewDealer";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import PropTypes from "prop-types";
import { formatNumber } from "../../logic/GeneralFunctions";
import "./css/FormRes.css";
import "../css/pdf.css";

import { Grid, Button, Typography } from "@mui/material";

const countriesWinter = ["at", "dk", "fi", "nl", "no", "uk"];
const hcsforms = ["leaseplus", "upcare", "flexpro"];
const vatRate = {
  at: 1.2,
  de: 1.19,
  dk: 1.25,
  fi: 1.24,
  fr: 1.2,
  nl: 1.21,
  no: 1.25,
  se: 1.25,
  uk: 1.2,
};

export default class FormRes extends React.Component {
  static propTypes = {
    formInformation: PropTypes.object,
    productsValues: PropTypes.object,
    customerInformation: PropTypes.object,
    labels: PropTypes.object,
    accessories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    params: PropTypes.object,
    extraFiles: PropTypes.array,
    pdf: PropTypes.bool,
    switchFormState: PropTypes.func,
    products: PropTypes.array,
    productAccessories: PropTypes.array,
    variables: PropTypes.object,
    newDealerInformation: PropTypes.object,
    changeContractLength: PropTypes.func,
    headerImgSrc: PropTypes.string,
    createContractPDf: PropTypes.func,
  };
  state = {
    filename: "",
    submited: false,
    invoiceCheckLoading: false,
  };

  /**
   *
   * @param {Date} date - Products delivery date, customers date of birth
   *
   * Parses the Date object to string
   */
  dateToString(date) {
    if (!date) {
      return "";
    }
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() < 9 ? "0" : "") +
      (date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" : "") +
      date.getDate()
    );
  }

  componentDidMount() {
    if (this.props.params.lang === "fr") {
      this.props.formInformation.extraInfo =
        this.props.formInformation.extraInfo.toUpperCase();
      Object.keys(this.props.customerInformation).forEach((key) => {
        if (
          typeof this.props.customerInformation[key] === "string" &&
          key !== "repurchase"
        ) {
          this.props.customerInformation[key] =
            this.props.customerInformation[key].toUpperCase();
        }
      });
    }
  }

  getPriceContractLengths(event) {
    this.props.changeContractLength(event);
  }

  switchFormState = (value) => {
    this.props.switchFormState(value);
  };

  serverResponce = (res) => {
    if (res.status === 200) {
      //if the mail was successfuly sent
      this.switchFormState("completed");
    } else {
      //if not
      console.log(res.err);
    }
  };

  generateUpCareLeasePlusProductRow(key) {
    return (
      <>
        <tr
          key={key}
          className={key[key.length - 1] % 2 === 0 ? "even" : "odd"}
        >
          <td className="item">{this.props.productsValues[key].quantity}</td>
          <td className="item not-center">
            {this.props.productsValues[key].name !== null
              ? this.props.productsValues[key].name
              : "Separate accessories"}
          </td>
          <td className="item">
            {this.props.labels.currency +
              formatNumber(
                this.props.productsValues[key].totalProductPrice,
                this.props.params.lang
              )}
          </td>
          {this.props.params.lang.toLowerCase() === "at" && (
            <td className="item">
              {this.props.params.formType.toLowerCase() === "upcare"
                ? this.props.labels.currency +
                  formatNumber(
                    this.props.productsValues[key].totalProductPrice *
                      (this.props.formInformation.contractLength + 1) *
                      0.01 *
                      1.2,
                    this.props.params.lang
                  )
                : this.props.labels.currency +
                  formatNumber(
                    this.props.productsValues[key].totalProductPrice *
                      (this.props.formInformation.contractLength + 1) *
                      0.01,
                    this.props.params.lang
                  )}
            </td>
          )}
          <td className="item">
            {this.props.labels.currency +
              formatNumber(
                this.props.productsValues[key].name !== null
                  ? this.props.productsValues[key].purchasePrice +
                      this.props.productsValues[key].installation
                  : 0,
                this.props.params.lang
              )}
          </td>
          <td className="item">
            {this.props.labels.currency +
              formatNumber(
                this.props.productsValues[key].name !== null
                  ? this.props.productsValues[key].residualPrice
                  : 0,
                this.props.params.lang
              )}
          </td>
          <td className="item">
            {this.dateToString(this.props.productsValues[key].deliveryDate)}
          </td>
          <td className="item">
            {this.props.productsValues[key].serial.join(", ")}
          </td>
          {this.props.params.formType.toLowerCase() === "upcare" &&
          this.props.params.comp === "hcs" &&
          countriesWinter.includes(this.props.params.lang.toLowerCase()) ? (
            <td className="item">
              {this.props.productsValues[key].ownServiceIncludes["storage"] ? (
                <b>X</b>
              ) : (
                ""
              )}
            </td>
          ) : null}
        </tr>
        {this.props.params.formType.toLowerCase() === "leaseplus" && (
          <>
            {this.props.productsValues[key].accessory.map(
              (accessory, index) => (
                <>
                  {accessory !== null && (
                    <tr
                      key={key + "accessory"}
                      className={key[key.length - 1] % 2 === 0 ? "even" : "odd"}
                    >
                      <td className="item">
                        {this.props.productsValues[key].quantity}
                      </td>
                      <td className="item not-center">{accessory}</td>
                      <td className="item"> </td>
                      {this.props.params.lang.toLowerCase() === "at" ? (
                        <td className="item"> </td>
                      ) : (
                        <td className="item">
                          {this.props.labels.currency +
                            formatNumber(
                              accessory !== null
                                ? this.props.productsValues[key]
                                    .purchasePriceAccessory[index] +
                                    this.props.productsValues[key]
                                      .installationAccessory[index]
                                : 0,
                              this.props.params.lang
                            )}{" "}
                        </td>
                      )}
                      {this.props.params.lang.toLowerCase() === "at" ? (
                        <td className="item">
                          {this.props.labels.currency +
                            formatNumber(
                              accessory !== null
                                ? this.props.productsValues[key]
                                    .purchasePriceAccessory[index] +
                                    this.props.productsValues[key]
                                      .installationAccessory[index]
                                : 0,
                              this.props.params.lang
                            )}{" "}
                        </td>
                      ) : (
                        <td className="item">
                          {this.props.labels.currency +
                            formatNumber(
                              accessory !== null
                                ? this.props.productsValues[key]
                                    .residualPriceAccessory[index]
                                : 0,
                              this.props.params.lang
                            )}
                        </td>
                      )}
                      {this.props.params.lang.toLowerCase() === "at" ? (
                        <td className="item">
                          {this.props.labels.currency +
                            formatNumber(
                              this.props.productsValues[key].accessory !== null
                                ? this.props.productsValues[key]
                                    .residualPriceAccessory
                                : 0,
                              this.props.params.lang
                            )}
                        </td>
                      ) : (
                        <td className="item"> </td>
                      )}

                      <td className="item"> </td>
                      {this.props.params.lang.toLowerCase() === "at" && (
                        <td className="item"> </td>
                      )}
                    </tr>
                  )}
                </>
              )
            )}
          </>
        )}
      </>
    );
  }

  generateFlexPrivateProductRow(key) {
    return (
      <tr key={key} className={key[key.length - 1] % 2 === 0 ? "even" : "odd"}>
        <td className="item">{this.props.productsValues[key].quantity}</td>
        <td className="item not-center">
          {this.props.productsValues[key].name}
        </td>
        <td className="item">
          {this.props.labels.currency +
            formatNumber(
              this.props.productsValues[key].totalProductPrice,
              this.props.params.lang
            )}
        </td>
        <td className="item">
          {this.props.labels.currency +
            formatNumber(
              this.props.productsValues[key].firstIncresedRent,
              this.props.params.lang
            )}
        </td>
        <td className="item">
          {this.props.productsValues[key].residual + " %"}
        </td>
        <td className="item">
          {this.props.labels.currency +
            formatNumber(
              this.props.productsValues[key].service,
              this.props.params.lang
            )}
        </td>
        <td className="item">
          {this.props.productsValues[key].ownServiceIncludes["storage"] ? (
            <b>X</b>
          ) : (
            ""
          )}
        </td>
        <td className="item">
          {this.props.productsValues[key].ownServiceIncludes["consumables"] ? (
            <b>X</b>
          ) : (
            ""
          )}
        </td>
        <td className="item">
          {this.props.productsValues[key].ownServiceIncludes["education"] ? (
            <b>X</b>
          ) : (
            ""
          )}
        </td>
        <td className="item">
          {this.props.productsValues[key].ownServiceIncludes["other"] ? (
            <b>X</b>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  }

  generateFlexProProductRow(key) {
    return (
      <tr key={key} className={key[key.length - 1] % 2 === 0 ? "even" : "odd"}>
        <td className="item">{this.props.productsValues[key].quantity}</td>
        <td className="item not-center">
          {this.props.productsValues[key].name}
        </td>
        <td className="item">
          {formatNumber(
            this.props.productsValues[key].totalProductPrice,
            this.props.params.lang
          )}
        </td>
        <td className="item">
          {this.props.productsValues[key].residual + " %"}
        </td>
        <td className="item">
          {this.props.labels.currency +
            formatNumber(
              this.props.productsValues[key].installation,
              this.props.params.lang
            )}
        </td>
        <td className="item">
          {this.props.productsValues[key].upCare
            ? this.props.labels.upCare_title
            : this.props.productsValues[key].ownServiceIncludes["noService"]
            ? this.props.labels.no_service
            : this.props.labels.own_service}
        </td>
        {this.props.productsValues[key].upCare ? (
          <>
            <td className="item">
              {this.props.productsValues[key].upCare
                ? this.props.labels.upCare_title
                : this.props.labels.no_upcare}
            </td>
            <td className="item">
              {this.props.productsValues[key].ownServiceIncludes["storage"] ? (
                <b>X</b>
              ) : (
                ""
              )}
            </td>
            <td className="item"> </td>
            <td className="item"> </td>
            <td className="item"> </td>
          </>
        ) : (
          <>
            <td className="item">
              {this.props.labels.currency +
                formatNumber(
                  this.props.productsValues[key].service,
                  this.props.params.lang
                )}
            </td>
            <td className="item">
              {this.props.productsValues[key].ownServiceIncludes["storage"] ? (
                <b>X</b>
              ) : (
                ""
              )}
            </td>
            <td className="item">
              {this.props.productsValues[key].ownServiceIncludes[
                "consumables"
              ] ? (
                <b>X</b>
              ) : (
                ""
              )}
            </td>
            <td className="item">
              {this.props.productsValues[key].ownServiceIncludes[
                "education"
              ] ? (
                <b>X</b>
              ) : (
                ""
              )}
            </td>
            <td className="item">
              {this.props.productsValues[key].ownServiceIncludes["other"] ? (
                <b>X</b>
              ) : (
                ""
              )}
            </td>
            <td className="item"> </td>
          </>
        )}
      </tr>
    );
  }

  generateConstructionProductRow(key) {
    return (
      <tr key={key} className={key[key.length - 1] % 2 === 0 ? "even" : "odd"}>
        <td className="item">{this.props.productsValues[key].quantity}</td>
        <td className="item not-center">
          {this.props.productsValues[key].name}
        </td>
        <td className="item">
          {this.props.labels.currency +
            formatNumber(
              this.props.productsValues[key].totalProductPrice,
              this.props.params.lang
            )}
        </td>
        {/* <td className="item">
          {this.props.labels.currency +
            formatNumber(
              this.props.productsValues[key].firstIncresedRent,
              this.props.params.lang
            )}
        </td> */}
        <td className="item">
          {this.props.productsValues[key].residual + " %"}
        </td>
        <td className="item">
          {this.props.labels.currency +
            formatNumber(
              this.props.productsValues[key].service,
              this.props.params.lang
            )}
        </td>
      </tr>
    );
  }

  generateArjoProductRow(key) {
    return (
      <tr key={key} className={key[key.length - 1] % 2 === 0 ? "even" : "odd"}>
        <td className="item">{this.props.productsValues[key].quantity}</td>
        <td className="item not-center">
          {this.props.productsValues[key].name}
        </td>
        <td className="item">
          {this.props.labels.currency +
            formatNumber(
              this.props.productsValues[key].totalProductPrice,
              this.props.params.lang
            )}
        </td>
        <td className="item">
          {this.props.productsValues[key].residual + " %"}
        </td>
        <td className="item">
          {this.props.productsValues[key].servicePackage}
        </td>
        <td className="item">
          {this.props.labels.currency}
          {formatNumber(
            this.props.productsValues[key].service,
            this.props.params.lang
          )}
        </td>
      </tr>
    );
  }

  /**
   *
   * @param {String} key - indexes for the obejcts
   *
   * Given a key, return a table row for product table. Depending on formtype, there are diffrent colums used.
   */
  generateProductRow(key) {
    switch (this.props.params.formType.toLowerCase()) {
      case "leaseplus":
      case "upcare":
        return this.generateUpCareLeasePlusProductRow(key);
      case "flexprivate":
        return this.generateFlexPrivateProductRow(key);
      case "flexpro":
        return this.generateFlexProProductRow(key);
      case "construction":
        return this.generateConstructionProductRow(key);
      case "standard":
        return this.generateArjoProductRow(key);
      default:
        throw Error("Unknown formType");
    }
  }

  /**
   *
   * @param {String} key - indexes for the obejcts
   *
   * Given a key, return a table row for accessories table.
   */
  generateAccessoryRow(key) {
    return (
      <tr key={key} className={key[key.length - 1] % 2 === 0 ? "even" : "odd"}>
        <td className="item">
          {this.props.accessories[key].AccessoriesQuantity}
        </td>
        <td className="item not-center">
          {this.props.accessories[key].AccessoriesName}
        </td>
        <td className="item">
          {this.props.labels.currency}
          {this.props.accessories[key].AccessoriesPrice !== undefined
            ? formatNumber(
                this.props.accessories[key].AccessoriesPrice,
                this.props.params.lang
              )
            : 0}
        </td>
        <td className="item">
          {this.props.labels.currency}
          {this.props.accessories[key].TotalAccessoriesPrice !== undefined
            ? formatNumber(
                this.props.accessories[key].TotalAccessoriesPrice,
                this.props.params.lang
              )
            : 0}{" "}
        </td>
      </tr>
    );
  }

  generateUpCareLeasePlusHeader() {
    return (
      <tr>
        <th className="item">{this.props.labels.quantity}</th>
        <th className="item">{this.props.labels.product}</th>
        <th className="item">{this.props.labels.product_fee}</th>
        {this.props.params.lang.toLowerCase() === "at" && (
          <th className="item">{this.props.labels.extra_fee}</th>
        )}
        <th className="item">
          {this.props.labels.total_product_price_per_product +
            " " +
            this.props.labels.ex_vat}
        </th>
        <th className="item">{this.props.labels.display_residual_title}</th>
        <th className="item">{this.props.labels.delivery_date}</th>
        <th className="item">{this.props.labels.serial_numbers}</th>
        {this.props.params.comp === "hcs" &&
        countriesWinter.includes(this.props.params.lang.toLowerCase()) &&
        this.props.params.formType.toLowerCase() === "upcare" ? (
          <th className="item">{this.props.labels.winter}</th>
        ) : null}
      </tr>
    );
  }

  generateFlexPrivateHeader() {
    return (
      <tr>
        <th className="item">{this.props.labels.quantity}</th>
        <th className="item">{this.props.labels.product_name}</th>
        <th className="item">{this.props.labels.total_product_price}</th>
        <th className="item">{this.props.labels.first_increased_rent}</th>
        <th className="item">{this.props.labels.residual_value}</th>
        <th className="item">{this.props.labels.service_per_year}</th>
        <th className="item">{this.props.labels.winter}</th>
        <th className="item">{this.props.labels.consumables}</th>
        <th className="item">{this.props.labels.education}</th>
        <th className="item">{this.props.labels.other}</th>
      </tr>
    );
  }

  generateFlexProHeader() {
    return (
      <tr>
        <th className="item">{this.props.labels.quantity}</th>
        <th className="item">{this.props.labels.product_name}</th>
        <th className="item">{this.props.labels.product_price}</th>
        <th className="item">{this.props.labels.residual_value}</th>
        <th className="item">{this.props.labels.installation_per_product}</th>
        <th className="item">{this.props.labels.service_type}</th>
        <th className="item">{this.props.labels.service_per_year}</th>
        <th className="item">{this.props.labels.winter}</th>
        <th className="item">{this.props.labels.consumables}</th>
        <th className="item">{this.props.labels.education}</th>
        <th className="item">{this.props.labels.other}</th>
      </tr>
    );
  }

  generateConstructionHeader() {
    return (
      <tr>
        <th className="item">{this.props.labels.quantity}</th>
        <th className="item">{this.props.labels.product_name}</th>
        <th className="item">{this.props.labels.total_product_price}</th>
        {/* <th className="item">{this.props.labels.first_increased_rent}</th> */}
        <th className="item">{this.props.labels.residual_value}</th>
        <th className="item">{this.props.labels.service_per_month_product}</th>
      </tr>
    );
  }
  generateArjoHeader() {
    return (
      <tr>
        <th className="item">{this.props.labels.quantity}</th>
        <th className="item">{this.props.labels.product_name}</th>
        <th className="item">{this.props.labels.total_product_price}</th>
        <th className="item">{this.props.labels.residual_value}</th>
        <th className="item">{this.props.labels.service_package}</th>
        <th className="item">{this.props.labels.service_per_year}</th>
      </tr>
    );
  }

  generateHeader() {
    switch (this.props.params.formType.toLowerCase()) {
      case "leaseplus":
      case "upcare":
        return this.generateUpCareLeasePlusHeader();
      case "flexprivate":
        return this.generateFlexPrivateHeader();
      case "flexpro":
        return this.generateFlexProHeader();
      case "construction":
        return this.generateConstructionHeader();
      case "standard":
        return this.generateArjoHeader();
      default:
        throw Error("Unknown formType");
    }
  }

  getTotalPrice() {
    /* HÄR */
    let totalPrice = 0;
    Object.keys(this.props.productsValues).forEach((key) => {
      totalPrice +=
        this.props.productsValues[key].name !== null
          ? (this.props.productsValues[key].purchasePrice +
              this.props.productsValues[key].installation) *
            this.props.productsValues[key].quantity
          : 0;
    });
    Object.keys(this.props.accessories).forEach((key) => {
      totalPrice += this.props.accessories[key].TotalAccessoriesPrice;
    });
    return totalPrice;
  }

  render() {
    const productRows = Object.keys(this.props.productsValues);
    const accessoryRows = Object.keys(this.props.accessories);
    let wrapperClass = this.props.pdf ? "formWrapper pdfScaler" : "formWrapper";
    return (
      <div>
        <div id="Form" className={wrapperClass}>
          <div className="wrapper">
            <h1 className="formTitle">{this.props.labels.products}</h1>
            <div>
              <table className="table">
                <tbody>
                  {this.generateHeader()}
                  {productRows.map((key) => this.generateProductRow(key))}
                </tbody>
              </table>
            </div>
          </div>
          {Object.keys(this.props.accessories).length > 0 &&
            this.props.params.formType.toLowerCase() !== "leaseplus" && (
              <div className="wrapper">
                <h1 className="formTitle">
                  {this.props.params.formType.toLowerCase() !== "standard" &&
                  this.props.params.formType.toLowerCase() !== "construction" &&
                  this.props.params.formType.toLowerCase() !== "upcare"
                    ? this.props.labels.accessories_title
                    : this.props.params.formType.toLowerCase() ===
                      "construction"
                    ? this.props.labels.accessories_title_construction
                    : this.props.params.formType.toLowerCase() === "standard"
                    ? this.props.labels.additional_cost_title
                    : this.props.labels.accessories_title_2}
                </h1>
                <table className="table">
                  <tbody>
                    <tr>
                      <th className="item">{this.props.labels.quantity}</th>
                      <th className="item">
                        {this.props.labels.accessories_name}
                      </th>
                      <th className="item">
                        {this.props.labels.accessories_price}
                      </th>
                      <th className="item">
                        {this.props.labels.accessories_total_price}
                      </th>
                    </tr>
                    {accessoryRows.map((key) => this.generateAccessoryRow(key))}
                  </tbody>
                </table>
              </div>
            )}
          <div className="wrapper">
            <h1 className="formTitle">{this.props.labels.summery_title}</h1>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    {this.props.labels.total_monthly_fee}{" "}
                    <b>
                      {this.props.labels.currency}
                      {formatNumber(
                        this.props.formInformation.totalMonthlyFee,
                        this.props.params.lang
                      )}
                    </b>
                  </td>
                  <td>
                    {this.props.labels.contract_length}{" "}
                    <b>
                      {this.props.formInformation.contractLength}{" "}
                      {this.props.labels.months}
                    </b>
                  </td>
                </tr>
                {this.props.params.formType.toLowerCase() === "upcare" && (
                  <tr>
                    <td>
                      {this.props.labels.total_product_price}{" "}
                      <b>
                        {this.props.labels.currency}
                        {formatNumber(
                          this.getTotalPrice(),
                          this.props.params.lang
                        )}
                      </b>
                    </td>
                  </tr>
                )}
                {this.props.params.formType.toLowerCase() === "leaseplus" && (
                  <tr>
                    <td>
                      {this.props.labels.total_monthly_fee}
                      {this.props.labels.ex_vat}{" "}
                      <b>
                        {this.props.labels.currency}
                        {formatNumber(
                          this.props.formInformation.totalMonthlyFee /
                            vatRate[this.props.params.lang.toLowerCase()],
                          this.props.params.lang
                        )}
                      </b>
                    </td>
                  </tr>
                )}
                {(this.props.params.formType.toLowerCase() === "flexpro" ||
                  this.props.params.formType.toLowerCase() === "flexprivate" ||
                  this.props.params.formType.toLowerCase() === "construction" ||
                  this.props.params.formType.toLowerCase() === "standard") && (
                  <tr>
                    <td>
                      {this.props.labels.label_total_price}{" "}
                      <b>
                        {this.props.labels.currency}
                        {formatNumber(
                          this.props.formInformation.totalPrice,
                          this.props.params.lang
                        )}{" "}
                      </b>
                    </td>
                    <td>
                      {this.props.labels.residual_value}{" "}
                      <b>
                        {this.props.labels.currency}
                        {formatNumber(
                          this.props.formInformation.totalResidue,
                          this.props.params.lang
                        )}{" "}
                      </b>
                    </td>
                  </tr>
                )}
                {this.props.params.formType.toLowerCase() === "standard" && (
                  <>
                    <tr>
                      <td>
                        {this.props.labels.amortization}{" "}
                        <b>
                          {this.props.labels.currency}
                          {this.props.formInformation.amortization}
                        </b>
                      </td>
                      <td>
                        {this.props.labels.interest_value}{" "}
                        <b>
                          {this.props.variables.function_interest}
                          {"%"}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.props.labels.leasing_type}{" "}
                        <b>{this.props.formInformation.leasingType}</b>
                      </td>
                    </tr>
                  </>
                )}
                {(this.props.params.formType.toLowerCase() === "flexpro" ||
                  this.props.params.formType.toLowerCase() ===
                    "construction") &&
                  this.props.params.lang.toLowerCase() === "at" && (
                    <tr>
                      <td>
                        {this.props.labels.extra_fee}{" "}
                        <b>
                          {this.props.labels.currency}
                          {formatNumber(
                            this.props.formInformation.totalMonthlyFee *
                              (this.props.formInformation.contractLength + 1) *
                              0.01 *
                              1.2,
                            this.props.params.lang
                          )}{" "}
                        </b>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <div className="wrapper">
            <h1 className="formTitle">
              {this.props.labels.customer_information_title}
            </h1>
            <div className="formPadding">
              {this.props.formInformation.customer === "Business" ? (
                <Business
                  labels={this.props.labels}
                  customerInformation={this.props.customerInformation}
                  orderChecked={this.props.formInformation.orderChecked}
                  params={this.props.params}
                  dateToString={this.dateToString}
                />
              ) : (
                <Private
                  labels={this.props.labels}
                  customerInformation={this.props.customerInformation}
                  orderChecked={this.props.formInformation.orderChecked}
                  lang={this.props.params.lang.toLowerCase()}
                  dateToString={this.dateToString}
                  productsValues={this.props.productsValues}
                  params={this.props.params}
                />
              )}
            </div>
          </div>
          <div className="wrapper">
            <h1 className="formTitle">
              {this.props.labels.dealer_information}
            </h1>
            <div className="formPadding">
              <Grid container spacing={2} className="CustomerInformation">
                <Grid item xs={6}>
                  <TextBox
                    title={
                      this.props.params.formType.toLowerCase() ===
                        "construction" ||
                      this.props.params.comp.toLowerCase() === "arjo"
                        ? this.props.labels.label_customer_name +
                          " " +
                          this.props.labels.dealer_name
                        : this.props.labels.dealer
                    }
                    content={this.props.customerInformation.dealerName}
                  />
                </Grid>
                {hcsforms.includes(this.props.params.formType.toLowerCase()) &&
                this.props.params.comp === "hcs" &&
                this.props.formInformation.orderChecked ? (
                  <Grid item xs={6}>
                    <TextBox
                      title={this.props.labels.dealer_number}
                      content={this.props.customerInformation.customerNumber}
                    />
                  </Grid>
                ) : null}

                {hcsforms.includes(this.props.params.formType.toLowerCase()) &&
                this.props.params.comp === "hcs" &&
                this.props.formInformation.orderChecked ? (
                  <>
                    <Grid item xs={4}>
                      <TextBox
                        title={this.props.labels.dealer_address}
                        content={this.props.customerInformation.dealerAdress}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextBox
                        title={this.props.labels.zip_code}
                        content={this.props.customerInformation.dealerZipCode}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextBox
                        title={this.props.labels.label_city}
                        content={this.props.customerInformation.dealerCity}
                      />
                    </Grid>
                  </>
                ) : null}

                {hcsforms.includes(this.props.params.formType.toLowerCase()) &&
                this.props.params.comp === "hcs" &&
                this.props.formInformation.orderChecked ? (
                  <Grid item xs={6}>
                    <TextBox
                      title={this.props.labels.dealer_name}
                      content={this.props.customerInformation.sellerName}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={6}>
                  <TextBox
                    title={this.props.labels.dealer_email}
                    content={this.props.customerInformation.dealerEmail}
                  />
                </Grid>
                {this.props.params.formType.toLowerCase() === "leaseplus" &&
                  (this.props.params.lang.toLowerCase() === "de" ||
                    this.props.params.lang.toLowerCase() === "se") &&
                  this.props.formInformation.ampChecked && (
                    <Grid item xs={6}>
                      <Typography className="padding-left">
                        {this.props.labels.amp}
                        {": "}
                        {this.props.labels.positive_answer}
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </div>
          </div>
          {this.props.formInformation.newDealerChecked && (
            <div className="wrapper">
              <h1 className="formTitle">
                {this.props.labels.new_dealer_title}
              </h1>
              <NewDealer
                labels={this.props.labels}
                newDealerInformation={this.props.newDealerInformation}
                params={this.props.params}
                dateToString={this.dateToString}
                payments={false}
              />
            </div>
          )}
          {(this.props.params.formType.toLowerCase() === "upcare" ||
            this.props.params.formType.toLowerCase() === "flexpro" ||
            this.props.params.formType.toLowerCase() === "construction") &&
            this.props.formInformation.extraInfo !== "" &&
            this.props.formInformation.orderChecked && (
              <div className="wrapper">
                <h1 className="formTitle">
                  {this.props.labels.extra_information}
                </h1>
                <div className="formPadding">
                  <Grid container spacing={2} className="CustomerInformation">
                    <Grid item xs={12}>
                      <TextBox
                        title={this.props.labels.other}
                        content={this.props.formInformation.extraInfo}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
        </div>
        <br></br>
        {!this.props.pdf && (
          <div>
            {this.props.extraFiles.length > 0 && (
              <div>
                <p>{this.props.labels.files_to_send} &nbsp;</p>

                {this.props.extraFiles.map((file) => {
                  return (
                    <p key={file.name} style={{ marginEnd: "10px" }}>
                      <b>{file.name}&nbsp;&nbsp;</b>
                    </p>
                  );
                })}
              </div>
            )}
            <div className="row checkboxRow">
              <Button
                sx={{ backgroundColor: "#273A60" }}
                onClick={() => {
                  this.switchFormState("edit");
                }}
                variant="contained"
              >
                <ArrowBackRoundedIcon sx={{ marginRight: "5px" }} />
                {this.props.labels.edit_form}
              </Button>
              {this.props.params.formType.toLowerCase() === "leaseplus" &&
              this.props.formInformation.orderChecked ? (
                <Button
                  sx={{
                    minWidth: "350px",
                    minHeight: "45px",
                    backgroundColor: "#273A60",
                  }}
                  onClick={() => {
                    this.props.createContractPDf();
                    this.switchFormState("confidentiality_notice");
                  }}
                  variant="contained"
                >
                  <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
                  <span className="app-span-text">
                    {this.props.labels.preview_form}{" "}
                  </span>
                </Button>
              ) : (
                <>
                  {!this.state.submited ? (
                    <Button
                      sx={{
                        textAlign: "right",
                        backgroundColor: "#273A60",
                      }}
                      onClick={() => {
                        this.setState({ submited: true });
                        generatePDFs(
                          this.props,
                          this.state.checked,
                          this.serverResponce,
                          {
                            formInfo: this.props.formInformation,
                            productInformation: this.props.productsValues,
                            customerInformation: this.props.customerInformation,
                            newDealerInformation:
                              this.props.newDealerInformation,
                          }
                        );
                      }}
                      variant="contained"
                    >
                      {this.props.labels.submit_quote}
                      <SendIcon sx={{ marginLeft: "5px" }} />
                    </Button>
                  ) : (
                    <Box sx={{ textAlign: "right" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
