import React from "react";
import PropTypes from "prop-types";
import { Tooltip, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./css/InputDate.css";

export default class InputDate extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    tooltip: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    inputFormat: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    sx: PropTypes.object,
    helperText: PropTypes.string,
  };

  state = {
    showButton: true,
  };

  /**
   *
   * @param {Date} date - Inputed date
   *
   * If a new date is picked
   */
  handleChange = (date) => {
    this.props.changeHandler(date, this.props.id);
  };

  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Tooltip title={this.props.tooltip}>
          <div className={this.props.className} style={{ width: "100%" }}>
            <DesktopDatePicker
              label={this.props.label}
              inputFormat={this.props.inputFormat}
              value={this.props.date}
              onChange={this.handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={this.props.sx}
                  label={this.props.label + (this.props.required ? " *" : "")}
                  helperText={this.props.helperText}
                />
              )}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
            />
          </div>
        </Tooltip>
      </LocalizationProvider>
    );
  }
}

InputDate.defaultProps = {
  tooltip: "",
  inputFormat: "dd/MM/yyyy",
};
