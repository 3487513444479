import React from "react";
import PropTypes from "prop-types";
import "./css/HcsQuote.css";
import { formatNumber } from "../../logic/GeneralFunctions";

export default class HcsQuote extends React.Component {
  static propTypes = {
    productsValues: PropTypes.object,
    labels: PropTypes.object,
    customerInformation: PropTypes.object,
    params: PropTypes.object,
    formInformation: PropTypes.object,
    accessories: PropTypes.object,
    headerImgSrc: PropTypes.string,
  };

  createAccessoryRow = (id) => {
    const className = id.at(-1) % 2 === 0 ? "productEven" : "productOdd";
    const key = id;
    return (
      <>
        <tr className={className} key={id}>
          <td className="quote-td">{this.props.accessories[key].quantity}</td>
          <td className="quote-td">
            {this.props.accessories[key].AccessoriesName}
          </td>
        </tr>
      </>
    );
  };

  createProductRow = (id) => {
    const className = id.at(-1) % 2 === 0 ? "productEven" : "productOdd";
    const key = id;
    let service = this.props.labels.positive_answer;
    if (
      this.props.params.formType.toLowerCase() === "flexpro" ||
      this.props.params.formType.toLowerCase() === "flexprivate" ||
      this.props.params.formType.toLowerCase() === "construction"
    ) {
      if (
        !(
          (this.props.productsValues[key].service !== undefined &&
            this.props.productsValues[key].service > 0) ||
          (this.props.params.formType.toLowerCase() === "flexpro" &&
            (this.props.productsValues[key].upCare ||
              this.props.productsValues[key].ownServiceIncludes["storage"] ||
              this.props.productsValues[key].ownServiceIncludes[
                "consumables"
              ] ||
              this.props.productsValues[key].ownServiceIncludes["education"] ||
              this.props.productsValues[key].ownServiceIncludes["other"]))
        )
      ) {
        service = this.props.labels.negative_answer;
      }
    }
    return (
      <>
        <tr className={className} key={id}>
          <td className="quote-td">
            {this.props.productsValues[key].quantity}
          </td>
          <td className="quote-td">{this.props.productsValues[key].name}</td>
          {this.props.params.formType.toLowerCase() === "construction" ? (
            <td className="quote-td" align="center">
              {" "}
              {this.props.productsValues[key].ownServiceIncludes.repairs
                ? this.props.labels.repairs
                : this.props.labels.maintenance}{" "}
            </td>
          ) : this.props.productsValues[key].upCare ? (
            <td className="quote-td" align="center">
              {" "}
              {this.props.labels.upCare_title}{" "}
            </td>
          ) : (
            <td className="quote-td" align="center">
              {" "}
              {service}{" "}
            </td>
          )}
          <td className="quote-td" align="center">
            {" "}
            {this.props.labels.positive_answer}{" "}
          </td>
        </tr>
        {this.props.productsValues[key].accessory.map(
          (accessory, index) =>
            accessory !== null && (
              <tr className={className} key={id + "accessory"}>
                <td className="quote-td"> {1} </td>
                <td className="quote-td">{accessory}</td>
                <td className="quote-td" align="center">
                  {" "}
                  {service}{" "}
                </td>
                <td className="quote-td" align="center">
                  {" "}
                  {this.props.labels.positive_answer}{" "}
                </td>
              </tr>
            )
        )}
      </>
    );
  };

  getCustomerInformation = () => {
    if (this.props.formInformation.customer === "Business") {
      return (
        <table id="customerInformationTable" className="quote-table">
          <tr color="red">
            <td colSpan="2" className="quote-td">
              <h2 className="title2">
                {" "}
                {this.props.labels.customer_information_title}{" "}
              </h2>
            </td>
          </tr>
          <tr className="foretagsinfo">
            <td className="quote-td">
              <span className="foretagsinfo">{this.props.labels.company}</span>
            </td>
            <td className="quote-td">
              {this.props.customerInformation.customerName}
            </td>
          </tr>
          <tr className="foretagsinfo">
            <td className="quote-td">
              <span className="foretagsinfo">
                {this.props.labels.company_number}
              </span>
            </td>
            <td className="quote-td">
              {this.props.customerInformation.companyNumber}
            </td>
          </tr>
          <tr className="foretagsinfo">
            <td className="quote-td">
              <span className="foretagsinfo">
                {this.props.labels.label_email}
              </span>
            </td>
            <td className="quote-td">
              {this.props.customerInformation.customerEmail}
            </td>
          </tr>
          <tr className="foretagsinfolast">
            <td className="quote-td">
              <span className="foretagsinfo">{this.props.labels.dealer}</span>
            </td>
            <td className="quote-td">
              {this.props.customerInformation.dealerName}
            </td>
          </tr>
        </table>
      );
    }
    return (
      <table id="customerInformationTable" className="quote-table">
        <tr color="red">
          <td colSpan="2" className="quote-td">
            <h2 className="title2">
              {" "}
              {this.props.labels.customer_information_title}{" "}
            </h2>
          </td>
        </tr>
        <tr className="foretagsinfo">
          <td className="quote-td">
            <span className="foretagsinfo">
              {this.props.labels.label_customer_name}
            </span>
          </td>
          <td className="quote-td">
            {this.props.customerInformation.customerName}
          </td>
        </tr>
        <tr className="foretagsinfo">
          <td className="quote-td">
            <span className="foretagsinfo">
              {this.props.labels.label_email}
            </span>
          </td>
          <td className="quote-td">
            {this.props.customerInformation.customerEmail}
          </td>
        </tr>
        <tr className="foretagsinfolast">
          <td className="quote-td">
            <span className="foretagsinfo">{this.props.labels.dealer}</span>
          </td>
          <td className="quote-td">
            {this.props.customerInformation.dealerName}
          </td>
        </tr>
      </table>
    );
  };

  getServiceInformation = () => {
    let repairs = false;
    Object.keys(this.props.productsValues).forEach((key) => {
      if (this.props.productsValues[key].ownServiceIncludes.repairs) {
        repairs = true;
      }
    });
    return repairs;
  };

  render() {
    const splitCareindex = this.props.labels.care_1_3_4.indexOf("-") + 1;
    const endOfString = this.props.labels.care_1_3_4.length;
    const winterStorage = this.props.labels.care_1_8 !== "";
    const padBottom = winterStorage ? "" : " padBottom";
    const date = new Date();
    switch (this.props.params.formType) {
      case "leaseplus":
        var title = `${this.props.labels.quote} - Lease Plus`;
        break;
      case "upcare":
        title = title = `${this.props.labels.quote} - UpCare`;
        break;
      case "flexpro":
        title = title = `${this.props.labels.quote} - Flex Pro`;
        break;
      case "flexprivate":
        title = title = `${this.props.labels.quote} - Flex Private`;
        break;
      case "construction":
        title = title = `${this.props.labels.quote} - Construction`;
        break;
      default:
        title = this.props.params.formType;
    }
    const repairs = this.getServiceInformation();
    return (
      <div className="quote-body">
        <div className="quote-logo">
          <img
            src={this.props.headerImgSrc}
            alt=""
            width="30%"
            height="30%"
          ></img>
        </div>
        <h1 className="quote-h1-hcs">{title}</h1>
        &nbsp;
        {this.getCustomerInformation()}
        &nbsp; &nbsp;
        <table id="productTable" className="quote-table">
          <tr bgcolor="#003366">
            <td colSpan="4" className="quote-td">
              <span className="title3"> {this.props.labels.product} </span>
            </td>
          </tr>
          <tr bgcolor="#efefef">
            <td width="10%" className="quote-td">
              <strong>{this.props.labels.quantity}</strong>
            </td>
            <td width="25%" className="quote-td">
              <strong>{this.props.labels.product}</strong>
            </td>
            <td width="25%" className="quote-td" align="center">
              <strong> {this.props.labels.service} </strong>
            </td>
            <td width="20%" className="quote-td" align="center">
              <strong> {this.props.labels.insurance} </strong>
            </td>
          </tr>
          {Object.keys(this.props.productsValues).map((id) => {
            return this.createProductRow(id);
          })}
        </table>
        &nbsp; &nbsp;
        {(this.props.params.formType === "construction" ||
          this.props.params.formType === "flexpro" ||
          this.props.params.formType === "upcare") &&
          Object.keys(this.props.accessories).length > 0 && (
            <table id="accessoryTable" className="quote-table">
              <tr bgcolor="#003366">
                <td colSpan="4" className="quote-td">
                  <span className="title3">
                    {" "}
                    {this.props.labels.accessory}{" "}
                  </span>
                </td>
              </tr>
              <tr bgcolor="#efefef">
                <td width="10%" className="quote-td">
                  <strong>{this.props.labels.quantity}</strong>
                </td>
                <td width="90%" className="quote-td" align="left">
                  <strong>{this.props.labels.accessory}</strong>
                </td>
              </tr>
              {Object.keys(this.props.accessories).map((id) => {
                return this.createAccessoryRow(id);
              })}
            </table>
          )}
        &nbsp; &nbsp;
        <table id="monthlyFeeTable" className="quote-table">
          <tr bgcolor="#003366">
            <td className="quote-td" width="70%">
              <span className="total-cost">
                {this.props.params.lang.toLowerCase() === "dk" &&
                (this.props.params.formType.toLowerCase() === "flexpro" ||
                  this.props.params.formType.toLowerCase() === "upcare")
                  ? this.props.labels.total_monthly_fee +
                    " " +
                    this.props.labels.ex_vat
                  : this.props.labels.total_monthly_fee}
              </span>
            </td>
            <td className="quote-td" align="right">
              <span className="total-cost">
                {this.props.labels.currency +
                  formatNumber(
                    this.props.formInformation.totalMonthlyFee,
                    this.props.params.lang
                  )}
              </span>
            </td>
          </tr>
          <tr bgcolor="#ffffff">
            <td className="quote-td">{this.props.labels.contract_length}</td>
            <td className="quote-td" align="right">
              <strong>
                {this.props.formInformation.contractLength +
                  " " +
                  this.props.labels.months}
              </strong>
            </td>
          </tr>
        </table>
        {this.props.params.formType.toLowerCase() === "upcare" ||
        this.props.params.formType.toLowerCase() === "leaseplus" ||
        this.props.params.formType.toLowerCase() === "flexpro" ? (
          <div className="care-service-includes-container">
            &nbsp; &nbsp;
            <table className="careService quote-table">
              <tr>
                <th
                  width="46%"
                  className="quote-th careServiceTitle hcs-wordspacing"
                >
                  {this.props.labels.care_title_1}{" "}
                </th>
                <th
                  width="50%"
                  className="quote-th careServiceTitle cellRight hcs-wordspacing"
                >
                  {this.props.labels.care_title_2}{" "}
                </th>
              </tr>
              <tr>
                <td className="quote-td careServiceCell padTop">
                  <b>+</b> {this.props.labels.care_1_1}{" "}
                </td>
                <td className="quote-td careServiceCell cellRight padTop">
                  <b>+</b> {this.props.labels.care_2_1}{" "}
                </td>
              </tr>
              <tr>
                <td className="quote-td careServiceCell">
                  <b>+</b> {this.props.labels.care_1_2}{" "}
                </td>
                <td className="quote-td careServiceCell cellRight">
                  <b>+</b> {this.props.labels.care_2_2}{" "}
                </td>
              </tr>
              {/* This is the Explosion point in the insurance explanation should not be used right now */}
              {/* <tr>
                <td className="quote-td careServiceCell">
                  <b>+</b>{" "}
                  {this.props.labels.care_1_3_4.substring(0, splitCareindex)}{" "}
                </td>
                <td className="quote-td careServiceCell cellRight">
                  <b>+</b> {this.props.labels.care_2_3}{" "}
                </td>
              </tr> */}
              <tr>
                <td className="quote-td careServiceCell" id="paddingLeft">
                  {" "}
                  {this.props.labels.care_1_3_4.substring(
                    splitCareindex,
                    endOfString
                  )}{" "}
                </td>
                <td className="quote-td careServiceCell cellRight">
                  <b>+</b> {this.props.labels.care_2_4}{" "}
                </td>
              </tr>
              <tr>
                <td className="quote-td careServiceCell">
                  <b>+</b> {this.props.labels.care_1_5}{" "}
                </td>
                <td className="quote-td careServiceCell cellRight">
                  <b>+</b> {this.props.labels.care_2_5}{" "}
                </td>
              </tr>
              <tr>
                <td className="quote-td careServiceCell">
                  <b>+</b> {this.props.labels.care_1_6}{" "}
                </td>
                <td className="quote-td careServiceCell cellRight">
                  <b>+</b> {this.props.labels.care_2_6}{" "}
                </td>
              </tr>
              {winterStorage ? (
                <tr>
                  <td className="quote-td careServiceCell">
                    <b>+</b> {this.props.labels.care_1_8}
                  </td>
                  <td className={"quote-td careServiceCell cellRight"}>
                    <b>+</b> {this.props.labels.care_2_7}{" "}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className={"quote-td careServiceCell"}></td>
                  <td className={"quote-td careServiceCell cellRight"}>
                    <b>+</b> {this.props.labels.care_2_7}{" "}
                  </td>
                </tr>
              )}
              {this.props.params.formType === "upcare" ||
              this.props.params.formType === "flexpro" ? (
                <tr>
                  <td className={"quote-td careServiceCell" + padBottom}>
                    <b>+</b> {this.props.labels.care_1_7}{" "}
                  </td>
                  <td
                    className={"quote-td careServiceCell cellRight" + padBottom}
                  ></td>
                </tr>
              ) : null}
            </table>
            <span className="care-bottom">
              {" "}
              {this.props.labels.care_bottom_1}{" "}
            </span>
            <br></br>
            <span className="care-bottom">
              {" "}
              {this.props.labels.care_bottom_2}{" "}
            </span>
          </div>
        ) : null}
        &nbsp;
        {this.props.params.formType.toLowerCase() === "construction" && (
          <>
            <h2 className="service">{this.props.labels.service}</h2>
            <p className="service">
              {this.props.labels.maintenance_information}
            </p>
            {repairs && (
              <>
                <br></br>
                <p className="service">
                  {this.props.labels.maintenance_repairs_information}
                </p>
              </>
            )}
          </>
        )}
        <br></br>
        <p>
          {this.props.labels.quote_end_date +
            date.getFullYear() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getDate()}
          <br></br>
          {this.props.labels.end_message_1}
          <br></br>
          {this.props.labels.end_message_2}
          <br></br>
          .....................................
          <br></br>
          {this.props.customerInformation.dealerName}
          <br></br>
          {this.props.customerInformation.dealerEmail}
        </p>
      </div>
    );
  }
}
