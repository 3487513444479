function validateValue(value) {
  return !(value === undefined || value === "" || value === 0);
}

export function calculateTotalFee(
  productsValues,
  duration,
  variables,
  accessories,
  formType
) {
  let totalPrice = 0,
    totalService = 0,
    totalResidue = 0,
    totalInsurance = 0,
    totalFirstIncrease = 0,
    t,
    annuity,
    amountFinanced,
    totalAccessoriesPrice = 0,
    totalMonthlyFee,
    amortization,
    interest;
  Object.values(productsValues).forEach((product) => {
    totalPrice +=
      validateValue(product["price"]) && validateValue(product["quantity"])
        ? product["price"] * product["quantity"]
        : 0;
    totalService +=
      validateValue(product["service"]) && validateValue(product["quantity"])
        ? formType !== "construction"
          ? (product["service"] * product["quantity"] * duration) / 12
          : product["service"] * product["quantity"] * duration
        : 0;
    totalResidue +=
      validateValue(product["residual"]) &&
      validateValue(product["price"]) &&
      validateValue(product["quantity"])
        ? (product["price"] * product["quantity"] * product["residual"]) / 100
        : 0;
    totalFirstIncrease +=
      validateValue(product["firstIncresedRent"]) &&
      validateValue(product["quantity"])
        ? product["firstIncresedRent"] * product["quantity"]
        : 0;
    let insurance =
      validateValue(product["price"]) && validateValue(product["quantity"])
        ? ((product["price"] *
            product["quantity"] *
            (1 - variables.discount_product) *
            variables.insurance) /
            100) *
          (duration / 12)
        : 0;
    let insuranceMin = validateValue(product["quantity"])
      ? product["quantity"] * duration * variables.insurance_min
      : 0;
    totalInsurance += Math.max(insurance, insuranceMin);
    if (product.installation > 0) {
      totalAccessoriesPrice += product.installation * product.quantity;
    }
  });
  for (let accessory in accessories) {
    if (accessories[accessory].TotalAccessoriesPrice !== undefined) {
      totalAccessoriesPrice += accessories[accessory].TotalAccessoriesPrice;
    }
  }
  totalPrice *= 1 - variables.discount_product;
  totalService *= 1 - variables.discount_service;
  totalAccessoriesPrice *= 1 - variables.discount_accesories;
  amountFinanced = totalPrice + totalAccessoriesPrice - totalFirstIncrease;
  t = (1 + variables.function_interest / 1200) ** duration;
  annuity =
    ((amountFinanced * t - totalResidue) *
      (variables.function_interest / 1200)) /
    ((1 + (variables.function_interest / 1200) * variables.payment) * (t - 1));
  totalMonthlyFee =
    annuity + totalInsurance / duration + totalService / duration;
  amortization = (amountFinanced - totalResidue) / duration;
  let insuranceCalc = totalInsurance / duration;
  let serviceCalc = totalService / duration;
  interest = (
    totalMonthlyFee -
    amortization -
    insuranceCalc -
    serviceCalc
  ).toFixed(2);
  return {
    totalMonthlyFee: totalMonthlyFee.toFixed(2),
    totalResidue: totalResidue.toFixed(2),
    totalPrice: amountFinanced.toFixed(2),
    totalAccessoriesPrice: totalAccessoriesPrice.toFixed(2),
    amortization: amortization.toFixed(2),
    interest: interest,
  };
}

export function calculatePriceInstallationModified(
  installation,
  installationModified,
  monthlyPrice,
  contractLength,
  variables
) {
  let t = (1 + variables.function_interest / 1200) ** contractLength;
  let installationMonthlyCost =
    (installation * t * (variables.function_interest / 1200)) /
    ((1 + (variables.function_interest / 1200) * variables.payment) * (t - 1));
  let installationModifiedMonthlyCost =
    (installationModified * t * (variables.function_interest / 1200)) /
    ((1 + (variables.function_interest / 1200) * variables.payment) * (t - 1));

  monthlyPrice += installationModifiedMonthlyCost - installationMonthlyCost;
  return monthlyPrice.toFixed(2);
}
