import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcons from "./StepIcons";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export default class StepperComponent extends React.Component {
  static propTypes = {
    steps: PropTypes.array,
    activeStep: PropTypes.number,
  };

  render() {
    return (
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={this.props.activeStep}
          connector={<ColorlibConnector />}
        >
          {this.props.steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcons}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    );
  }
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(67,206,162) 0%, rgb(39,58,96) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "rgb(67,206,162)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));
