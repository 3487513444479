import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import BadgeIcon from "@mui/icons-material/Badge";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CreateIcon from "@mui/icons-material/Create";
import MenuBookIcon from "@mui/icons-material/MenuBook";

export default class StepIcons extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.number,
  };

  getIcon(iconNumber) {
    const icons = {
      1: <CreateIcon />,
      2: <MenuBookIcon />,
      3: <BadgeIcon />,
      4: <DocumentScannerIcon />,
      5: <DoneOutlineIcon />,
    };

    return icons[iconNumber] || null; // Return null if the icon number doesn't match
  }

  render() {
    return (
      <Stack sx={{ width: "100%" }} spacing={4}>
        <ColorlibStepIconRoot
          ownerState={{
            completed: this.props.completed,
            active: this.props.active,
          }}
          className={this.props.className}
        >
          {this.getIcon(this.props.icon)}
        </ColorlibStepIconRoot>
      </Stack>
    );
  }
}

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#273A60",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "rgb(67,206,162)",
  }),
}));
