import React from "react";
import PropTypes from "prop-types";
import InformationBox from "./Fields/InformationBox";
import { Grid } from "@mui/material";
import InputText from "./Fields/InputText";

export default class HcsInformation extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    extraInfo: PropTypes.string,
    changeHandler: PropTypes.func,
    ceoraCampaign: PropTypes.bool,
  };

  handleChange = (value, id) => {
    let data = {};
    data[id] = value;
    this.props.changeHandler(data);
  };

  render() {
    return (
      <Grid container spacing={2} className="customer_info">
        {this.props?.extraInfo === undefined ? (
          <Grid item xs={12}>
            <InformationBox
              content={this.props.label}
              ceoraCampaign={this.props.ceoraCampaign}
            ></InformationBox>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <InputText
              className="input_serial"
              id="extraInfo"
              changeHandler={this.handleChange}
              label={this.props.label}
              defaultValue={this.props.extraInfo}
              sx={{ width: "100%" }}
            ></InputText>
          </Grid>
        )}
      </Grid>
    );
  }
}
