import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import InputText from "../Fields/InputText";

export default class CreditCheckSSN extends React.Component {
  static propTypes = {
    customerInformation: PropTypes.object,
    labels: PropTypes.object,
    changeHandler: PropTypes.func,
    formInformation: PropTypes.object,
    checkHandler: PropTypes.func,
    params: PropTypes.object,
  };

  state = {
    socialSecurityNumber: false,
  };

  componentDidMount() {
    this.setState(this.checkFieldsFinished());
  }

  checkFieldsFinished = () => {
    let newState = this.state;
    for (let key in newState) {
      if (
        this.props.customerInformation[key] !== undefined &&
        this.props.customerInformation[key] !== "" &&
        this.props.customerInformation[key] !== null
      ) {
        newState[key] = true;
      }
    }
    return newState;
  };

  handleChange = (value, id) => {
    let newState = this.state;
    let data = {};
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {this.props.labels.customer_information_title}{" "}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <InputText
            id="socialSecurityNumber"
            label={this.props.labels.social_security_number}
            defaultValue={this.props.customerInformation.socialSecurityNumber}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.socialSecurityNumber
                ? "invalid"
                : ""
            }
          />
        </Grid>
      </Grid>
    );
  }
}
