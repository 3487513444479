import React from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import InputText from "../../Fields/InputText";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Signatory from "./Signatory";

const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];
const formColor = { hcs: "#273A60", arjo: "#0F5376" };

export default class CustomerInformationBusiness extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func,
    customerInformation: PropTypes.object,
    formInformation: PropTypes.object,
    labels: PropTypes.object,
    addSignatory: PropTypes.func,
    removeSignatory: PropTypes.func,
    className: PropTypes.string,
    formType: PropTypes.string,
    params: PropTypes.object,
  };

  state = {
    customerName: false,
    companyNumber: false,
    customerEmail: false,
    signatory: false,
    address: false,
    zipCode: false,
    city: false,
  };

  componentDidMount() {
    this.setState(this.checkFieldsFinsished());
    if (
      socialSecurityNumberCountries.includes(
        this.props.params.lang.toLowerCase()
      )
    ) {
      this.setState({ dateOfBirthCustomerSignatory: true });
    } else {
      this.setState({ socialSecurityNumberCustomerSignatory: true });
    }
  }

  generateSignatory = (id) => {
    return (
      <Signatory
        signatory={this.props.customerInformation.signatories[id]}
        formInformation={this.props.formInformation}
        labels={this.props.labels}
        id={id}
        changeHandler={this.handleSignatoryChange}
        key={id}
        validName={this.state.nameSignatory}
        validEmail={this.state.emailSignatory}
        validPhone={this.state.phoneSignatory}
        className={"invalid"}
        formType={this.props.formType}
        onBoarding={false}
        params={this.props.params}
        number={id.charAt(id.length - 1)}
      />
    );
  };

  handleChange = (value, id) => {
    let newState = this.checkFieldsFinsished();
    newState[id] = id.includes("email") ? mailformat.test(value) : value !== "";
    let data = {};
    data["businessFinished"] = Object.values(newState).every((finished) => {
      return finished;
    });
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  checkFieldsFinsished = () => {
    let newState = this.state;
    for (let key in newState) {
      if (
        (this.props.customerInformation[key] !== undefined &&
          this.props.customerInformation[key] !== "" &&
          this.props.customerInformation[key] !== null) ||
        ((key === "address" || key === "city" || key === "zipCode") &&
          !(this.props.params.lang === "at" || this.props.params.lang === "de"))
      ) {
        newState[key] = true;
      }
    }
    let signatories = this.props.customerInformation.signatories;
    newState["signatory"] = this.signatoryFinished(signatories);
    return newState;
  };

  signatoryFinished = (signatories) => {
    let signatory = true;
    for (const key1 in signatories) {
      for (const key2 in signatories[key1]) {
        if (
          signatories[key1][key2] === undefined ||
          signatories[key1][key2] === "" ||
          signatories[key1][key2] === null ||
          (key2 === "emailSignatory" &&
            !mailformat.test(signatories[key1][key2]))
        ) {
          if (
            (key2 === "dateOfBirthCustomerSignatory" &&
              socialSecurityNumberCountries.includes(
                this.props.params.lang.toLowerCase()
              )) ||
            (key2 === "socialSecurityNumberCustomerSignatory" &&
              !socialSecurityNumberCountries.includes(
                this.props.params.lang.toLowerCase()
              )) ||
            (key2 === "idNumberSignatory" &&
              this.props.params.lang.toLowerCase() !== "at")
          ) {
            continue;
          }
          signatory = false;
        }
      }
    }
    return signatory;
  };

  addRemoveSignatory = (id) => {
    if (id === "remove") {
      this.props.removeSignatory(false);
    }
    if (id === "add") {
      this.props.addSignatory(false);
    }
    let data = {};
    let newState = this.checkFieldsFinsished();
    let signatories = this.props.customerInformation.signatories;
    newState["signatory"] = this.signatoryFinished(signatories);
    data["businessFinished"] = Object.values(newState).every((finished) => {
      return finished;
    });

    this.props.changeHandler(data);
    this.setState(newState);
  };

  handleSignatoryChange = (value, id, signatoryId) => {
    let data = {};
    let newState = this.checkFieldsFinsished();

    let signatories = this.props.customerInformation.signatories;
    let signatory = signatories[signatoryId];
    signatory[id] = value;
    signatories[signatoryId] = signatory;
    data["signatories"] = signatories;

    newState["signatory"] = this.signatoryFinished(signatories);
    data["businessFinished"] = Object.values(newState).every((finished) => {
      return finished;
    });

    this.props.changeHandler(data);
    this.setState(newState);
  };

  render() {
    return (
      <div>
        <Grid container spacing={2} className="customer_info">
          <Grid item xs={4}>
            <InputText
              id="customerName"
              label={
                this.props.formType !== "standard"
                  ? this.props.labels.company
                  : this.props.labels.company_name_text
              }
              changeHandler={this.handleChange}
              defaultValue={this.props.customerInformation.customerName}
              tooltip={this.props.labels.tooltip_company_name}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.customerName
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          {(this.props.params.lang.toLowerCase() === "de" ||
            this.props.params.lang.toLowerCase() === "at") && (
            <>
              <Grid item xs={4}>
                <InputText
                  id="address"
                  label={this.props.labels.label_address}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.address}
                  tooltip={this.props.labels.tooltip_customer_address}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.address
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id="city"
                  label={this.props.labels.label_city}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.city}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid && !this.state.city
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id="zipCode"
                  label={this.props.labels.label_zip_code}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.zipCode}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.zipCode
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <InputText
              id="companyNumber"
              label={this.props.labels.company_number}
              changeHandler={this.handleChange}
              defaultValue={this.props.customerInformation.companyNumber}
              tooltip={this.props.labels.tooltip_company_number}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.companyNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={4}>
            <InputText
              id="customerEmail"
              label={this.props.labels.label_invoice_email}
              changeHandler={this.handleChange}
              defaultValue={this.props.customerInformation.customerEmail}
              tooltip={this.props.labels.tooltip_invoice_email}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.customerEmail
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
        </Grid>
        <br></br>
        <div>
          {Object.keys(this.props.customerInformation.signatories).map((id) => {
            return this.generateSignatory(id);
          })}
        </div>
        <div className="row padding-Top">
          <div className="buttonPadding">
            <Button
              sx={{
                backgroundColor:
                  formColor[this.props.params.comp.toLowerCase()],
              }}
              onClick={() => {
                this.addRemoveSignatory("add");
              }}
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
            >
              {this.props.labels.label_add_signatory}
            </Button>
          </div>
          {Object.keys(this.props.customerInformation.signatories).length >
            1 && (
            <div className="buttonPadding">
              <Button
                onClick={() => {
                  this.addRemoveSignatory("remove");
                }}
                variant="contained"
                color="error"
                startIcon={<RemoveCircleOutlineIcon />}
              >
                {this.props.labels.label_remove_signatory}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
