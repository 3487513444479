import React from "react";
import PropTypes from "prop-types";
import { Grid, Tooltip, TextField, MenuItem } from "@mui/material";
import InputText from "../../Fields/InputText";
import "../css/Signatory.css";
import InputDate from "../../Fields/InputDate";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];

export default class Signatory extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    changeHandler: PropTypes.func,
    customerInformation: PropTypes.object,
    formInformation: PropTypes.object,
    newDealer: PropTypes.object,
    labels: PropTypes.object,
    validName: PropTypes.bool,
    validEmail: PropTypes.bool,
    validPhone: PropTypes.bool,
    className: PropTypes.string,
    formType: PropTypes.string,
    onBoarding: PropTypes.bool,
    params: PropTypes.object,
  };

  handleChange = (value, id) => {
    if (typeof id !== "string") {
      if (value.startsWith(id.dialCode + "0")) {
        value = id.dialCode + value.slice((id.dialCode + "0").length);
      }
      id = "phoneSignatory";
      if (!value.startsWith("+")) {
        value = "+" + value;
      }
    }
    this.props.changeHandler(value, id, this.props.id);
  };

  render() {
    const countryCode =
      this.props.params.lang.toLowerCase() === "uk"
        ? "gb"
        : this.props.params.lang.toLowerCase();
    const size = this.props.params.comp.toLowerCase() === "arjo" ? 12 / 5 : 3;
    return this.props.onBoarding ? (
      <Grid container spacing={2} className="customer_info paddingBottom">
        <Grid item xs={4}>
          <InputText
            id="nameSignatoryNewDealer"
            label={
              this.props.labels.label_customer_name +
              " " +
              this.props.labels.label_signatory_name
            }
            defaultValue={this.props.signatory.nameSignatoryNewDealer}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid &&
              (this.props.signatory.nameSignatoryNewDealer === undefined ||
                this.props.signatory.nameSignatoryNewDealer === "" ||
                this.props.signatory.nameSignatoryNewDealer === null)
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <InputText
            id="emailSignatoryNewDealer"
            label={
              this.props.labels.label_email +
              " " +
              this.props.labels.label_signatory_name
            }
            defaultValue={this.props.signatory.emailSignatoryNewDealer}
            changeHandler={this.handleChange}
            sx={{ width: "100%" }}
            required={true}
            className={
              this.props.formInformation.showInvalid &&
              (this.props.signatory.emailSignatoryNewDealer === undefined ||
                this.props.signatory.emailSignatoryNewDealer === "" ||
                this.props.signatory.emailSignatoryNewDealer === null)
                ? "invalid"
                : ""
            }
          />
        </Grid>
        {socialSecurityNumberCountries.includes(
          this.props.params.lang.toLowerCase()
        ) ? (
          <Grid item xs={4}>
            <InputText
              id="socialSecurityNumberSignatory"
              label={
                this.props.labels.social_security_number +
                " " +
                this.props.labels.label_signatory_name
              }
              defaultValue={this.props.signatory.socialSecurityNumberSignatory}
              tooltip={
                this.props.labels.tooltip_social_security_number_signatory
              }
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid &&
                (this.props.signatory.socialSecurityNumberSignatory ===
                  undefined ||
                  this.props.signatory.socialSecurityNumberSignatory === "" ||
                  this.props.signatory.socialSecurityNumberSignatory ===
                    null) &&
                socialSecurityNumberCountries.includes(
                  this.props.params.lang.toLowerCase()
                )
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
        ) : (
          <Grid item xs={4}>
            <InputDate
              id={"dateOfBirthSignatory"}
              label={this.props.labels.social_security_number}
              changeHandler={this.handleChange}
              date={this.props.signatory.dateOfBirthSignatory}
              tooltip={
                this.props.labels.tooltip_social_security_number_signatory
              }
              required={true}
              maxDate={new Date()}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                (this.props.signatory.dateOfBirthSignatory === undefined ||
                  this.props.signatory.dateOfBirthSignatory === "" ||
                  this.props.signatory.dateOfBirthSignatory === null) &&
                !socialSecurityNumberCountries.includes(
                  this.props.params.lang.toLowerCase()
                )
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
        )}
      </Grid>
    ) : (
      <Grid container spacing={2} className="customer_info paddingBottom">
        <Grid item xs={12}>
          <TextField
            id={this.props.id + "_title"}
            value={
              this.props.labels.label_signatory_name + " " + this.props.number
            }
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={size}>
          <InputText
            id="nameSignatory"
            label={
              this.props.formType !== "standard"
                ? this.props.labels.label_signatory_name
                : this.props.labels.parties_4_1
            }
            changeHandler={this.handleChange}
            defaultValue={this.props.signatory.nameSignatory}
            tooltip={this.props.labels.tooltip_signatory_name}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              (this.props.signatory.nameSignatory === undefined ||
                this.props.signatory.nameSignatory === "" ||
                this.props.signatory.nameSignatory === null)
                ? "invalid"
                : ""
            }
          />
        </Grid>
        {this.props.params.comp.toLowerCase() === "arjo" && (
          <Grid item xs={size}>
            <InputText
              id="positionSignatory"
              label={this.props.labels.label_signatory_position}
              changeHandler={this.handleChange}
              defaultValue={this.props.signatory.positionSignatory}
              tooltip={this.props.labels.tooltip_signatory_position}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                (this.props.signatory.positionSignatory === undefined ||
                  this.props.signatory.positionSignatory === "" ||
                  this.props.signatory.positionSignatory === null)
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
        )}
        <Grid item xs={size}>
          <InputText
            id="emailSignatory"
            label={
              this.props.formType !== "standard"
                ? this.props.labels.label_signatory_email
                : this.props.labels.email_contact
            }
            changeHandler={this.handleChange}
            defaultValue={this.props.signatory.emailSignatory}
            tooltip={this.props.labels.tooltip_signatory_email}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              (this.props.signatory.emailSignatory === undefined ||
                this.props.signatory.emailSignatory === "" ||
                this.props.signatory.emailSignatory === null ||
                !mailformat.test(this.props.signatory.emailSignatory))
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={size}>
          <PhoneInput
            onlyCountries={[countryCode]}
            country={countryCode}
            countryCodeEditable={false}
            autoFormat={true}
            specialLabel={
              this.props.formType !== "standard"
                ? this.props.labels.label_signatory_phone
                : this.props.labels.phone_contact
            }
            value={this.props.signatory.phoneSignatory}
            onChange={this.handleChange}
            component={TextField}
            inputStyle={{ width: "100%" }}
          />
        </Grid>
        {socialSecurityNumberCountries.includes(
          this.props.params.lang.toLowerCase()
        ) ? (
          <Grid item xs={size}>
            <InputText
              id="socialSecurityNumberCustomerSignatory"
              label={
                this.props.labels.social_security_number +
                " " +
                this.props.labels.label_signatory_name
              }
              defaultValue={
                this.props.signatory.socialSecurityNumberCustomerSignatory
              }
              tooltip={
                this.props.labels.tooltip_social_security_number_signatory
              }
              changeHandler={this.handleChange}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid &&
                (this.props.signatory.socialSecurityNumberCustomerSignatory ===
                  undefined ||
                  this.props.signatory.socialSecurityNumberCustomerSignatory ===
                    "" ||
                  this.props.signatory.socialSecurityNumberCustomerSignatory ===
                    null) &&
                socialSecurityNumberCountries.includes(
                  this.props.params.lang.toLowerCase()
                )
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
        ) : (
          <Grid item xs={size}>
            <InputDate
              id={"dateOfBirthCustomerSignatory"}
              label={this.props.labels.social_security_number}
              changeHandler={this.handleChange}
              date={this.props.signatory.dateOfBirthCustomerSignatory}
              tooltip={
                this.props.labels.tooltip_social_security_number_signatory
              }
              required={true}
              maxDate={new Date()}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                (this.props.signatory.dateOfBirthCustomerSignatory ===
                  undefined ||
                  this.props.signatory.dateOfBirthCustomerSignatory === "" ||
                  this.props.signatory.dateOfBirthCustomerSignatory === null) &&
                !socialSecurityNumberCountries.includes(
                  this.props.params.lang.toLowerCase()
                )
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
        )}
        {this.props.params.lang.toLowerCase() === "at" && (
          <>
            <Grid item xs={4}>
              <Tooltip
                title={
                  <span style={{ fontSize: "12px" }}>
                    {this.props.labels.tooltip_id_select}
                  </span>
                }
                placement="top"
              >
                <TextField
                  className="id_select"
                  value={this.props.signatory.idTypeSignatory}
                  name={"idTypeSignatory"}
                  onChange={(event) => {
                    this.handleChange(event.target.value, event.target.name);
                  }}
                  select
                  label={this.props.labels.label_id_type + " *"}
                >
                  {this.props.labels.id_select.map((option) => {
                    return (
                      <MenuItem value={option} key={option} id="test">
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <InputText
                id="idNumberSignatory"
                label={this.props.labels.label_id_number}
                changeHandler={this.handleChange}
                defaultValue={this.props.signatory.idNumberSignatory}
                tooltip={this.props.labels.tooltip_label_id_number}
                required={true}
                sx={{ width: "100%" }}
                className={
                  this.props.formInformation.showInvalid &&
                  (this.props.signatory.idNumberSignatory === undefined ||
                    this.props.signatory.idNumberSignatory === "" ||
                    this.props.signatory.idNumberSignatory === null)
                    ? "invalid"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                title={
                  <span style={{ fontSize: "12px" }}>
                    {this.props.labels.tooltip_pep}
                  </span>
                }
                placement="top"
              >
                <TextField
                  className="id_select"
                  value={this.props.signatory.pepSignatory}
                  name={"pepSignatory"}
                  onChange={(event) => {
                    this.handleChange(event.target.value, event.target.name);
                  }}
                  select
                  label={this.props.labels.pep_label + " *"}
                >
                  {this.props.labels.pep.map((option) => {
                    return (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}
