import React from "react";
import "./css/TextBox.css";
import PropTypes from "prop-types";

export default class TextBox extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    title: PropTypes.string,
  };
  render() {
    return (
      <div className="textbox_wrapper">
        <div className="textbox_title">
          <p>{this.props.content !== undefined ? this.props.title : ""}</p>
        </div>
        <div className="textbox_content">
          <p>
            {this.props.content !== undefined
              ? this.props.content
              : this.props.title}
          </p>
        </div>
      </div>
    );
  }
}
