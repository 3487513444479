import React from "react";
import PropTypes from "prop-types";
import InputNumber from "../../component/Fields/InputNumber";
import ProductList from "../../component/Fields/ProductList";
import {
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import DisplayField from "../../component/Fields/DisplayField";
import debounce from "lodash.debounce";

export default class ConstructionProduct extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    number: PropTypes.string,
    productValues: PropTypes.object,
    labels: PropTypes.object,
    variables: PropTypes.object,
    products: PropTypes.object,
    updateProduct: PropTypes.func,
    calculateNewFee: PropTypes.func,
  };

  state = {
    minResidual: 5,
    maxResidual: 40,
    productList: "productList2",
  };

  componentDidMount() {
    if (this.props.productValues.ownServiceIncludes.maintenance) {
      this.setState({ productList: "productList1" });
    }
  }

  productFinished = debounce(() => {
    const data = {
      finished:
        this.props.productValues.quantity > 0 &&
        this.props.productValues.price > 0 &&
        this.props.productValues.name !== null,
    };
    this.props.updateProduct(data, this.props.id);
  }, 100);

  finished = (fieldId) => {
    switch (fieldId) {
      case "quantity":
        return this.props.productValues.quantity > 0;
      case "price":
        return this.props.productValues.price > 0;
      case "name":
        return this.props.productValues.name !== null;
      default:
        return -1;
    }
  };

  /**
   *
   * @param {Number} value - The new value of the field
   * @param {String} id    - id of the field the value is from
   *
   * Updates the field with new value.
   */

  handleChange = (value, id) => {
    let index = this.props.formInformation.contractLengths.indexOf(
      this.props.formInformation.contractLength
    );
    const fieldId = id.split("_")[1];
    let data = {};
    data[fieldId] = value;
    if (fieldId === "name" && value !== null) {
      if (
        this.props.products[this.state.productList][value].service[index] > 0
      ) {
        data["service"] =
          this.props.products[this.state.productList][value].service[index];
        data["upCare"] = true;
      } else {
        data["service"] = 0;
        data["upCare"] = false;
      }
      if (this.props.products[this.state.productList][value]?.residual) {
        if (
          this.props.products[this.state.productList][value]?.residual[index]
            .length > 1
        ) {
          this.setState({
            minResidual:
              this.props.products[this.state.productList][value]?.residual[
                index
              ][0],
            maxResidual:
              this.props.products[this.state.productList][value]?.residual[
                index
              ][1],
          });
          data["residual"] =
            this.props.products[this.state.productList][value]?.residual[
              index
            ][0];
        } else {
          this.setState({
            minResidual: 5,
            maxResidual: 40,
          });
        }
      } else {
        this.setState({
          minResidual: 5,
          maxResidual: 40,
        });
      }
    } else if (fieldId === "name") {
      data["service"] = 0;
    }
    if (fieldId === "residual") {
      if (this.props.productValues.name !== "") {
        let name = this.props.productValues.name;
        if (this.props.products[this.state.productList][name]?.residual) {
          if (
            this.props.products[this.state.productList][name]?.residual[index]
              .length > 1
          ) {
            this.setState({
              minResidual:
                this.props.products[this.state.productList][name]?.residual[
                  index
                ][0],
              maxResidual:
                this.props.products[this.state.productList][name]?.residual[
                  index
                ][1],
            });
          } else {
            this.setState({
              minResidual: 5,
              maxResidual: 40,
            });
          }
        } else {
          this.setState({
            minResidual: 5,
            maxResidual: 40,
          });
        }
      }
    }
    if (fieldId === "quantity") {
      data["totalProductPrice"] = value * this.props.productValues.price;
    }
    if (fieldId === "price") {
      data["totalProductPrice"] = value * this.props.productValues.quantity;
    }
    this.props.updateProduct(data, this.props.id);
    this.productFinished();
    this.props.calculateNewFee();
  };

  handleClick = (e) => {
    const productName = this.props.productValues.name;
    let data = { ownServiceIncludes: {} };
    let newState = this.state;
    let index = this.props.formInformation.contractLengths.indexOf(
      this.props.formInformation.contractLength
    );

    if (e.target.id === "repairs" && e.target.checked) {
      data.ownServiceIncludes[e.target.id] = e.target.checked;
      data.ownServiceIncludes["maintenance"] = false;
      newState.productList = e.target.checked ? "productList2" : "productList1";
    }
    if (e.target.id === "maintenance" && e.target.checked) {
      data.ownServiceIncludes[e.target.id] = e.target.checked;
      data.ownServiceIncludes["repairs"] = false;
      newState.productList = e.target.checked ? "productList1" : "productList2";
    }
    this.setState(newState);

    if (
      productName !== null &&
      this.props.products[this.state.productList][productName].service[index] >
        0
    ) {
      data["service"] =
        this.props.products[this.state.productList][productName].service[index];
      data["upCare"] = true;
    } else if (productName !== null) {
      data["service"] = 0;
      data["upCare"] = false;
    }

    this.props.updateProduct(data, this.props.id);
    this.productFinished();
    this.props.calculateNewFee();
  };

  render() {
    return (
      <Grid container spacing={2} className="Product">
        <Grid item xs={12}>
          <TextField
            id={this.props.id + "_title"}
            value={this.props.labels.product + " " + this.props.number}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={2}>
          <InputNumber
            label={this.props.labels.quantity}
            id={this.props.id + "_quantity"}
            defaultValue={this.props.productValues.quantity}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_quantity}
            className={
              this.props.formInformation.showInvalid &&
              !this.finished("quantity")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={7}>
          <ProductList
            id={this.props.id + "_name"}
            defaultValue={this.props.productValues.name}
            changeHandler={this.handleChange}
            label={this.props.labels.choose_a_product}
            products={this.props.products[this.state.productList]}
            tooltip={this.props.labels.tooltip_product}
            required={true}
            className={
              this.props.formInformation.showInvalid && !this.finished("name")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputNumber
            label={this.props.labels.product_price}
            id={this.props.id + "_price"}
            defaultValue={this.props.productValues.price}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_product_price}
            endAdornment={this.props.labels.currency}
            className={
              this.props.formInformation.showInvalid && !this.finished("price")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <InputNumber
            label={this.props.labels.residual_value}
            id={this.props.id + "_residual"}
            defaultValue={this.props.productValues.residual}
            changeHandler={this.handleChange}
            required={true}
            min={this.state.minResidual}
            max={this.state.maxResidual}
            endAdornment="%"
            tooltip={this.props.labels.tooltip_residual_value}
          />
        </Grid>
        <Grid item xs={4}>
          <DisplayField
            id={this.props.id + "_totalProductPrice"}
            value={
              this.props.productValues.totalProductPrice !== undefined
                ? this.props.productValues.totalProductPrice.toString()
                : ""
            }
            label={this.props.labels.total_product_price}
            currency={this.props.labels.currency}
          />
        </Grid>
        {!this.props.productValues.upCare &&
        this.props.productValues.name !== null ? (
          <Grid item xs={4}>
            <InputNumber
              label={this.props.labels.service_per_month}
              id={this.props.id + "_service"}
              defaultValue={this.props.productValues.service}
              changeHandler={this.handleChange}
              tooltip={this.props.labels.tooltip_service_per_year}
              endAdornment={this.props.labels.currency}
            />
          </Grid>
        ) : (
          <Grid item xs={4}>
            <TextField
              label={this.props.labels.service}
              id={this.props.id + "_service"}
              value={
                this.props.productValues.ownServiceIncludes.repairs
                  ? this.props.labels.repairs_included
                  : this.props.labels.maintenance_included || ""
              }
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
        )}
        {!this.props.productValues.upCare &&
        this.props.productValues.name !== null ? (
          <Grid item xs={12}>
            <Typography variant="h6">
              {this.props.labels.service_contact}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6">
              {this.props.labels.service_included}
            </Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          <FormGroup row={true}>
            <FormControlLabel
              control={<Checkbox id="maintenance" onClick={this.handleClick} />}
              label={this.props.labels.maintenance}
              sx={{ width: "130px" }}
              checked={
                this.props.productValues.ownServiceIncludes["maintenance"]
              }
            />
            <FormControlLabel
              control={<Checkbox id="repairs" onClick={this.handleClick} />}
              label={this.props.labels.repairs}
              checked={this.props.productValues.ownServiceIncludes["repairs"]}
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
  }
}
