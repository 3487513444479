import React from "react";
import PropTypes from "prop-types";
import config from "../../res/config.json";
import { cssStyles } from "./pdfContractStyles";
import { formatNumber } from "../../logic/GeneralFunctions";

const emptyLabel = " ";
let objektNr = 0;

export default class FloorplanContract extends React.Component {
  static propTypes = {
    newDealerInformation: PropTypes.object,
    labels: PropTypes.object,
  };

  state = {
    headerImgSrc: "",
  };

  render() {
    return (
      <div className="wrapper">
        <style>{cssStyles}</style>
        <div id="contractPdf" className="contract-body">
          <div className="contract-header">
            <img
              src={
                config.ROOT +
                `getimage?company=hcs&country=images&type=logo_flat.png`
              }
              alt=""
              width="50%"
            />
            <div>
              <table className="contract-table">
                <tr>
                  <td className="contract-td">
                    <p>{this.props.labels.contract_number}</p>
                  </td>
                  <td className="contract-td contract-min-width">
                    <p>{1234}</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <h2 className="contract-align-center">
            {this.props.labels.label_ram_deal.toUpperCase()}
          </h2>
          <div className="contract-parties">
            <table className="contract-table">
              <tr>
                <td colSpan="2">{this.props.labels.label_credit_giver}</td>
                <td colSpan="2">{this.props.labels.label_credit_taker}</td>
              </tr>
              <tr>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.customer_name}
                  </p>
                  <p>Husqvarna Commercial Solutions AB</p>
                </td>
                <td
                  colSpan="2"
                  className="contract-td contract-half-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.customer_name}
                  </p>
                  <p>{this.props.newDealerInformation.newDealerName}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.company_number}
                  </p>
                  <p>556745-5893</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.postal_adress}
                  </p>
                  <p>Ågatan 7</p>
                </td>
                <td
                  colSpan="1"
                  className="contract-td contract-quater-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.company_number}
                  </p>
                  <p>{this.props.newDealerInformation.organizationNumber}</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.postal_adress}
                  </p>
                  <p>{this.props.newDealerInformation.postAddress}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.zip_code}
                  </p>
                  <p>582 22</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_city}
                  </p>
                  <p>Linköping</p>
                </td>
                <td
                  colSpan="1"
                  className="contract-td contract-quater-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.zip_code}
                  </p>
                  <p>{this.props.newDealerInformation.nd_zipCode}</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_city}
                  </p>
                  <p>{this.props.newDealerInformation.nd_city}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.phone_number}
                  </p>
                  <p>+4670 782 50 75</p>
                </td>
                <td
                  colSpan="1"
                  className="contract-td contract-quater-space  contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.contact_person}
                  </p>
                  <p>{this.props.newDealerInformation.contactPerson}</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.phone_number}
                  </p>
                  <p>{this.props.newDealerInformation.phoneContactPerson}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_email}
                  </p>
                  <p>HCS-support@husqvarnagroup.com</p>
                </td>
                <td
                  colSpan="2"
                  className="contract-td contract-half-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.label_email}
                  </p>
                  <p>{this.props.newDealerInformation.emailContactPerson}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="contract-half-space"></td>
                <td
                  colSpan="2"
                  className="contract-td contract-half-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.label_invoice_email}
                  </p>
                  <p>
                    {this.props.newDealerInformation.wantEInvoice ||
                    this.props.newDealerInformation.eInvoiceEmail === ""
                      ? this.props.newDealerInformation.eInvoiceEmail
                      : this.props.newDealerInformation.emailContactPerson}
                  </p>
                </td>
              </tr>
            </table>
          </div>

          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="4">{this.props.labels.contract_length}</th>
              </tr>
              <tr>
                <td colSpan="2" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_contract_period_from}
                  </p>
                  <p>{"2024-02-01"}</p>
                </td>
                <td colSpan="1" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_contract_period_to}
                  </p>
                  <p>{"2025-02-01"}</p>
                </td>
                <td colSpan="1" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_eventual_contract_extension}
                  </p>
                  <p>{"12"}</p>
                </td>
              </tr>
            </table>
          </div>

          <div className="margin-top-large">
            <p>{this.props.labels.label_p1_onboarding}</p>
            <p>{this.props.labels.label_p2_onboarding}</p>
            <p>{this.props.labels.label_p3_onboarding}</p>
          </div>

          <div className="page-break"></div>

          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="3">
                  {this.props.labels.label_bankinfo.toUpperCase()}
                </th>
              </tr>
              <tr>
                <td colSpan="3" className="contract-td">
                  <p className="contract-helper-text">
                    {this.props.labels.label_bankgiro_postgiro}
                  </p>
                  <p>{this.props.newDealerInformation.bankGiroPostGiro}</p>
                </td>
              </tr>
            </table>
          </div>

          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="4">{"HUSQVARNA-KUNDUPPGIFTER"}</th>
              </tr>
              <tr>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">Husqvarna-kundnummer</p>
                  <p>{this.props.newDealerInformation.husqvarnaNumber}</p>
                </td>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_has_husqvarna_portaluser}
                  </p>
                  <p>
                    {this.props.newDealerInformation.hasHusqvarnaPortalUser
                      ? "JA"
                      : "NEJ"}
                  </p>
                </td>
              </tr>
              {this.props.newDealerInformation.hasHusqvarnaPortalUser && (
                <>
                  <tr>
                    <td colSpan="2" className="contract-td contract-half-space">
                      <p className="contract-helper-text">
                        {this.props.labels.label_first_name}
                      </p>
                      <p>
                        {this.props.newDealerInformation.husqvarnaUserFirstName}
                      </p>
                    </td>
                    <td colSpan="2" className="contract-td contract-half-space">
                      <p className="contract-helper-text">
                        {this.props.labels.label_last_name}
                      </p>
                      <p>
                        {this.props.newDealerInformation.husqvarnaUserLastName}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="contract-td contract-half-space">
                      <p className="contract-helper-text">
                        {this.props.labels.label_email}
                      </p>
                      <p>
                        {this.props.newDealerInformation.husqvarnaUserEmail}
                      </p>
                    </td>
                    <td colSpan="2" className="contract-td contract-half-space">
                      <p className="contract-helper-text">
                        {this.props.labels.label_husqvarna_idnumber}
                      </p>
                      <p>{this.props.newDealerInformation.husqvarnaUserId}</p>
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>

          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="3">FAKTURERINGSADRESS</th>
              </tr>
              <tr>
                <td colSpan="3" className="contract-td">
                  <p className="contract-helper-text">
                    {this.props.labels.label_different_invoice_adress}
                  </p>
                  <p>
                    {this.props.newDealerInformation.differentInvoiceAddress
                      ? this.props.labels.positive_answer.toUpperCase()
                      : this.props.labels.negative_answer.toUpperCase()}
                  </p>
                </td>
              </tr>
              {this.props.newDealerInformation.differentInvoiceAddress ? (
                <>
                  <tr>
                    <td
                      colSpan="1"
                      className="contract-td contract-third-space"
                    >
                      <p className="contract-helper-text">
                        {this.props.labels.label_invoice_adress}
                      </p>
                      <p>{this.props.newDealerInformation.invoiceAddress}</p>
                    </td>
                    <td
                      colSpan="1"
                      className="contract-td contract-third-space"
                    >
                      <p className="contract-helper-text">
                        {this.props.labels.zip_code}
                      </p>
                      <p>{this.props.newDealerInformation.invoiceZipCode}</p>
                    </td>
                    <td
                      colSpan="1"
                      className="contract-td contract-third-space"
                    >
                      <p className="contract-helper-text">
                        {this.props.labels.label_city}
                      </p>
                      <p>{this.props.newDealerInformation.invoiceCity}</p>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td
                      colSpan="1"
                      className="contract-td contract-third-space"
                    >
                      <p className="contract-helper-text">
                        {this.props.labels.label_invoice_adress}
                      </p>
                      <p>{this.props.newDealerInformation.postAddress}</p>
                    </td>
                    <td
                      colSpan="1"
                      className="contract-td contract-third-space"
                    >
                      <p className="contract-helper-text">
                        {this.props.labels.zip_code}
                      </p>
                      <p>{this.props.newDealerInformation.nd_zipCode}</p>
                    </td>
                    <td
                      colSpan="1"
                      className="contract-td contract-third-space"
                    >
                      <p className="contract-helper-text">
                        {this.props.labels.label_city}
                      </p>
                      <p>{this.props.newDealerInformation.nd_city}</p>
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="4">VERKLIG HUVUDMAN</th>
              </tr>
              {this.props.newDealerInformation.beneficialOwners.map(
                (owner, index) => (
                  <>
                    <tr key={index}>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.label_first_name}
                        </p>
                        <p>{owner.firstName}</p>
                      </td>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.label_last_name}
                        </p>
                        <p>{owner.lastName}</p>
                      </td>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">Personnummer</p>
                        <p>{owner.personalNumber}</p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="2"
                        className="contract-td contract-half-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.label_ownership_percentage}
                        </p>
                        <p>{owner.ownershipPercentage}</p>
                      </td>
                      <td
                        colSpan="2"
                        className="contract-td contract-half-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.label_is_beneficial_owner_pep}
                        </p>
                        <p>
                          {owner.isPEP
                            ? this.props.labels.positive_answer.toUpperCase()
                            : this.props.labels.negative_answer.toUpperCase()}
                        </p>
                      </td>
                    </tr>
                    {/* Empty row for spacing */}
                    <tr>
                      <td colSpan="4" style={{ height: "20px" }}></td>
                    </tr>
                  </>
                )
              )}
            </table>
          </div>

          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="4">FIRMATECKNARE</th>
              </tr>
              {this.props.newDealerInformation.newDealerSignatories.map(
                (signatory, index) => (
                  <>
                    <tr key={index}>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.label_first_name}
                        </p>
                        <p>{signatory.firstName}</p>
                      </td>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.label_last_name}
                        </p>
                        <p>{signatory.lastName}</p>
                      </td>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">Personnummer</p>
                        <p>{signatory.socialSecurityNumberSignatory}</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="contract-td">
                        <p className="contract-helper-text">
                          {this.props.labels.label_email}
                        </p>
                        <p>{signatory.emailSignatoryNewDealer}</p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.postal_adress}
                        </p>
                        <p>{signatory.addressSignatory}</p>
                      </td>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.zip_code}
                        </p>
                        <p>{signatory.zipCodeSignatory}</p>
                      </td>
                      <td
                        colSpan="1"
                        className="contract-td contract-third-space"
                      >
                        <p className="contract-helper-text">
                          {this.props.labels.label_city}
                        </p>
                        <p>{signatory.citySignatory}</p>
                      </td>
                    </tr>
                    {/* Empty row for spacing */}
                    <tr>
                      <td colSpan="4" style={{ height: "20px" }}></td>
                    </tr>
                  </>
                )
              )}
            </table>
          </div>
        </div>
      </div>
    );
  }
}
