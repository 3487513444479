import React from "react";
import UpCareProduct from "./Forms/UpCare";

import Information from "../component/Information";

import Accessories from "../component/Accessories";

import { calculateTotalFee } from "../logic/Calculations";
import { useParams } from "react-router-dom";
import DisplayField from "../component/Fields/DisplayField";
import debounce from "lodash.debounce";

import FormRes from "../component/FormRes/FormRes";
import { getCountryInformation } from "../logic/ServerCalls";

import {
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import CustomerInformationBusiness from "../component/Information/Customer/Business";
import CustomerInformationPrivate from "../component/Information/Customer/Private";
import CustomerInformationIdentification from "../component/Information/Customer/Identification";
import DealerInformation from "../component/Information/Dealer/DealerInformation";
import NewDealer from "../component/Information/Dealer/NewDealer";

import NotFound from "../component/NotFound";
import configSource from "../res/config.json";
import config from "./res/config.json";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import "./css/logisnext.css";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Logisnext extends React.Component {
  state = {
    labels: {},
    variables: {},
    products: {},
    headerText: "",
    params: {},
    productsValues: {
      product1: {
        deliveryDate: null,
        productList: "productList1",
        name: null,
        quantity: 1,
        serial: [],
        price: 0,
        service: 0,
        residual: 10,
        accessory: null,
        upCare: false,
        firstIncresedRent: 0,
        totalProductPrice: 0,
        finished: false,
        ownServiceIncludes: {
          storage: false,
          consumables: false,
          education: false,
          maintenance: true,
          repairs: false,
          other: false,
        },
      },
    },
    accessories: {},
    customerInformation: {
      privateFinished: false,
      businessFinished: false,
      identificationFinished: false,
      dealerFinished: false,
      dealerName: "",
      dealerEmail: "",
      customerNumber: "",
      idType: "",
      idNumber: "",
      pep: "",
      repurchase: "",
      dateOfBirth: null,
      socialSecurityNumber: "",
      address: "",
      signatories: {
        signatory1: {
          name: undefined,
          email: undefined,
          phone: undefined,
        },
      },
    },
    formInformation: {
      totalMonthlyFee: 0,
      totalPrice: 0,
      totalResidue: 0,
      totalAccessoriesPrice: 0,
      amortization: 0,
      formState: "edit",
      showInvalid: false,
      quoteChecked: false,
      orderChecked: false,
      newDealerChecked: false,
      newDealerNotChecked: false,
      idChecked: false,
      ampChecked: false,
      contractLength: 36,
      contractLengths: [36],
      customer: "Private",
      useCampaign: false,
      extraInfo: "",
    },
    newDealerInformation: {
      newDealerFinished: false,
      verifyNewDealer: false,
      newDealerName: "",
      organizationNumber: "",
      taxNumber: "",
      invoiceEmail: "",
      contactPerson: "",
      emailContactPerson: "",
      phoneContactPerson: "",
      nameSignatory: "",
      emailSignatory: "",
      socialSecurityNumberSignatory: "",
      dateOfBirthSignatory: null,
    },
    campaign: false,
    extraFiles: [],
    firstRender: true,
  };

  /**
   *  Gets called after first render.
   */
  componentDidMount() {
    if (this.validateParams()) {
      this.setState({
        firstRender: false,
        params: { ...this.props.params, comp: "logisnext" },
      });
      this.SetFormInformation();
    }
  }

  /**
   * Requests labels, headerText, productlist and variables from server and puts them in state.
   */
  SetFormInformation = () => {
    (async () => {
      try {
        const comp = "logisnext";
        const lang = this.props.params.lang.toLowerCase();
        const formType = this.props.params.formType.toLowerCase();
        let data = await getCountryInformation(comp, lang, formType);
        data = data.data;

        let state = this.state;
        if (
          formType === "upcare" ||
          formType === "flexpro" ||
          formType === "construction" ||
          formType === "standard"
        ) {
          state.formInformation.customer = "Business";
        } else {
          state.formInformation.customer = "Private";
        }
        const indexMiddle = Math.floor(
          data["Products"]["contractLengths"].length / 2
        );
        state.labels = data["Labels"];
        state.variables = data["Variables"];
        state.products = data["Products"];
        state.headerText = data["HeaderText"];
        state.campaign = data["campaign"];
        state.formInformation.contractLengths =
          data["Products"]["contractLengths"];
        state.formInformation.contractLength =
          data["Products"]["contractLengths"][indexMiddle];
        this.setState(state);
      } catch (error) {
        //TODO redirect
        console.log(error);
      }
    })();
  };

  /**
   * Passes values to function and sets state with result
   */
  newCalculationsProductFee = debounce(() => {
    const result = calculateTotalFee(
      this.state.productsValues,
      this.state.formInformation.contractLength,
      this.state.variables,
      this.state.accessories,
      this.state.params.formType.toLowerCase()
    );
    let formInformation = this.state.formInformation;
    Object.assign(formInformation, result);
    this.setState({ formInformation: formInformation });
  }, 500);

  /**
   *
   * @param {Object} value - Updated product information
   * @param {String} id    - Product id
   *
   * Updates given product with information from value
   */
  updateProduct = (value, id) => {
    let productsValues = this.state.productsValues;
    let variables = this.state.variables;
    for (let key in value) {
      productsValues[id][key] =
        key === "ownServiceIncludes"
          ? Object.assign(productsValues[id][key], value[key])
          : (productsValues[id][key] = value[key]);
    }
    this.setState({
      productsValues: productsValues,
    });
    if ("interest" in value) {
      variables["function_interest"] = value["interest"];
      this.setState({
        variables: variables,
      });
    }
  };

  /**
   * Used by LeasePlus and UpCare.
   * Calculates total monthly fee. Updates price, totalProductPrice and totalMonthlyFee.
   */
  updateTotalMonthlyFee = () => {
    let formInformation = this.state.formInformation;
    let totalMonthlyFee = 0;
    const index =
      this.state.params.formType.toLowerCase() === "upcare" ||
      this.state.params.formType.toLowerCase() === "leaseplus"
        ? this.state.formInformation.contractLengths.indexOf(
            this.state.formInformation.contractLength
          )
        : 0;
    Object.keys(this.state.productsValues).forEach((productId) => {
      let product = this.state.productsValues[productId];
      let data = {};
      let price = 0;
      let accessoryPrice = 0;

      if (product.name !== null) {
        price =
          this.state.products[this.state.productsValues[productId].productList][
            product.name
          ]["price"][index];
      }
      if (product.accessory !== null) {
        accessoryPrice =
          this.state.products.accessories[product.accessory].price[index];
      }

      let totalProductPrice = price * product.quantity + accessoryPrice;

      data["price"] = price;
      data["totalProductPrice"] = totalProductPrice;
      totalMonthlyFee += totalProductPrice;
      this.updateProduct(data, productId);
    });
    let accessoryPriceExtra = 0;
    let annuityAccessory = 0;
    if (Object.keys(this.state.accessories).length > 0) {
      let accessories = this.state.accessories;
      Object.keys(this.state.accessories).forEach((accessory) => {
        if (accessories[accessory].TotalAccessoriesPrice !== undefined) {
          accessoryPriceExtra += accessories[accessory]?.TotalAccessoriesPrice;
        }
      });
      let t =
        (1 + this.state.variables.function_interest / 1200) **
        this.state.formInformation.contractLength;
      annuityAccessory =
        (accessoryPriceExtra *
          t *
          (this.state.variables.function_interest / 1200)) /
        ((1 +
          (this.state.variables.function_interest / 1200) *
            this.state.variables.payment) *
          (t - 1));
      accessoryPriceExtra = annuityAccessory;
    }
    totalMonthlyFee += accessoryPriceExtra;
    formInformation.totalMonthlyFee = totalMonthlyFee;
    this.setState({
      formInformation: formInformation,
    });
  };

  /**
   *
   * @param {Array<File>} files
   *
   * Puts the files in the array in state.
   */
  addExtraFiles = (files) => {
    let extraFiles = this.state.extraFiles;
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      extraFiles.push(element);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * @param {File} file
   *
   * Removes given file from state if it exists.
   */
  removeExtraFiles = (file) => {
    let extraFiles = this.state.extraFiles;
    let index = extraFiles.indexOf(file);
    if (index !== -1) {
      extraFiles.splice(index, 1);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * Adds new object in productsValues with newId.
   * newId = highest oldId + 1.
   * Also populates the new product objects with deafult values.
   */
  addAnotherProduct = () => {
    const newId = Object.keys(this.state.productsValues).length + 1;

    const key = "product" + newId;

    let productsValues = this.state.productsValues;
    productsValues[key] = {};
    productsValues[key]["deliveryDate"] = null;
    productsValues[key]["productList"] = "productList1";
    productsValues[key]["name"] = null;
    productsValues[key]["quantity"] = 1;
    productsValues[key]["serial"] = [];
    productsValues[key]["price"] = 0;
    productsValues[key]["accessory"] = null;
    productsValues[key]["service"] = 0;
    productsValues[key]["residual"] = 10;
    productsValues[key]["upCare"] = false;
    productsValues[key]["firstIncresedRent"] = 0;
    productsValues[key]["totalProductPrice"] = 0;
    productsValues[key]["finished"] = false;
    productsValues[key]["ownServiceIncludes"] = {
      storage: false,
      consumables: false,
      education: false,
      maintenance: true,
      repairs: false,
      other: false,
    };

    this.setState({
      productsValues: productsValues,
    });
  };

  /**
   *
   * Removes the last product from state if there are more than one
   * and updates totalMonthlyFee in state.
   */
  removeProduct = () => {
    let productsValues = this.state.productsValues;
    const id = Object.keys(productsValues).at(-1);
    delete productsValues[id];
    this.setState({
      productsValues: productsValues,
    });
    if (
      this.state.params.formType.toLowerCase() === "upcare" ||
      this.state.params.formType.toLowerCase() === "leaseplus"
    ) {
      this.updateTotalMonthlyFee();
    } else {
      this.newCalculationsProductFee();
    }
  };

  /**
   * Adds a new Object to accessories.
   */
  addAccessories = () => {
    const key = Object.keys(this.state.accessories).length + 1;
    const id = "accessories" + key;
    let accessoreis = this.state.accessories;
    accessoreis[id] = {};
    this.setState({
      accessoreis: accessoreis,
    });
  };

  /**
   * Removes last object from accessories.
   */
  removeAccessories = () => {
    const id = Object.keys(this.state.accessories).at(-1);
    let accessoreis = this.state.accessories;
    delete accessoreis[id];
    this.setState({
      accessoreis: accessoreis,
    });
    if (
      this.state.params.formType.toLowerCase() === "upcare" ||
      this.state.params.formType.toLowerCase() === "leaseplus"
    ) {
      this.updateTotalMonthlyFee();
    } else {
      this.newCalculationsProductFee();
    }
  };

  /**
   * Adds a new object to signatories.
   */
  addSignatory = () => {
    const key =
      Object.keys(this.state.customerInformation.signatories).length + 1;
    const id = "signatory" + key;
    let signatories = this.state.customerInformation.signatories;
    signatories[id] = { name: "", email: "", phone: "" };
    this.setState({
      signatories: signatories,
    });
  };

  /**
   * Remove last object from signatories.
   */
  removeSignatory = () => {
    const id = Object.keys(this.state.customerInformation.signatories).at(-1);
    let signatories = this.state.customerInformation.signatories;
    delete signatories[id];
    this.setState({
      signatories: signatories,
    });
  };

  generateAccessories = (id) => {
    return (
      <Accessories
        id={id}
        key={id}
        labels={this.state.labels}
        handleAccessories={this.handleAccessories}
        accessories={this.state.accessories[id]}
        number={id.charAt(id.length - 1)}
      />
    );
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of customerInformation based on keys in info.
   */
  handleCustomerInfoChange = (info) => {
    let customerInformation = this.state.customerInformation;
    for (let id in info) {
      customerInformation[id] = info[id];
    }
    this.setState({
      customerInformation: customerInformation,
    });
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of newDealerInformation based on keys in info.
   */
  handleNewDealerInfoChange = (info) => {
    let newDealerInformation = this.state.newDealerInformation;
    for (let id in info) {
      newDealerInformation[id] = info[id];
    }
    this.setState({
      newDealerInformation: newDealerInformation,
    });
  };

  /**
   *
   * @param {Object} value
   * @param {String} id
   *
   * Updates the value of the accessory with key id with the new value.
   */
  handleAccessories = (value, id) => {
    let accessories = this.state.accessories;

    Object.entries(value).forEach(([key, val]) => {
      accessories[id][key] = val;
    });
    this.setState(accessories);
    if (this.state.params.formType.toLowerCase() === "upcare") {
      this.updateTotalMonthlyFee();
    } else {
      this.newCalculationsProductFee();
    }
  };

  handleFormInformationChange = (info) => {
    let formInformation = this.state.formInformation;
    for (let id in info) {
      formInformation[id] = info[id];
    }
    this.setState({
      formInformation: formInformation,
    });
  };

  /**
   *
   * @param {Object} event
   *
   * Handles checkbox check depending on case.
   */
  handleChecked = (event) => {
    let newState;
    let newCustomerState;
    let formInformation = this.state.formInformation;
    let newDealerInformation = this.state.newDealerInformation;
    switch (event.target.id) {
      case "quote_check":
        newState = this.state.formInformation.quoteChecked ? false : true;
        if (newState && this.state.formInformation.orderChecked) {
          formInformation.orderChecked = false;
        }
        formInformation.quoteChecked = newState;
        this.setState({ formInformation: formInformation });
        break;
      case "order_check":
        newCustomerState = this.state.customerInformation;
        newState = this.state.formInformation.orderChecked ? false : true;
        if (!newState) {
          formInformation.idChecked = false;
        }
        newCustomerState["idType"] = this.state.labels.id_select[0];
        newCustomerState["idNumber"] = "";
        newCustomerState["pep"] = this.state.labels.pep[0];
        newCustomerState["repurchase"] = this.state.labels.repurchase[0];
        if (newState && this.state.formInformation.quoteChecked) {
          formInformation.quoteChecked = false;
        }
        formInformation.orderChecked = newState;
        this.setState({
          formInformation: formInformation,
          customerInformation: newCustomerState,
        });
        break;
      case "id_checked":
        newState = this.state.formInformation.idChecked ? false : true;
        formInformation.idChecked = newState;
        this.setState({ formInformation: formInformation });
        break;
      case "new_dealer_yes":
        formInformation.newDealerChecked = true;
        formInformation.newDealerNotChecked = false;
        this.setState({ formInformation: formInformation });
        break;
      case "new_dealer_no":
        formInformation.newDealerNotChecked = true;
        formInformation.newDealerChecked = false;
        this.setState({ formInformation: formInformation });
        break;
      case "verify_new_dealer":
        newState = this.state.newDealerInformation.verifyNewDealer
          ? false
          : true;
        newDealerInformation.verifyNewDealer = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;
      case "amp_checked":
        newState = this.state.formInformation.ampChecked ? false : true;
        formInformation.ampChecked = newState;
        this.setState({ formInformation: formInformation });
        break;
      default:
        throw Error("Unknown button ID");
    }
  };

  /**
   *
   * @param {String} value
   *
   * Switches the current formState to new value.
   */
  switchFormState = (value) => {
    let formInformation = this.state.formInformation;
    formInformation.formState = value;
    this.setState(formInformation);
  };

  /**
   *
   * @param {Object} event
   *
   * Updates the contractLenght in state to new value, calls for update of totalMonthlyFee.
   */
  changeContractLength = (event) => {
    let state = this.state;
    state.formInformation.contractLength = event.target.value;
    this.setState(state);
    if (this.state.params.formType.toLowerCase() === "flexpro") {
      let data = {};
      data["name"] = null;
      data["service"] = 0;
      data["upCare"] = false;
      for (let product in this.state.productsValues) {
        this.updateProduct(data, product);
      }
    }
    if (this.state.params.formType.toLowerCase() === "upcare") {
      let index = this.state.formInformation.contractLengths.indexOf(
        event.target.value
      );
      for (let product in this.state.productsValues) {
        let data = {};
        const name = this.state.productsValues[product].name;
        if (name !== null) {
          if (this.state.products.productList1[name]?.price.length <= index) {
            data["name"] = null;
          }
        }
        this.updateProduct(data, product);
      }
    }
    if (
      this.state.params.formType.toLowerCase() === "leaseplus" ||
      this.state.params.formType.toLowerCase() === "upcare"
    ) {
      this.updateTotalMonthlyFee();
    } else {
      this.newCalculationsProductFee();
    }
    if (this.state.params.formType.toLowerCase() === "construction") {
      let data = {};
      data["residual"] = 10;
      data["name"] = null;
      data["service"] = 0;
      for (let product in this.state.productsValues) {
        this.updateProduct(data, product);
      }
    }
  };

  showInvalid = (newValue = true) => {
    let newFormInformation = this.state.formInformation;
    newFormInformation.showInvalid = newValue;
    this.setState({
      formInformation: newFormInformation,
    });
  };

  /**
   *
   * @returns Boolean
   */
  validateFormInputs = () => {
    if (configSource["DEBUG"]) return true;
    if (this.state.formInformation.totalMonthlyFee < 0.1) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    if (
      this.state.formInformation.orderChecked &&
      (!(
        (this.state.customerInformation.businessFinished ||
          this.state.customerInformation.privateFinished) &&
        this.state.customerInformation.identificationFinished &&
        this.state.customerInformation.dealerFinished
      ) ||
        !(
          !this.state.formInformation.newDealerChecked ||
          this.state.newDealerInformation.newDealerFinished
        ))
    ) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    if (
      this.state.formInformation.quoteChecked &&
      !(
        (this.state.customerInformation.businessFinished ||
          this.state.customerInformation.privateFinished) &&
        this.state.customerInformation.dealerFinished
      )
    ) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    for (let product in this.state.productsValues) {
      if (
        this.state.productsValues[product]["finished"] === undefined ||
        this.state.productsValues[product]["finished"] === false
      ) {
        this.showInvalid();
        alert("All required fields are not filled in");
        return false;
      }
    }
    this.showInvalid(false);
    return true;
  };

  /**
   *
   * @returns Boolean
   *
   * Check the params to validate that the form requested exists.
   */
  validateParams = () => {
    const allowedArgs = config.ALLOWED_ARGS;
    if (
      Object.keys(allowedArgs["logisnext"]).includes(
        this.props.params.lang.toLowerCase()
      ) &&
      allowedArgs["logisnext"][this.props.params.lang.toLowerCase()].includes(
        this.props.params.formType.toLowerCase()
      )
    ) {
      return true;
    }
    return false;
  };

  /**
   *
   * @param {string} id
   * @returns ReactElement || null
   *
   * Creates a ReactElement depending on formType and returns it.
   * If unknown formType return null.
   */

  generateProduct = (id) => {
    const standardProps = {
      key: id,
      id: id,
      number: id.charAt(id.length - 1),
      productValues: this.state.productsValues[id],
      formInformation: this.state.formInformation,
      labels: this.state?.labels,
      variables: this.state?.variables,
      params: this.state.params,
      updateProduct: this.updateProduct,
    };
    switch (this.state.params.formType.toLowerCase()) {
      case "upcare":
        let index = this.state.formInformation.contractLengths.indexOf(
          this.state.formInformation.contractLength
        );
        const finalList = {};
        for (const list in this.state.products) {
          if (list.includes("productList")) {
            finalList[list] = {};
            for (const key in this.state.products[list]) {
              if (
                Array.isArray(this.state.products[list][key].price) &&
                this.state.products[list][key].price.length > index
              ) {
                finalList[list][key] = this.state.products[list][key];
              }
            }
          }
        }
        const upCareProps = {
          products: finalList,
          updateTotalMonthlyFee: this.updateTotalMonthlyFee,
        };
        return React.createElement(
          UpCareProduct,
          Object.assign(standardProps, upCareProps)
        );
      default:
        return null;
    }
  };

  render() {
    if (!this.validateParams()) return <NotFound />;
    if (this.state.firstRender) return;

    const onBoarding =
      this.state.params.formType.toLowerCase() === "leaseplus" ||
      this.state.params.formType.toLowerCase() === "upcare";

    const disabled = !(
      (this.state.formInformation.quoteChecked &&
        !this.state.formInformation.orderChecked) ||
      (this.state.formInformation.orderChecked &&
        this.state.formInformation.idChecked &&
        (!this.state.formInformation.newDealerChecked ||
          this.state.newDealerInformation.verifyNewDealer) &&
        (!onBoarding ||
          this.state.formInformation.newDealerNotChecked ||
          this.state.formInformation.newDealerChecked)) ||
      configSource["DEBUG"]
    );

    let showQuote = true;
    const style_campaign = {
      color: "#003366",
      fontSize: "25px",
    };
    const style_campaign_extra = {
      color: "#003366",
      fontSize: "18px",
    };
    return (
      <Stack className="form-wrapper" alignItems="stretch">
        <div className="header">
          {this.state.params.formType !== "flexprivate" && (
            <div className="header-img">
              <img
                src={
                  configSource.ROOT +
                  "getimage?imageName=" +
                  this.state.params.formType.toLowerCase() +
                  ".jpg&company=logisnext"
                }
                alt=""
                width="30%"
              ></img>
            </div>
          )}
          <div>
            <p>{this.state.headerText?.text}</p>
          </div>
        </div>
        {this.state.formInformation.formState === "edit" && (
          <div>
            <div>
              <strong>
                <span style={style_campaign}>
                  {this.state.headerText?.campaign}
                </span>
              </strong>
            </div>
            <div>
              <strong>
                <span style={style_campaign_extra}>
                  {this.state.headerText?.campaign_extra}
                </span>
              </strong>
            </div>
            {this.state.labels.logisnext_information !== "" &&
              this.state.labels?.logisnext_information !== undefined && (
                <div>
                  <Information
                    labels={this.state.labels}
                    changeHandler={this.handleFormInformationChange}
                  ></Information>
                </div>
              )}
            <div className="padding-top-small">
              <Tooltip title={this.state.labels.tooltip_upload_formdata}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    minWidth: "400px",
                    minHeight: "36.5px",
                    float: "right",
                  }}
                >
                  <UploadIcon sx={{ marginLeft: "350px" }} />
                  <span className="app-span-text">
                    {this.state.labels.upload_formdata}
                  </span>
                  <input
                    type="file"
                    accept=".json"
                    hidden
                    onChange={(event) => {
                      var reader = new FileReader();
                      reader.addEventListener(
                        "load",
                        () => {
                          //jumps here when its done with 'readAsText'
                          let parsed = JSON.parse(reader.result);
                          Object.keys(parsed.productsValues).forEach((key) => {
                            if (
                              parsed.productsValues[key].deliveryDate !== null
                            ) {
                              parsed.productsValues[key].deliveryDate =
                                new Date(
                                  parsed.productsValues[key].deliveryDate
                                );
                            }
                          });
                          if (parsed.customerInformation.dateOfBirth !== null) {
                            parsed.customerInformation.dateOfBirth = new Date(
                              parsed.customerInformation.dateOfBirth
                            );
                          }
                          if (
                            parsed.formInformation.useCampaign &&
                            !this.state.campaign
                          ) {
                            parsed.formInformation.useCampaign = false;
                          }
                          this.setState(parsed);
                        },
                        false
                      );
                      reader.readAsText(event.target.files[0]);
                    }}
                  />
                </Button>
              </Tooltip>
            </div>
            <div className="titles-wrapp">
              <h1 className="titles">{this.state.labels.main_products}</h1>
              <hr />
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel id="contractLength-label">
                  {this.state.labels.contract_length}
                </InputLabel>
                <Select
                  labelId="contractLength-label"
                  id="contractLength-select"
                  value={this.state.formInformation.contractLength}
                  label={this.state.labels.contract_length}
                  onChange={this.changeContractLength}
                >
                  {this.state.formInformation.contractLengths.map((length) => {
                    return (
                      <MenuItem key={length} value={length}>
                        {length.toString() + " " + this.state.labels.months}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {Object.keys(this.state.productsValues).map((id) => {
              return this.generateProduct(id);
            })}
            <div className="padding-top row ">
              <Tooltip title={this.state.labels.tooltip_add_product || ""}>
                <Button
                  onClick={() => {
                    this.addAnotherProduct();
                  }}
                  variant="contained"
                >
                  {"+"}
                </Button>
              </Tooltip>
              {Object.keys(this.state.productsValues).length > 1 && (
                <div className="padding-left">
                  <Tooltip
                    title={this.state.labels.tooltip_remove_product || ""}
                  >
                    <Button
                      onClick={() => {
                        this.removeProduct();
                      }}
                      color="error"
                      variant="contained"
                    >
                      {"-"}
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
            {this.state.params.formType.toLowerCase() === "flexpro" && (
              <div className="padding-small">
                <Typography variant="h7">
                  {this.state.labels.installation_cost}
                </Typography>
              </div>
            )}
            {(this.state.params.formType.toLowerCase() === "construction" ||
              this.state.params.formType.toLowerCase() === "flexpro" ||
              this.state.params.formType.toLowerCase() === "flexprivate" ||
              this.state.params.formType.toLowerCase() === "standard" ||
              this.state.params.formType.toLowerCase() === "upcare") && (
              <div className="padding-top">
                <div className="titles-wrapp">
                  <h1 className="titles">
                    {this.state.params.formType.toLowerCase() !== "standard" &&
                    this.state.params.formType.toLowerCase() !==
                      "construction" &&
                    this.state.params.formType.toLowerCase() !== "upcare"
                      ? this.state.labels.accessories_title
                      : this.state.params.formType.toLowerCase() ===
                        "construction"
                      ? this.state.labels.accessories_title_construction
                      : this.state.params.formType.toLowerCase() === "standard"
                      ? this.state.labels.additional_cost_title
                      : this.state.labels.accessories_title_2}
                  </h1>
                  <hr />
                </div>
                <div className="accessories padding-bot">
                  {Object.keys(this.state.accessories).map((id) => {
                    return this.generateAccessories(id);
                  })}
                </div>
                <div className="row">
                  <Tooltip
                    title={this.state.labels.tooltip_add_accessory || ""}
                  >
                    <Button
                      onClick={() => {
                        this.addAccessories();
                      }}
                      variant="contained"
                    >
                      {"+"}
                    </Button>
                  </Tooltip>
                  {Object.keys(this.state.accessories).length > 0 && (
                    <div className="padding-left">
                      <Tooltip
                        title={this.state.labels.tooltip_remove_accessory || ""}
                      >
                        <Button
                          onClick={() => {
                            this.removeAccessories();
                          }}
                          variant="contained"
                          color="error"
                        >
                          {"-"}
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="padding-top">
              <div className="titles-wrapp">
                <h1 className="titles">
                  {this.state.labels.customer_information_title}
                </h1>
                <hr />
              </div>
              {this.state.formInformation.customer === "Business" ? (
                <CustomerInformationBusiness
                  changeHandler={this.handleCustomerInfoChange}
                  customerInformation={this.state.customerInformation}
                  formInformation={this.state.formInformation}
                  labels={this.state?.labels}
                  addSignatory={this.addSignatory}
                  removeSignatory={this.removeSignatory}
                  formType={this.state.params.formType.toLowerCase()}
                  params={this.state.params}
                />
              ) : (
                <CustomerInformationPrivate
                  changeHandler={this.handleCustomerInfoChange}
                  customerInformation={this.state.customerInformation}
                  formInformation={this.state.formInformation}
                  labels={this.state?.labels}
                  params={this.state.params}
                />
              )}
            </div>
            <div className="padding-top">
              <Tooltip
                title={
                  <span style={{ fontSize: "12px" }}>
                    {this.state.labels.tooltip_order_quote || ""}
                  </span>
                }
                placement="top"
              >
                <FormGroup sx={{ width: "15%" }}>
                  {showQuote && (
                    <FormControlLabel
                      className="quote_order"
                      control={
                        <Checkbox
                          id="quote_check"
                          checked={this.state.formInformation.quoteChecked}
                          onChange={this.handleChecked}
                        />
                      }
                      label={this.state.labels.quote}
                    />
                  )}
                  <FormControlLabel
                    className="quote_order"
                    control={
                      <Checkbox
                        id="order_check"
                        checked={this.state.formInformation.orderChecked}
                        onChange={this.handleChecked}
                      />
                    }
                    label={this.state.labels.order}
                  />
                </FormGroup>
              </Tooltip>
            </div>
            {this.state.formInformation.orderChecked ? (
              <div className="padding-top">
                <CustomerInformationIdentification
                  customerInformation={this.state.customerInformation}
                  formInformation={this.state.formInformation}
                  labels={this.state?.labels}
                  params={this.state.params}
                  extraFiles={this.state.extraFiles}
                  idSelectHandler={this.handleIdSelect}
                  pepSelectHandler={this.handlePepSelect}
                  addExtraFiles={this.addExtraFiles}
                  changeHandler={this.handleCustomerInfoChange}
                  removeExtraFiles={this.removeExtraFiles}
                  checkHandler={this.handleChecked}
                  formType={this.state.params.formType.toLowerCase()}
                  productsValues={this.state.productsValues}
                />
              </div>
            ) : null}
            {this.state.formInformation.quoteChecked ||
            this.state.formInformation.orderChecked ? (
              <div className="padding-top">
                <div className="titles-wrapp">
                  <h1 className="titles">
                    {this.state.labels.dealer_information}
                  </h1>
                  <hr />
                </div>
                <DealerInformation
                  changeHandler={this.handleCustomerInfoChange}
                  customerInformation={this.state.customerInformation}
                  formInformation={this.state.formInformation}
                  labels={this.state?.labels}
                  params={this.state.params}
                  checkHandler={this.handleChecked}
                />
              </div>
            ) : null}
            {this.state.formInformation.orderChecked && onBoarding && (
              <>
                <div className="padding-onboarding-title">
                  <h1 className="titles-onboarding">
                    {this.state.labels.first_order}
                  </h1>
                  <div className="padding-checkbox">
                    <FormGroup row={true}>
                      <FormControlLabel
                        className="new_dealer"
                        control={
                          <Checkbox
                            id="new_dealer_yes"
                            checked={
                              this.state.formInformation.newDealerChecked
                            }
                            onChange={this.handleChecked}
                          />
                        }
                        label={this.state.labels.positive_answer}
                      />
                      <FormControlLabel
                        className="new_dealer"
                        control={
                          <Checkbox
                            id="new_dealer_no"
                            checked={
                              this.state.formInformation.newDealerNotChecked
                            }
                            onChange={this.handleChecked}
                          />
                        }
                        label={this.state.labels.negative_answer}
                      />
                    </FormGroup>
                  </div>
                </div>
              </>
            )}
            {this.state.formInformation.newDealerChecked && (
              <div>
                <div className="titles-wrapp">
                  <h1 className="titles">
                    {this.state.labels.new_dealer_title}
                  </h1>
                  <hr />
                </div>
                <NewDealer
                  newDealer={this.state.newDealerInformation}
                  labels={this.state?.labels}
                  changeHandler={this.handleNewDealerInfoChange}
                  formInformation={this.state.formInformation}
                  checkHandler={this.handleChecked}
                  params={this.state.params}
                />
              </div>
            )}
            {(this.state.params.formType.toLowerCase() === "upcare" ||
              this.state.params.formType.toLowerCase() === "flexpro" ||
              this.state.params.formType.toLowerCase() === "construction") &&
              this.state.formInformation.orderChecked && (
                <div className="padding-top">
                  <div className="titles-wrapp">
                    <h1 className="titles">
                      {this.state.labels.extra_information}
                    </h1>
                    <hr />
                  </div>
                  <Information
                    labels={this.state.labels}
                    extraInfo={this.state.formInformation.extraInfo}
                    changeHandler={this.handleFormInformationChange}
                  ></Information>
                </div>
              )}
            {this.state.params.formType.toLowerCase() === "flexpro" ||
            this.state.params.formType.toLowerCase() === "construction" ? (
              <div className="padding-top totalValues">
                <DisplayField
                  id="totalPrice"
                  value={(
                    this.state.formInformation.totalPrice -
                    this.state.formInformation.totalAccessoriesPrice
                  ).toString()}
                  label={this.state.labels.total_product_price}
                  currency={this.state.labels.currency}
                  sx={{ width: "33%" }}
                />
                <DisplayField
                  id="totalAccessoriesPrice"
                  value={this.state.formInformation.totalAccessoriesPrice.toString()}
                  label={
                    this.state.labels.label_total_price +
                    " " +
                    (this.state.params.formType.toLowerCase() === "flexpro"
                      ? this.state.labels.accessories_title
                      : this.state.labels.accessories_title_construction)
                  }
                  currency={this.state.labels.currency}
                  sx={{ width: "33%" }}
                />
                <DisplayField
                  id="totalMonthlyFee"
                  value={this.state.formInformation.totalResidue.toString()}
                  label={this.state.labels.total_residual_value}
                  currency={this.state.labels.currency}
                  sx={{ width: "33%" }}
                />
              </div>
            ) : null}
            <div className="padding-top padding-bot">
              <DisplayField
                id="totalMonthlyFee"
                value={this.state.formInformation.totalMonthlyFee.toString()}
                label={
                  this.state.params.formType.toLowerCase() === "leaseplus"
                    ? this.state.labels.total_monthly_fee +
                      "" +
                      this.state.labels.inc_vat
                    : this.state.labels.total_monthly_fee
                }
                currency={this.state.labels.currency}
              />
            </div>
            <div className="campaign">
              {this.state.campaign && (
                <>
                  <TextField
                    id={this.state.labels.campaign_description}
                    defaultValue={this.state.labels.campaign_description}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="standard"
                    fullWidth={true}
                  />
                  <FormControlLabel
                    className="campaign"
                    control={
                      <Checkbox
                        id="campaign"
                        checked={this.state.formInformation.useCampaign}
                        onChange={() => {
                          let state = this.state;
                          state.formInformation.useCampaign =
                            !state.formInformation.useCampaign;
                          this.setState(state);
                        }}
                      />
                    }
                    label={this.state.labels.campaign_checkbox}
                  />
                </>
              )}
            </div>
            {/* <div className="button-container-row">
              <Tooltip title={this.state.labels.tooltip_upload_extra_files}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    minWidth: "415px",
                    minHeight: "45px",
                    float: "right",
                  }}
                >
                  <UploadIcon sx={{ marginLeft: "380px" }} />
                  <span className="app-span-text">
                    {this.state.labels.upload_extra_files}
                  </span>
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    multiple
                    hidden
                    onChange={(event) => {
                      let files = [];
                      for (let i = 0; i < event.target.files.length; i++) {
                        if (
                          !this.state.extraFiles.some(
                            (file) => file.name === event.target.files[i].name
                          )
                        ) {
                          let element = event.target.files[i];
                          files.push(element);
                        } else {
                          alert(this.state.labels.duplicate_filename);
                        }
                      }
                      this.addExtraFiles(files);
                      event.target.value = null;
                    }}
                  />
                </Button>
              </Tooltip>
            </div> */}
            <div className="button-container">
              <div className="button-container-row">
                <Tooltip title={this.state.labels.tooltip_download_formdata}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ minWidth: "415px", minHeight: "45px" }}
                    onClick={() => {
                      const content = {
                        productsValues: this.state.productsValues,
                        accessories: this.state.accessories,
                        customerInformation: this.state.customerInformation,
                        formInformation: this.state.formInformation,
                      };

                      const filename = `logisnext-${this.props.params.lang.toLowerCase()}-${this.props.params.formType.toLowerCase()}.json`;

                      const a = document.createElement("a");
                      const file = new Blob([JSON.stringify(content)], {
                        type: "json",
                      });
                      a.href = URL.createObjectURL(file);
                      a.download = filename;
                      a.click();

                      URL.revokeObjectURL(a.href);
                    }}
                  >
                    <DownloadIcon sx={{ marginLeft: "380px" }} />
                    <span className="app-span-text">
                      {this.state.labels.download_formdata}
                    </span>
                  </Button>
                </Tooltip>
                <Button
                  sx={{
                    minWidth: "350px",
                    minHeight: "45px",
                  }}
                  onClick={() => {
                    if (this.validateFormInputs()) {
                      this.switchFormState("review");
                    }
                  }}
                  variant="contained"
                  disabled={disabled}
                >
                  <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
                  <span className="app-span-text">
                    {this.state.labels.preview_form}{" "}
                  </span>
                </Button>
              </div>
              {/* <div id="fileContents" style={{ textAlign: "right" }}>
                {this.state.extraFiles.length > 0 && (
                  <p>{this.state.labels.uploaded_files} </p>
                )}
                {this.state.extraFiles.map((file) => {
                  return (
                    <Button
                      variant="text"
                      className="file"
                      id={file.name}
                      key={file.name}
                      onClick={() => {
                        this.removeExtraFiles(file);
                      }}
                    >
                      {file.name}
                    </Button>
                  );
                })}
              </div> */}
            </div>
          </div>
        )}
        {this.state.formInformation.formState === "review" && (
          <div className="padding-top">
            <FormRes
              formInformation={this.state.formInformation}
              productsValues={this.state.productsValues}
              customerInformation={this.state.customerInformation}
              labels={this.state.labels}
              accessories={this.state.accessories}
              extraFiles={this.state.extraFiles}
              params={this.state.params}
              pdf={false}
              switchFormState={this.switchFormState}
              products={this.state.products.productList1}
              productAccessories={this.state.products?.accessories}
              variables={this.state.variables}
              newDealerInformation={this.state.newDealerInformation}
              changeContractLength={this.changeContractLength}
            ></FormRes>
          </div>
        )}
        {this.state.formInformation.formState === "completed" && (
          <div className="completedText">
            <h1>{this.state.labels.thank_you}</h1>
            {this.state.formInformation.orderChecked && (
              <>
                <div>
                  <p>{this.state.labels.order_sent}</p>
                </div>
                <div className="order_info">
                  <p>{this.state.labels.order_info}</p>
                </div>
              </>
            )}
            {this.state.formInformation.quoteChecked && (
              <div>
                <p>{this.state.labels.quote_downloaded}</p>
              </div>
            )}
          </div>
        )}
        <br></br>
      </Stack>
    );
  }
}

export default withParams(Logisnext);
