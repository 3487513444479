import React from "react";
import PropTypes from "prop-types";
import "./css/HcsQuote.css";
import "./css/ArjoQuote.css";
import config from "../../res/config.json";
import { formatNumber } from "../../logic/GeneralFunctions";

export default class HcsQuote extends React.Component {
  static propTypes = {
    productsValues: PropTypes.object,
    labels: PropTypes.object,
    customerInformation: PropTypes.object,
    params: PropTypes.object,
    formInformation: PropTypes.object,
    accessories: PropTypes.object,
  };

  createProductRow = (id, lastId) => {
    let style = "";
    if (id === lastId) {
      style = " padding-bottom";
    }
    return (
      <tr key={id} style={{ lineHeight: "8px" }}>
        <td
          style={{ width: "15%" }}
          className={"cell-left arjo-mainproduct-cell" + style}
        >
          {this.props.productsValues[id].quantity}
        </td>
        <td
          style={{ width: "60%" }}
          className={"arjo-mainproduct-cell" + style}
        >
          {this.props.productsValues[id].name}
        </td>
        <td
          style={{ width: "25%" }}
          className={"arjo-mainproduct-cell" + style}
        >
          { this.props.productsValues[id].servicePackage.charAt(0).toUpperCase() + 
          this.props.productsValues[id].servicePackage.slice(1)}
        </td>
      </tr>
    );
  };

  createAccessoryRow = (id, lastId) => {
    let style = "";
    if (id === lastId) {
      style = " padding-bottom";
    }
    console.log(this.props.accessories[id])
    return (
      <tr key={id} style={{ lineHeight: "8px" }}>
        <td
          style={{ width: "25%" }}
          className={"cell-left arjo-mainproduct-cell" + style}
        >
          {this.props.accessories[id].quantity}
        </td>
        <td
          style={{ width: "75%" }}
          className={"arjo-mainproduct-cell" + style}
        >
          {this.props.accessories[id].AccessoriesName}
        </td>
      </tr>
    );
  };

  render() {
    return (
      <div className="quote-body">
        <div className="arjo-img-center">
          <img
            src={config.ROOT + "getimage?imageName=Logo.jpg&company=arjo"}
            alt=""
            width="25%"
            height="25%"
          ></img>
        </div> 
        <div className="parties arjo-borders">
          <span className="arjo-title">
            <b>{this.props.labels.parties_title_1}</b>
          </span>
          <div
            style={{ width: "100%", paddingBottom: "4px", paddingLeft: "8px" }}
            className="arjo-title"
          >
            <span style={{ width: "50%" }}>
              <b>{this.props.labels.parties_title_2}</b>
            </span>
            <span style={{ width: "50%", position: "relative", left: "40%" }}>
              <b>{this.props.labels.parties_title_3}</b>
            </span>
          </div>
          <div className="arjo-table">
            <div className="arjo-row">
              <div className="arjo-cell cell-left" style={{ width: "30%" }}>
                <span className="up">{this.props.labels.parties_1_1}</span>
                <span className="down">{this.props.labels.arjo_name}</span>
              </div>
              <div className="arjo-cell" style={{ width: "20%" }}>
                <span className="up">{this.props.labels.parties_1_2}</span>
                <span className="down">{this.props.labels.number}</span>
              </div>
              <div className="arjo-cell" style={{ width: "30%" }}>
                <span className="up">{this.props.labels.parties_1_1}</span>
                <span className="down">
                  {this.props.customerInformation.companyName}
                </span>
              </div>
              <div className="arjo-cell" style={{ width: "20%" }}>
                <span className="up">{this.props.labels.parties_1_2}</span>
                <span className="down">
                  {this.props.customerInformation.companyNumber}
                </span>
              </div>
            </div>
            <div className="arjo-row">
              <div className="arjo-cell cell-left" style={{ width: "50%" }}>
                <span className="up">{this.props.labels.parties_2}</span>
                <span className="down">
                  {this.props.labels.arjo_address}
                </span>
              </div>
              <div className="arjo-cell" style={{ width: "50%" }}>
                <span className="up">{this.props.labels.parties_2}</span>
                <span className="down"></span>
              </div>
            </div>
            <div className="arjo-row">
              <div className="arjo-cell cell-left" style={{ width: "15%" }}>
                <span className="up">{this.props.labels.parties_3_1}</span>
                <span className="down">{this.props.labels.arjo_post_code}</span>
              </div>
              <div className="arjo-cell" style={{ width: "35%" }}>
                <span className="up">{this.props.labels.parties_3_2}</span>
                <span className="down">{this.props.labels.arjo_city}</span>
              </div>
              <div className="arjo-cell" style={{ width: "15%" }}>
                <span className="up">{this.props.labels.parties_3_1}</span>
                <span className="down"></span>
              </div>
              <div className="arjo-cell" style={{ width: "35%" }}>
                <span className="up">{this.props.labels.parties_3_2}</span>
                <span className="down"></span>
              </div>
            </div>
            <div className="arjo-row">
              <div className="arjo-cell cell-left">
                <span className="up">{this.props.labels.parties_4_1}</span>
                <span className="down">{this.props.labels.arjo_contact}</span>
              </div>
              <div className="arjo-cell">
                <span className="up">{this.props.labels.parties_4_2}</span>
                <span className="down">{this.props.labels.arjo_phone}</span>
              </div>
              <div className="arjo-cell">
                <span className="up">{this.props.labels.parties_4_1}</span>
                <span className="down">
                  {
                    this.props.customerInformation.signatories["signatory1"]
                      .name
                  }
                </span>
              </div>
              <div className="arjo-cell">
                <span className="up">{this.props.labels.parties_4_2}</span>
                <span className="down">
                  {
                    this.props.customerInformation.signatories["signatory1"]
                      .phone
                  }
                </span>
              </div>
            </div>
            <div className="arjo-row last-row">
              <div className="arjo-cell cell-left" style={{ width: "50%" }}>
                <span className="up">{this.props.labels.parties_5}</span>
                <span className="down">{this.props.labels.arjo_email}</span>
              </div>
              <div className="arjo-cell" style={{ width: "50%" }}>
                <span className="up">{this.props.labels.parties_5}</span>
                <span className="down">
                  {
                    this.props.customerInformation.signatories["signatory1"]
                      .email
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="contact arjo-borders">
          <span className="arjo-title">
            <b>{this.props.labels.contact_title}</b>
          </span>
          <div className="arjo-table">
            <div className="arjo-row">
              <div className="arjo-cell cell-left" style={{ width: "23.7%" }}>
                <span className="up">{this.props.labels.contact_1_1}</span>
                <span className="down">{this.props.customerInformation.dealerName}</span>
              </div>
              <div className="arjo-cell" style={{ width: "23.7%" }}>
                <span className="up">{this.props.labels.contact_1_2}</span>
                <span className="down"></span>
              </div>
              <div className="arjo-cell" style={{ width: "50%" }}>
                <span className="up">{this.props.labels.contact_1_3}</span>
                <span className="down">
                  {this.props.labels.arjo_address}
                </span>
              </div>
            </div>
            <div className="arjo-row last-row">
              <div className="arjo-cell cell-left" style={{ width: "50%" }}>
                <span className="up">{this.props.labels.contact_2_1}</span>
                <span className="down">{this.props.customerInformation.dealerEmail}</span>
              </div>
              <div className="arjo-cell" style={{ width: "15%" }}>
                <span className="up">{this.props.labels.contact_2_2}</span>
                <span className="down">{this.props.labels.arjo_post_code}</span>
              </div>
              <div className="arjo-cell" style={{ width: "35%" }}>
                <span className="up">{this.props.labels.contact_2_3}</span>
                <span className="down">{this.props.labels.arjo_city}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="payment arjo-borders">
          <span className="arjo-title">
            <b>{this.props.labels.monthly_payment_title}</b>
          </span>
          <div className="arjo-table">
            <div className="arjo-row last-row">
              <div className="arjo-cell cell-left" style={{ width: "30%" }}>
                <span className="up">
                  {this.props.labels.monthly_payment_1_1}
                </span>
                <span className="down">
                  {this.props.labels.currency}
                  {formatNumber(this.props.formInformation.totalMonthlyFee)}
                </span>
              </div>
              <div className="arjo-cell" style={{ width: "20%" }}>
                <span className="up">
                  {this.props.labels.monthly_payment_1_2}
                </span>
                <span className="down">
                  {this.props.formInformation.contractLength}
                </span>
              </div>
              <div className="arjo-cell" style={{ width: "20%" }}>
                <span className="up">
                <b>{this.props.labels.leasing_type}</b>
                </span>
                <span className="down">
                  {this.props.formInformation.leasingType}
                </span>
              </div>
              <div className="arjo-cell" style={{ width: "20%" }}>
                <span className="up">
                  {this.props.labels.monthly_payment_1_3}
                </span>
                <span className="down">
                  <b>TBD</b>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="products arjo-borders">
          <span className="arjo-title">
            <b>
              {this.props.labels.main_product_title}{" "}
              <u>{this.props.labels.main_product_title_bold}</u>
            </b>
          </span>
          <div className="arjo-table">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <tr className="arjo-mainproduct-row">
                <th
                  style={{ width: "15%" }}
                  className="arjo-mainproduct-header"
                >
                  {this.props.labels.main_product_1_1}
                </th>
                <th
                  style={{ width: "60%" }}
                  className="arjo-mainproduct-header"
                >
                  {this.props.labels.main_product_1_2}
                </th>
                <th
                  style={{ width: "25%" }}
                  className="arjo-mainproduct-header"
                >
                  {this.props.labels.main_product_1_3}
                </th>
              </tr>
              {Object.keys(this.props.productsValues).map((id) => {
                return this.createProductRow(
                  id,
                  Object.keys(this.props.productsValues).at(-1)
                );
              })}
            </table>
          </div>
        </div>
        <div name="accessories">
          <span className="arjo-title">
            <b className="arjo-wordspacing">
              {this.props.labels.accessories_quote}
            </b>
            {"  :  "}
            <b>
              {this.props.labels.currency}
              {formatNumber(this.props.formInformation.totalAccessoriesPrice)}
            </b>
          </span>
          {Object.keys(this.props.accessories).length > 0 ? (
          <span className="arjo-title">
            <b>
              {this.props.labels.main_product_title}{" "}
              <u>{this.props.labels.accessory_product_title_bold}</u>
            </b>
          </span>):null}
          {Object.keys(this.props.accessories).length > 0 ? (
          <div className="arjo-table">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <tr className="arjo-mainproduct-row">
                <th
                  style={{ width: "25%" }}
                  className="arjo-mainproduct-header"
                >
                  {this.props.labels.main_product_1_1}
                </th>
                <th
                  style={{ width: "75%" }}
                  className="arjo-mainproduct-header"
                >
                  {this.props.labels.accessory_product_1_2 + " " + this.props.labels.main_product_title}
                </th>
              </tr>
              {Object.keys(this.props.accessories).map((id) => {
                return this.createAccessoryRow(
                  id,
                  Object.keys(this.props.accessories).at(-1)
                );
              })}
            </table>
          </div>):null}
          {/* {this.props.params.lang.toLowerCase() === 'uk' ? (
          <span style={{ whiteSpace: "nowrap" }}>
            {this.props.labels.terms_and_conditions}
          </span>
          ):null} */}
        </div>
      </div>
    );
  }
}
