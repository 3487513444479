import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import InputText from "../../Fields/InputText";

const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export default class QuoteCustomerInformation extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func,
    customerInformation: PropTypes.object,
    labels: PropTypes.object,
    params: PropTypes.object,
    customerType: PropTypes.string,
  };

  state = {
    customerName: false,
    customerEmail: false,
    companyNumber: false,
  };

  componentDidMount() {
    this.setState(this.checkFieldsFinsished());
  }

  checkFieldsFinsished = () => {
    let newState = this.state;
    for (let key in newState) {
      if (
        (this.props.customerInformation[key] !== undefined &&
          this.props.customerInformation[key] !== "" &&
          this.props.customerInformation[key] !== null) ||
        (key === "companyNumber" && this.props.customerType === "Private")
      ) {
        newState[key] = true;
      }
    }
    return newState;
  };

  handleChange = (value, id) => {
    let newState = this.checkFieldsFinsished();
    newState[id] = id.includes("email") ? mailformat.test(value) : value !== "";
    let data = {};
    data["quoteInformationFinished"] = Object.values(newState).every(
      (finished) => {
        return finished;
      }
    );
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  render() {
    let size = this.props.customerType === "Private" ? 6 : 4;
    return (
      <Grid container spacing={2} className="customer_info">
        <Grid item xs={size}>
          <InputText
            id="customerName"
            label={
              this.props.customerType === "Business"
                ? this.props.labels.company
                : this.props.labels.customer_name
            }
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerName}
            tooltip={this.props.labels.tooltip_customer_name}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid && !this.state.customerName
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={size}>
          <InputText
            id="customerEmail"
            label={this.props.labels.label_email}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.customerEmail}
            tooltip={this.props.labels.tooltip_customer_email}
            required={true}
            sx={{ width: "100%" }}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.customerEmail
                ? "invalid"
                : ""
            }
          />
        </Grid>
        {this.props.customerType === "Business" && (
          <Grid item xs={size}>
            <InputText
              id="companyNumber"
              label={this.props.labels.company_number}
              changeHandler={this.handleChange}
              defaultValue={this.props.customerInformation.companyNumber}
              tooltip={this.props.labels.tooltip_company_number}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.companyNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
        )}
      </Grid>
    );
  }
}
