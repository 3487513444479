import React from "react";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

export default class ProductList extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func,
    id: PropTypes.string,
    tooltip: PropTypes.string,
    products: PropTypes.array,
    label: PropTypes.string,
    required: PropTypes.bool,
    sx: PropTypes.object,
    className: PropTypes.string,
  };

  /**
   *
   * @param {Object} event - event
   *
   * New product in list is picked.
   */
  handleChange = (event) => {
    let value =
      event.target.textContent === "" ? null : event.target.textContent;
    this.props.changeHandler(value, this.props.id);
  };

  /**
   *
   * @returns {Array}
   *
   * Parses JSON file to Array and returns Array
   */
  getProducts = () => {
    let products = [];
    for (const product in this.props.products) {
      products.push(product);
    }
    return products;
  };

  render() {
    return (
      <Tooltip
        title={<span style={{ fontSize: "12px" }}>{this.props.tooltip}</span>}
        placement="top"
      >
        <Autocomplete
          className={"productList " + this.props.className}
          disablePortal
          clearOnBlur
          selectOnFocus
          sx={this.props.sx}
          options={this.props.products}
          value={this.props.defaultValue}
          renderInput={(params) => (
            <TextField
              {...params}
              label={this.props.label + (this.props.required ? " *" : "")}
            />
          )}
          onChange={this.handleChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.defaultMuiPrevented = true;
            }
          }}
        />
      </Tooltip>
    );
  }
}

ProductList.defaultProps = {
  required: false,
  sx: {},
};
