import React from "react";
import PropTypes from "prop-types";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Button, FormControlLabel, Checkbox } from "@mui/material";
import configSource from "../res/config.json";

export default class ConfidentialityNotice extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    labels: PropTypes.object,
    switchFormState: PropTypes.func,
  };

  state = {
    verify: false,
    pdfURL: null,
  };

  switchFormState = (value) => {
    this.props.switchFormState(value);
  };

  handleChecked = (event) => {
    let newState;
    switch (event.target.id) {
      case "verify":
        newState = this.state.verify ? false : true;
        this.setState({ verify: newState });
        break;
      default:
        throw Error("Unknown button ID");
    }
  };

  fetchConfidentialityPDF = async () => {
    const country = this.props.params.lang.toLowerCase();
    const pdfName = "confidentiality_message.pdf";
    const response = await fetch(
      configSource.ROOT +
        `getimage?company=hcs&country=${country}&type=${pdfName}`
    );
    const pdfURL = response.url;
    this.setState({ pdfURL });
  };

  componentDidMount() {
    this.fetchConfidentialityPDF();
  }

  render() {
    return (
      <div>
        {this.state.pdfURL ? (
          <div style={{ textAlign: "center" }}>
            <object
              data={
                this.state.pdfURL +
                "#toolbar=0&navpanes=0&scrollbar=0&amp;page=3&amp;view=FitV"
              }
              width="75%"
              height="1252px"
            >
              <param name="view" value="Fit" />
              <p>
                Your browser does not support PDFs. Please download the PDF to
                view it:
                <a href={this.state.pdfURL}>Download PDF</a>.
              </p>
            </object>
          </div>
        ) : (
          "Loading PDF..."
        )}
        <FormControlLabel
          className="quote_order"
          control={
            <Checkbox
              id="verify"
              checked={this.state.verify}
              onChange={this.handleChecked}
              sx={{ width: "10%" }}
            />
          }
          sx={{ width: "100%" }}
          label={this.props.labels.confidentiality_verify}
        />
        <div className="button-container">
          <div className="button-container-row">
            <Button
              sx={{ backgroundColor: "#273A60" }}
              onClick={() => {
                this.switchFormState("edit");
              }}
              variant="contained"
            >
              <ArrowBackRoundedIcon sx={{ marginRight: "5px" }} />
              {this.props.labels.edit_form}
            </Button>
            <Button
              sx={{
                minWidth: "350px",
                minHeight: "45px",
                backgroundColor: "#273A60",
                marginLeft:
                  this.props.params.formType.toLowerCase() === "leaseplus"
                    ? "auto"
                    : "0",
              }}
              onClick={() => {
                //no credit check available in france
                if (this.props.params.lang.toLowerCase() === "fr") {
                  this.switchFormState("create_contract");
                } else {
                  this.switchFormState("credit_check");
                }
              }}
              variant="contained"
              disabled={!this.state.verify}
            >
              <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
              <span className="app-span-text">
                {this.props.labels.preview_form}{" "}
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
