import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";

function InformationCard() {
  const headerText = "Värdefull information";
  return (
    <Card
      sx={{
        width: "100%", // Full width of parent
        border: "1px solid black", // Black border
        borderRadius: 2, // Softer corners (8px radius)
        boxShadow: 2, // Light shadow for effect
      }}
    >
      <CardContent>
        {/* Header */}
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          {headerText}
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />
        <Grid>
          {/* Body Text with Links */}
          <Typography variant="body1" paragraph>
            Information om Husqvarna lagerfinansiering finns{" "}
            <Link href="#" underline="hover" color="primary">
              här
            </Link>
            .<br />
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default InformationCard;
