import React from "react";
import DisplayField from "../../component/Fields/DisplayField";
import ProductList from "../../component/Fields/ProductList";
import InputNumber from "../../component/Fields/InputNumber";
import { Grid, TextField, Tooltip, Button, Box } from "@mui/material";
import InputDate from "../../component/Fields/InputDate";
import InputText from "../../component/Fields/InputText";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { getAccessories } from "../../logic/ServerCalls";
import { darken } from "@mui/system";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LeasePlusAccessory from "../../component/LeasePlusAccessory";

export default class LeasePlusProduct extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    number: PropTypes.string,
    productValues: PropTypes.object,
    labels: PropTypes.object,
    variables: PropTypes.object,
    products: PropTypes.array,
    accessories: PropTypes.array,
    formInformation: PropTypes.object,
    required: PropTypes.bool,
    updateProduct: PropTypes.func,
    updateTotalMonthlyFee: PropTypes.func,
    paramas: PropTypes.object,
    addLeasePlusAccessory: PropTypes.func,
    removeLeasePlusAccessory: PropTypes.func,
  };

  state = {
    accessories: this.props.accessories,
  };

  productFinished = debounce(() => {
    let data = {};
    if (
      this.props.params.lang.toLowerCase() === "at" &&
      this.props.formInformation.orderChecked
    ) {
      data = {
        finished:
          (this.props.id !== "product1" ||
            this.props.productValues.name !== null) &&
          this.props.productValues.deliveryDate !== null &&
          this.props.productValues.deliveryDate -
            new Date(Date.now() + 12096e5) <
            0,
      };
    } else {
      data = {
        finished:
          //this.props.productValues.name !== null &&
          this.props.productValues.name !== "" &&
          (this.props.productValues.accessory.length <= 1 ||
            this.props.productValues.accessory.every(
              (accessory) => accessory !== null
            )),
      };
    }
    this.props.updateProduct(data, this.props.id);
  }, 100);

  finished = (fieldId) => {
    switch (fieldId) {
      case "name":
        return (
          this.props.productValues.name !== null ||
          this.props.productValues.accessory !== null
        );
      case "deliveryDate":
        return (
          this.props.productValues.deliveryDate !== null ||
          this.props.params.lang.toLowerCase() !== "at" ||
          this.props.formInformation.quoteChecked
        );
      default:
        return -1;
    }
  };

  parseSerial(serials) {
    if (serials === undefined) return "";
    return serials.join(", ");
  }

  handleChange = async (value, id) => {
    const fieldId = id.split("_")[1];
    let data = {};
    data[fieldId] = value;
    if (fieldId === "name") {
      if (value === null || value === undefined) {
        this.setState({ accessories: this.props.accessories });
      } else {
        const accessories = await this.getAccessories(value);
        this.setState({ accessories: accessories });
      }
      data["accessory"] = [null];
    }
    /* if (this.props.productValues.name === null && fieldId === "accessory") {
      data["accessory"] = null;
    } */
    this.props.updateProduct(data, this.props.id);
    this.productFinished();
    if (fieldId === "name" || fieldId === "accessory") {
      this.props.updateTotalMonthlyFee();
    }
  };

  getAccessories = async (productName) => {
    const accessories = await getAccessories(
      this.props.params.lang.toLowerCase(),
      this.props.params.formType.toLowerCase(),
      this.props.formInformation.contractLength,
      productName
    );
    const accessoryArray = accessories.map((object) => object.name);
    return accessoryArray;
  };

  addAccessory() {
    this.props.addLeasePlusAccessory(this.props.id);
  }

  removeAccessory() {
    this.props.removeLeasePlusAccessory(this.props.id);
  }

  render() {
    let showHiddenFields = false;
    return (
      <Grid container spacing={2} className="Product">
        <Grid item xs={12}>
          <TextField
            id={this.props.id + "_title"}
            value={this.props.labels.product + " " + this.props.number}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={4}>
          <ProductList
            id={this.props.id + "_name"}
            changeHandler={this.handleChange}
            defaultValue={this.props.productValues.name}
            label={this.props.labels.choose_a_product}
            products={this.props.products}
            tooltip={this.props.labels.tooltip_product_list}
            required={true}
            className={
              this.props.formInformation.showInvalid && !this.finished("name")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <InputText
            id={this.props.id + "_serial"}
            label={this.props.labels.serial_numbers}
            multiline={true}
            changeHandler={this.handleChange}
            defaultValue={this.parseSerial(this.props.productValues.serial)}
            tooltip={this.props.labels.tooltip_serialnumber}
          />
        </Grid>
        {this.props.productValues.accessory.map((accessory, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Grid item xs={8}></Grid>}
            <LeasePlusAccessory
              accessory={accessory}
              accessories={this.props.productValues.accessory}
              labels={this.props.labels}
              handleChange={this.handleChange}
              accessoryId={index}
              id={this.props.id}
              products={this.state.accessories}
              formInformation={this.props.formInformation}
              productValues={this.props.productValues}
            />
          </React.Fragment>
        ))}

        <Grid item xs={8}></Grid>

        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <Tooltip title={this.props.labels.tooltip_add_accessory || ""}>
              <Button
                sx={{
                  backgroundColor: "#273A60",
                  "&:hover": {
                    backgroundColor: darken("#273A60", 0.2), // Darken by 20%
                  },
                }}
                onClick={() => {
                  this.addAccessory();
                }}
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
              >
                {this.props.labels.add_accessory}
              </Button>
            </Tooltip>
            {this.props.productValues.accessory.length > 1 && (
              <Tooltip title={this.props.labels.tooltip_remove_accessory || ""}>
                <Button
                  sx={{ marginLeft: 2 }}
                  onClick={() => {
                    this.removeAccessory();
                  }}
                  color="error"
                  variant="contained"
                  startIcon={<RemoveCircleOutlineIcon />}
                >
                  {this.props.labels.remove_accessory}
                </Button>
              </Tooltip>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <InputDate
            id={this.props.id + "_deliveryDate"}
            label={this.props.labels.delivery_date}
            changeHandler={this.handleChange}
            date={this.props.productValues.deliveryDate}
            required={this.props.params.lang.toLowerCase() === "at"}
            minDate={new Date()}
            maxDate={
              this.props.params.lang.toLowerCase() === "at"
                ? new Date(Date.now() + 12096e5)
                : undefined
            }
            className={
              this.props.formInformation.showInvalid &&
              !this.finished("deliveryDate")
                ? "invalid"
                : ""
            }
            helperText={
              this.props.params.lang.toLowerCase() === "at"
                ? this.props.labels.delivery_information
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <DisplayField
            id={this.props.id + "_monthlyFee"}
            label={
              this.props.labels.product_fee + "" + this.props.labels.inc_vat
            }
            value={this.props.productValues.totalProductPrice.toString()}
            currency={this.props.labels.currency}
          />
        </Grid>
        {showHiddenFields && (
          <Grid item xs={2}>
            <InputNumber
              label={this.props.labels.quantity}
              id={this.props.id + "_quantity"}
              defaultValue={this.props.productValues.quantity}
              readOnly={true}
              tooltip={""}
            />
          </Grid>
        )}
        {this.props.params.lang === "at" ? (
          <Grid item xs={4}>
            <DisplayField
              label={this.props.labels.extra_fee}
              value={
                this.props.productValues.totalProductPrice *
                (this.props.formInformation.contractLength + 1) *
                0.01
              }
              currency={this.props.labels.currency}
              tooltip={""}
            />
          </Grid>
        ) : (
          <Grid item xs={4}></Grid>
        )}
        <Grid item xs={4}></Grid>
        <Grid item xs={3}>
          <DisplayField
            label={this.props.labels.display_price_title}
            id={this.props.id + "_displayPrice"}
            value={
              this.props.productValues.purchasePrice +
              this.props.productValues.purchasePriceAccessorySum
            }
            currency={this.props.labels.currency}
            tooltip={""}
          />
        </Grid>
        <Grid item xs={3}>
          <DisplayField
            label={this.props.labels.display_installation_title}
            id={this.props.id + "_displayInstallation"}
            value={
              this.props.productValues.installation +
              this.props.productValues.installationAccessorySum
            }
            currency={this.props.labels.currency}
            tooltip={""}
          />
        </Grid>
        <Grid item xs={3}>
          <DisplayField
            label={
              this.props.labels.total_product_price +
              " " +
              this.props.labels.ex_vat
            }
            id={this.props.id + "_displayTotalProductPrice"}
            value={
              this.props.productValues.installation +
              this.props.productValues.installationAccessorySum +
              this.props.productValues.purchasePrice +
              this.props.productValues.purchasePriceAccessorySum
            }
            currency={this.props.labels.currency}
            tooltip={this.props.labels.total_price_installation}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#273A60",
                  borderWidth: "2px",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <DisplayField
            label={this.props.labels.display_residual_title}
            id={this.props.id + "_displayResidual"}
            value={
              this.props.productValues.residualPrice +
              this.props.productValues.residualPriceAccessorySum
            }
            currency={this.props.labels.currency}
            tooltip={""}
          />
        </Grid>
      </Grid>
    );
  }
}
