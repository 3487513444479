import React from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import InputText from "../../Fields/InputText";
import "../css/Identification.css";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  creditSearchSe,
  personInfoCheckNo,
  experianDKSearch,
} from "../../../logic/ServerCalls.js";

export default class FillBySSN extends React.Component {
  static propTypes = {
    customerInformation: PropTypes.object,
    formInformation: PropTypes.object,
    labels: PropTypes.object,
    params: PropTypes.object,
    changeHandler: PropTypes.func,
    formInfoChange: PropTypes.func,
  };

  state = {
    checkFailed: false,
  };

  splitAddress = (address) => {
    const parts = address.split(" ").filter((part) => part.trim() !== "");

    let numberReached = false;
    let streetName = "";
    let houseNumber = "";

    for (let i = 0; i < parts.length; i++) {
      if (!/\d/.test(parts[i]) && !numberReached) {
        streetName += parts[i] + " ";
      } else {
        numberReached = true;
        houseNumber += parts[i] + " ";
      }
    }
    return { streetName: streetName.trim(), houseNumber: houseNumber.trim() };
  };

  handleChange = async (value, id) => {
    let data = {};
    data[id] = value;
    await this.props.changeHandler(data);
  };

  handleClick = async (event) => {
    let id = event.target.id;
    let data = {};
    if (id === "fetch_person_info") {
      let response;
      switch (this.props.params.lang.toLowerCase()) {
        case "se":
          response = await creditSearchSe(
            this.props.customerInformation.socialSecurityNumber
          );
          break;
        case "no":
          response = await personInfoCheckNo(
            this.props.customerInformation.socialSecurityNumber
          );
          if (response.error) {
            response = {};
          }
          break;
        case "dk":
          /* response = await experianDKSearch(
            this.props.customerInformation.socialSecurityNumber
          ); */
          response = {};
          break;
        case "fi":
          response = {};
          break;
        default:
          response = {};
      }
      if (Object.keys(response).length === 0) {
        alert(
          "Hittade ingen information kopplat till personnummret, vänligen försök igen eller fyll i informationen manuelt."
        );
        this.setState({ checkFailed: true });
      } else {
        if (this.props.params.lang.toLowerCase() === "se") {
          const address = this.splitAddress(response?.ADDRESS[0]);
          data["customerFirstName"] = response?.GIVEN_NAME[0];
          data["customerLastName"] = response?.LAST_NAME[0];
          data["customerStreet"] = address.streetName;
          data["customerHouseNumber"] = address.houseNumber;
          data["customerCity"] = response?.TOWN[0];
          data["customerZipCode"] = response?.ZIPCODE[0];
          data["identificationFinished"] = true;
          await this.props.changeHandler(data);
          data["fillBySSN"] = false;
          await this.props.formInfoChange(data);
        } else if (this.props.params.lang.toLowerCase() === "no") {
          const address = this.splitAddress(response?.address.address);
          data["customerFirstName"] = response?.firstName;
          data["customerLastName"] = response?.lastName;
          data["customerStreet"] = address.streetName;
          data["customerHouseNumber"] = address.houseNumber;
          data["customerCity"] = response?.address.city;
          data["customerZipCode"] = response?.address.postCode;
          data["identificationFinished"] = true;
          await this.props.changeHandler(data);
          data["fillBySSN"] = false;
          await this.props.formInfoChange(data);
        } else if (this.props.params.lang.toLowerCase() === "dk") {
          const address = this.splitAddress(response?.address.address);
          data["customerFirstName"] = response?.firstName;
          data["customerLastName"] = response?.lastName;
          data["customerStreet"] = address.streetName;
          data["customerHouseNumber"] = address.houseNumber;
          data["customerCity"] = response?.address.city;
          data["customerZipCode"] = response?.address.postCode;
          data["identificationFinished"] = true;
          await this.props.changeHandler(data);
          data["fillBySSN"] = false;
          await this.props.formInfoChange(data);
        } else if (this.props.params.lang.toLowerCase() === "fi") {
          const address = this.splitAddress(response?.address.address);
          data["customerFirstName"] = response?.firstName;
          data["customerLastName"] = response?.lastName;
          data["customerStreet"] = address.streetName;
          data["customerHouseNumber"] = address.houseNumber;
          data["customerCity"] = response?.address.city;
          data["customerZipCode"] = response?.address.postCode;
          data["identificationFinished"] = true;
          await this.props.changeHandler(data);
          data["fillBySSN"] = false;
          await this.props.formInfoChange(data);
        }
      }
    }
    if (id === "manual") {
      data["fillBySSN"] = false;
      await this.props.formInfoChange(data);
      data = {};
      data["identificationFinished"] = true;
      await this.props.changeHandler(data);
    }
  };

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputText
            debounce={100}
            id="socialSecurityNumber"
            label={this.props.labels.social_security_number}
            changeHandler={this.handleChange}
            defaultValue={this.props.customerInformation.socialSecurityNumber}
            required={true}
            sx={{ width: "100%" }}
            tooltip={this.props.labels.tooltip_social_security_number}
            className={
              this.props.formInformation.showInvalid &&
              !this.state.socialSecurityNumber
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={8}>
          <div>
            <Button
              sx={{
                minWidth: "100px",
                minHeight: "55px",
                ml: 2,
                backgroundColor: "#273A60",
              }}
              id="fetch_person_info"
              variant="contained"
              onClick={(e) => {
                this.handleClick(e);
              }}
              startIcon={<PermIdentityIcon />}
            >
              {"Hämta information"}
            </Button>
            {this.state.checkFailed && (
              <Button
                sx={{
                  minWidth: "100px",
                  minHeight: "55px",
                  ml: 2,
                  backgroundColor: "#273A60",
                }}
                id="manual"
                variant="contained"
                onClick={(e) => {
                  this.handleClick(e);
                }}
                startIcon={<PermIdentityIcon />}
              >
                {"Fyll i informationen manuellt"}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}
