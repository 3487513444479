import React from "react";
import PropTypes from "prop-types";

import { Grid, TextField } from "@mui/material";
import InputNumber from "./Fields/InputNumber";
import InputText from "./Fields/InputText";
import DisplayField from "./Fields/DisplayField";

export default class Accessories extends React.Component {
  static propTypes = {
    accessories: PropTypes.object,
    labels: PropTypes.object,
    handleAccessories: PropTypes.func,
    id: PropTypes.string,
    number: PropTypes.string,
  };

  /**
   *
   * @param {number} value
   * @param {string} id
   *
   * Multiplies quantity and price to get total price and calls handleAccessories
   */
  handleChange = (value, id) => {
    const fieldId = id.split("_")[1];
    let data = {};
    data[fieldId] = value;
    if (
      this.props.accessories.AccessoriesPrice !== undefined &&
      fieldId === "AccessoriesQuantity"
    ) {
      data["TotalAccessoriesPrice"] =
        value * this.props.accessories.AccessoriesPrice;
    }
    if (
      this.props.accessories.AccessoriesQuantity !== undefined &&
      fieldId === "AccessoriesPrice"
    ) {
      data["TotalAccessoriesPrice"] =
        value * this.props.accessories.AccessoriesQuantity;
    }

    this.props.handleAccessories(data, this.props.id);
  };

  render() {
    return (
      <div style={{ paddingBottom: 1 + "em" }}>
        <Grid container spacing={2} className="">
          <Grid item xs={12}>
            <TextField
              id={this.props.id + "_title"}
              value={this.props.labels.accessory + " " + this.props.number}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={3}>
            <InputNumber
              label={this.props.labels.quantity}
              id={this.props.id + "_AccessoriesQuantity"}
              defaultValue={
                this.props.accessories?.AccessoriesQuantity !== undefined
                  ? this.props.accessories.AccessoriesQuantity
                  : 0
              }
              changeHandler={this.handleChange}
              tooltip={this.props.labels.tooltip_accessory_quantity}
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id={this.props.id + "_AccessoriesName"}
              defaultValue={this.props.accessories?.AccessoriesName}
              changeHandler={this.handleChange}
              label={this.props.labels.accessories_name}
              products={this.props.products}
              tooltip={this.props.labels.tooltip_accessory_name}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={3}>
            <InputNumber
              label={this.props.labels.price_per_accessory}
              id={this.props.id + "_AccessoriesPrice"}
              defaultValue={
                this.props.accessories.AccessoriesPrice !== undefined
                  ? this.props.accessories.AccessoriesPrice
                  : 0
              }
              endAdornment={this.props.labels.currency}
              changeHandler={this.handleChange}
              tooltip={this.props.labels.tooltip_accessory_price}
            />
          </Grid>
          <Grid item xs={3}>
            <DisplayField
              label={this.props.labels.accessories_total_price}
              id={this.props.id + "_TotalAccessoriesPrice"}
              value={
                this.props.accessories.TotalAccessoriesPrice !== undefined
                  ? this.props.accessories.TotalAccessoriesPrice.toString()
                  : "0"
              }
              currency={this.props.labels.currency}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
