import React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Autocomplete,
  CircularProgress,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import debounce from "lodash.debounce";
import {
  fetchAddressSuggestions,
  fetchPlaceDetails,
} from "../../logic/ServerCalls";

export default class LocationInput extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    tooltip: PropTypes.string,
    required: PropTypes.bool,
    sx: PropTypes.object,
    className: PropTypes.string,
    params: PropTypes.object,
    formInfoChange: PropTypes.func,
  };

  static defaultProps = {
    required: false,
    sx: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.defaultValue || "",
      selectedValue: this.props.defaultValue
        ? { Text: this.props.defaultValue }
        : null,
      options: [],
      loading: false,
    };
  }

  fetchSuggestions = debounce(async (input) => {
    if (input.length < 3) {
      this.setState({ options: [] });
      return;
    }

    this.setState({ loading: true });
    try {
      const data = await fetchAddressSuggestions(input);
      if (data && data.Results) {
        this.setState({ options: data.Results });
      } else {
        this.setState({ options: [] });
      }
    } catch (error) {
      this.setState({ options: [] });
    } finally {
      this.setState({ loading: false });
    }
  }, 500);

  getPlace = async (placeId) => {
    try {
      const placeData = await fetchPlaceDetails(placeId);
      const Place = placeData.Place;
      const customerInfo = {};

      if (Place) {
        if (Place.AddressNumber) {
          customerInfo["customerHouseNumber"] = String(
            Place.AddressNumber
          ).toUpperCase();
        }
        if (Place.Street) {
          customerInfo["customerStreet"] = String(Place.Street).toUpperCase();
        }
        if (Place.PostalCode) {
          customerInfo["customerZipCode"] = String(
            Place.PostalCode
          ).toUpperCase();
        }
        if (Place.Municipality) {
          customerInfo["customerCity"] = String(Place.Municipality).toUpperCase(
            customerInfo
          );
        }
        await this.props.changeHandler(customerInfo);
      }
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };
  handleInputChange = (event, newInputValue) => {
    this.setState({ inputValue: newInputValue });
    this.fetchSuggestions(newInputValue);
  };

  handleChange = (event, newValue) => {
    this.setState({
      selectedValue: newValue,
      inputValue: newValue ? newValue.Text : "",
    });
    if (newValue && newValue.PlaceId) {
      this.getPlace(newValue.PlaceId);
    }
  };

  render() {
    return (
      <Tooltip
        title={<span style={{ fontSize: "12px" }}>{this.props.tooltip}</span>}
        placement="top"
      >
        <Autocomplete
          id={this.props.id}
          options={this.state.options}
          getOptionLabel={(option) => option.Text || ""}
          isOptionEqualToValue={(option, value) =>
            option.PlaceId === value.PlaceId || option.Text === value.Text
          }
          filterOptions={(x) => x}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={this.state.selectedValue}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={this.props.label}
              required={this.props.required}
              className={this.props.className}
              sx={Object.assign(this.props.sx, { width: "100%" })}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Tooltip>
    );
  }
}
