import React from "react";
import PropTypes from "prop-types";

export default class Header extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    imageSrc: PropTypes.string,
    params: PropTypes.object,
    imageWidth: PropTypes.string,
    headerType: PropTypes.string,
    labels: PropTypes.object,
    page: PropTypes.number,
  };

  getStringWithLinks = (text) => {
    const textWithClickableLinks = text.replace(
      /https?:\/\/[^\s]+/g,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer"><b>${
          this.props.labels.here + "."
        }</b></a>`
    );
    return textWithClickableLinks;
  };

  render() {
    return (
      <div className={this.props.headerType}>
        {this.props.params.formType !== "flexprivate" && (
          <div className="header-img">
            <img
              src={this.props.imageSrc}
              alt=""
              style={{ width: this.props.imageWidth }}
            ></img>
          </div>
        )}
        {this.props.text !== undefined && (
          <>
            <div className="vertical-line"></div>
            {this.props.page === 1 ? (
              <div
                className="header-text"
                dangerouslySetInnerHTML={{
                  __html: this.getStringWithLinks(this.props.text),
                }}
              />
            ) : (
              <div className="header-text">
                <p>
                  <strong>{this.props.text}</strong>
                </p>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
