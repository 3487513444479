import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import PropTypes from "prop-types";
import CreditCheckSSN from "./CreditCheckSSN";
import CreditCheckNotSSN from "./CreditCheckNotSSN";
import {
  creditCheckSe,
  creditCheckNo,
  experianRequest,
  experianDKRequest,
} from "../../logic/ServerCalls";

import "./css/CreateContract.css";

import { Button, Box } from "@mui/material";

const creditSafeCountries = ["se", "no", "dk"];
const countryCode = {
  at: "AUT",
  de: "DEU",
  dk: "DNK",
  nl: "NLD",
  uk: "GBR",
};

export default class CreditCheck extends React.Component {
  static propTypes = {
    formInformation: PropTypes.object,
    productsValues: PropTypes.object,
    customerInformation: PropTypes.object,
    labels: PropTypes.object,
    accessories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    params: PropTypes.object,
    extraFiles: PropTypes.array,
    switchFormState: PropTypes.func,
    products: PropTypes.object,
    productAccessories: PropTypes.object,
    variables: PropTypes.object,
    newDealerInformation: PropTypes.object,
    changeContractLength: PropTypes.func,
    changeHandlerCustomer: PropTypes.func,
    changeHandlerForm: PropTypes.func,
    changeHandlerCredit: PropTypes.func,
    changeHandlerContractFile: PropTypes.func,
    changeHandlerCreditFile: PropTypes.func,
    createContractPDf: PropTypes.func,
    sendBeforeCerditCheck: PropTypes.func,
  };
  state = {
    submited: false,
    personInfo: undefined,
    personCreditCheck: undefined,
    personCheckId: undefined,
    error: false,
    isCheckingCredit: false,
  };

  /**
   *
   * @param {Date} date - Products delivery date, customers date of birth
   *
   * Parses the Date object to string
   */
  dateToString(date) {
    if (!date) {
      return "";
    }
    return (
      date.getFullYear() +
      (date.getMonth() < 9 ? "0" : "") +
      (date.getMonth() + 1) +
      (date.getDate() < 10 ? "0" : "") +
      date.getDate()
    );
  }

  componentDidMount() {}

  getPriceContractLengths(event) {
    this.props.changeContractLength(event);
  }

  switchFormState = (value) => {
    this.props.switchFormState(value);
  };

  serverResponce = (res) => {
    if (res.status === 200) {
      //if the mail was successfuly sent
      this.switchFormState("completed");
    } else if (res.status === 201) {
      this.switchFormState("show_contract");
    } else {
      //if not
      console.log(res.err);
    }
  };

  creditCheck = async (callback) => {
    this.setState({ isCheckingCredit: true });

    let newState = this.state;
    let personCreditCheck;
    let data = {};
    if (this.props.params.lang.toLowerCase() === "se") {
      personCreditCheck = await creditCheckSe(
        this.props.customerInformation.socialSecurityNumber
      );
      if (personCreditCheck.error) {
        alert(this.props.labels.credit_check_error);
        const uint8Array = new Uint8Array();
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        this.props.changeHandlerCreditFile({
          file: blob,
        });
        newState.error = true;
      } else if (!personCreditCheck.creditPassed) {
        const uint8Array = new Uint8Array(personCreditCheck.creditPdf.data);
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        alert(this.props.labels.credit_check_declined);
        newState.error = true;
        this.props.changeHandlerCreditFile({
          file: blob,
        });
      } else {
        const uint8Array = new Uint8Array(personCreditCheck.creditPdf.data);
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        newState.error = false;
        this.props.changeHandlerCreditFile({
          file: blob,
        });
      }
    } else if (this.props.params.lang.toLowerCase() === "no") {
      let totalPrice = 0;
      Object.keys(this.props.productsValues).forEach((id) => {
        totalPrice =
          totalPrice +
          this.props.productsValues[id].purchasePrice +
          this.props.productsValues[id].purchasePriceAccessorySum;
      });
      personCreditCheck = await creditCheckNo(
        this.props.customerInformation.socialSecurityNumber,
        totalPrice
      );
      if (personCreditCheck.error) {
        alert(this.props.labels.credit_check_error);
        const uint8Array = new Uint8Array();
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        this.props.changeHandlerCreditFile({
          file: blob,
        });
        newState.error = true;
      } else if (!personCreditCheck.creditPassed) {
        const uint8Array = new Uint8Array(personCreditCheck.creditPdf.data);
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        alert(this.props.labels.credit_check_declined);
        newState.error = true;
        this.props.changeHandlerCreditFile({
          file: blob,
        });
      } else {
        const uint8Array = new Uint8Array(personCreditCheck.creditPdf.data);
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        newState.error = false;
        this.props.changeHandlerCreditFile({
          file: blob,
        });
      }
    } else if (this.props.params.lang.toLowerCase() === "dk") {
      personCreditCheck = await experianDKRequest({
        number: this.props.customerInformation.socialSecurityNumber,
      });
      if (personCreditCheck.error) {
        alert(this.props.labels.credit_check_error);
        const uint8Array = new Uint8Array();
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        this.props.changeHandlerCreditFile({
          file: blob,
        });
        newState.error = true;
      } else if (!personCreditCheck.creditPassed) {
        const uint8Array = new Uint8Array(personCreditCheck.creditPdf.data);
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        alert(this.props.labels.credit_check_declined);
        newState.error = true;
        this.props.changeHandlerCreditFile({
          file: blob,
        });
      } else {
        const uint8Array = new Uint8Array(personCreditCheck.creditPdf.data);
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        newState.error = false;
        this.props.changeHandlerCreditFile({
          file: blob,
        });
      }
    }
    this.props.changeHandlerCredit(data);
    this.setState(newState, callback);
    this.setState({ isCheckingCredit: false });
  };

  experianCheck = async () => {
    let request = {
      requestParams: {
        CustomerRefId: 1,
        ReasonOfInquiry: "ABK",
        PersonParams: {
          Title: "",
          Salutation: "",
          LastName: this.props.customerInformation.customerLastName,
          FirstName: this.props.customerInformation.customerFirstName,
          Addresses: {
            Address: {
              Street: this.props.customerInformation.customerStreet,
              HouseNumber: this.props.customerInformation.customerHouseNumber,
              ZipCode: this.props.customerInformation.customerZipCode,
              City: this.props.customerInformation.customerCity,
              Country: countryCode[this.props.params.lang.toLowerCase()],
            },
          },
          BankAccountParams: {
            BankCode: "",
            BankAccount: "",
          },
          Services: {
            PersonCreditCheck: {
              enabled: true,
            },
            BankAccountCreditCheck: {
              enabled: false,
            },
          },
        },
      },
    };
    await experianRequest(request);
  };

  getTotalPrice() {
    let totalPrice = 0;
    Object.keys(this.props.productsValues).forEach((key) => {
      totalPrice +=
        this.props.productsValues[key].name !== null
          ? this.props.productsValues[key].purchasePrice +
            this.props.productsValues[key].installation
          : 0;
    });
    Object.keys(this.props.accessories).forEach((key) => {
      totalPrice += this.props.accessories[key].TotalAccessoriesPrice;
    });
    return totalPrice;
  }

  render() {
    return (
      <div>
        <div className="titles-wrapp">
          <h1 className="titles">{this.props.labels.credit_check}</h1>
          <hr />
        </div>
        {creditSafeCountries.includes(this.props.params.lang.toLowerCase()) ? (
          <div>
            <CreditCheckSSN
              customerInformation={this.props.customerInformation}
              labels={this.props?.labels}
              formInformation={this.props.formInformation}
              params={this.props.params}
              changeHandler={this.props.changeHandlerCustomer}
            />
          </div>
        ) : (
          <div>
            <CreditCheckNotSSN
              customerInformation={this.props.customerInformation}
              labels={this.props?.labels}
              formInformation={this.props.formInformation}
              params={this.props.params}
              changeHandler={this.props.changeHandlerCustomer}
            />
          </div>
        )}
        {this.state.error && (
          <div className="rightButton">
            {!this.state.submited ? (
              <Button
                sx={{
                  minWidth: "350px",
                  minHeight: "45px",
                  backgroundColor: "#273A60",
                }}
                onClick={async () => {
                  this.setState({ submited: true });
                  await this.props.sendBeforeCerditCheck();
                }}
                variant="contained"
              >
                <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
                <span className="app-span-text">
                  {this.props.labels.submit}{" "}
                </span>
              </Button>
            ) : (
              <Box>
                <CircularProgress />
              </Box>
            )}
          </div>
        )}
        <div className="row checkboxRow">
          <Button
            onClick={() => {
              this.switchFormState("edit");
            }}
            variant="contained"
            sx={{ backgroundColor: "#273A60" }}
          >
            <ArrowBackRoundedIcon sx={{ marginRight: "5px" }} />
            {this.props.labels.edit_form}
          </Button>
          {this.state.isCheckingCredit ? (
            <CircularProgress />
          ) : (
            <Button
              sx={{
                minWidth: "350px",
                minHeight: "45px",
                backgroundColor: "#273A60",
              }}
              onClick={async () => {
                await new Promise((resolve) => this.creditCheck(resolve));
                if (!this.state.error) {
                  this.switchFormState("create_contract");
                  //await this.props.createContractPDf();
                }
              }}
              variant="contained"
            >
              <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
              <span className="app-span-text">
                {this.props.labels.make_credit_check}{" "}
              </span>
            </Button>
          )}
        </div>
      </div>
    );
  }
}
