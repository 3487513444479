import { useEffect, useContext } from "react";
import { AuthContext } from "./hcsauthprovider";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function Callback() {
    const { signinRedirectCallback } = useContext(AuthContext);

    useEffect(() => {
        try {
            signinRedirectCallback().then((x) => {
                const url = localStorage.getItem("redirectUri");
                window.location = url;
            });
        } catch (error) {
            console.log('error', error);
        }
    });

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        </div>
    );
}

export default Callback;