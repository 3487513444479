import React from "react";
import Information from "../component/Information";
import { useParams } from "react-router-dom";
import { getLabels } from "../logic/ServerCalls";
import { getContractOnboardingPDF } from "../logic/ServerCalls";
import { Stack, Button, Modal, Box, Typography, Divider } from "@mui/material";
import NewDealerFloorplan from "../component/Information/Dealer/NewDealerFloorplan";
import NotFound from "../component/NotFound";
import configSource from "../res/config.json";
import config from "./res/config.json";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { sendEmailToAddress } from "../logic/ServerCalls";
import "./css/onboarding.css";
import FloorplanContract from "../component/Contract/OnboardingFloorplanContract";
import Header from "../component/Header";
import InformationSection from "../component/Information/InformationSection";
import CircularProgress from "@mui/material/CircularProgress";
const ReactDomServer = require("react-dom/server");

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Hcs extends React.Component {
  state = {
    isLoading: false,
    labels: {},
    headerText: "",
    headerImgSrc: "",
    params: {},
    modalOpen: true,
    renewalOfContract: false,
    newDealerInformation: {
      wantDemoFinancing: false,
      wantFloorplanFinancing: false,
      newDealerFinished: false,
      verifyNewDealer: false,
      newDealerName: "", // Namn
      organizationNumber: "", // Organisationsnummer
      postAddress: "", // Postadress
      nd_zipCode: "", // Postnummer
      nd_city: "", // Ort
      contactPerson: "", // Kontaktperson
      phoneContactPerson: "", // Telefonnummer
      emailContactPerson: "", // E-postadress
      invoiceEmail: "", // E-post för avisering
      differentInvoiceAddress: false, // Är fakturaadressen en annan än bolagsadressen?
      invoiceAddress: "", // Fakturaadress
      invoiceZipCode: "", // Postnummer (för fakturaadress)
      invoiceCity: "", // Ort (för fakturaadress)
      wantEInvoice: false, // Vill ni ha fakturering via E-faktura (PEPPOL)?
      differentEInvoiceEmail: false, // Är det en annan e-postadress än den för avi/faktura?
      eInvoiceEmail: "", // Ange e-postadress (Peppol)

      // Bankuppgifter
      bankGiroPostGiro: "", // Bankgiro/Postgiro
      bankAccountNumber: "", // Bankkontonummer
      bicSwiftCode: "", // BIC/Swift kod
      clearingNumber: "", // Clearingnummer
      ibanNumber: "", // IBAN
      bankName: "", // Bank namn

      // Husqvarna
      husqvarnaNumber: "", // Husqvarna-kundnummer
      hasHusqvarnaPortalUser: false, // Har ni en användare i Husqvarna-portalen?
      husqvarnaUserFirstName: "", // Förnamn
      husqvarnaUserLastName: "", // Efternamn
      husqvarnaUserEmail: "", // E-postadress
      husqvarnaUserId: "", // Husqvarna användarID-nummer

      // Verklighuvudman
      hasOwnershipOver25Percent: false, // Äger eller kontrollerar några fysiska personer mer än 25% av bolaget?
      beneficialOwners: [
        {
          personalNumber: "", // Personnummer
          firstName: "", // Förnamn
          lastName: "", // Efternamn
          ownershipPercentage: "", // Ägandeandel (%)
          isPEP: false, // Är denna verkliga huvudman en PEP?
        },
      ],

      // Firmatecknare
      newDealerSignatories: [
        {
          firstName: "", // Förnamn
          lastName: "", // Efternamn
          emailSignatoryNewDealer: "", // E-postadress
          socialSecurityNumberSignatory: "", // Personnummer
          addressSignatory: "", // Postadress
          zipCodeSignatory: "", // Postnummer
          citySignatory: "", // Ort
        },
      ],
    },
    formInformation: {
      formState: "edit",
      showInvalid: false,
      useCampaign: false,
    },
    extraFiles: [],
    firstRender: true,
  };

  /**
   *  Gets called after first render.
   */
  componentDidMount() {
    if (this.validateParams()) {
      this.setState({
        firstRender: false,
        params: { ...this.props.params, comp: "hcs" },
      });
      this.SetFormInformation();
    }
  }

  /**
   * Requests labels, headerText, productlist and variables from server and puts them in state.
   */
  SetFormInformation = () => {
    (async () => {
      try {
        const lang = this.props.params.lang.toLowerCase();
        let labels;
        labels = await getLabels(lang);
        let state = this.state;

        state.labels = labels;
        state.isVerified = true;
        try {
          let response = await fetch(
            configSource.ROOT +
              `getimage?company=hcs&country=images&type=Logo.jpg`
          );
          state.headerImgSrc = response.url;
        } catch (error) {
          console.log("Could Not Fetch Header Image: \n", error);
        }

        this.setState(state);
      } catch (error) {
        //TODO redirect
        console.log(error);
      }
    })();
  };

  /**
   *
   * @param {Array<File>} files
   *
   * Puts the files in the array in state.
   */
  addExtraFiles = (files) => {
    let extraFiles = this.state.extraFiles;
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      extraFiles.push(element);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * @param {File} file
   *
   * Removes given file from state if it exists.
   */
  removeExtraFiles = (file) => {
    let extraFiles = this.state.extraFiles;
    let index = extraFiles.indexOf(file);
    if (index !== -1) {
      extraFiles.splice(index, 1);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of newDealerInformation based on keys in info.
   */
  handleNewDealerInfoChange = (info) => {
    let newDealerInformation = this.state.newDealerInformation;

    for (let id in info) {
      newDealerInformation[id] = info[id];
    }
    this.setState({
      newDealerInformation: newDealerInformation,
    });
  };

  /**
   * Handles checkbox state toggle depending on case.
   * @param {Object} event
   */
  handleChecked = async (event) => {
    let newState;
    let newDealerInformation = this.state.newDealerInformation;

    switch (event.target.id) {
      case "verifyNewDealer":
        newState = !this.state.newDealerInformation.verifyNewDealer;
        newDealerInformation.verifyNewDealer = newState;
        this.setState({ newDealerInformation });
        break;
      case "differentInvoiceAddress":
        newState = !this.state.newDealerInformation.differentInvoiceAddress;
        newDealerInformation.differentInvoiceAddress = newState;
        this.setState({ newDealerInformation });
        break;
      case "differentEInvoiceEmail":
        newState = !this.state.newDealerInformation.differentEInvoiceEmail;
        newDealerInformation.differentEInvoiceEmail = newState;
        this.setState({ newDealerInformation });
        break;
      case "hasHusqvarnaPortalUser":
        newState = !this.state.newDealerInformation.hasHusqvarnaPortalUser;
        newDealerInformation.hasHusqvarnaPortalUser = newState;
        this.setState({ newDealerInformation });
        break;
      case "hasOwnershipOver25Percent":
        newState = !this.state.newDealerInformation.hasOwnershipOver25Percent;
        newDealerInformation.hasOwnershipOver25Percent = newState;
        this.setState({ newDealerInformation });
        break;
      case "wantDemoFinancing":
        newState = !this.state.newDealerInformation.wantDemoFinancing;
        newDealerInformation.wantDemoFinancing = newState;
        this.setState({ newDealerInformation });
        break;
      case "wantFloorplanFinancing":
        newState = !this.state.newDealerInformation.wantFloorplanFinancing;
        newDealerInformation.wantFloorplanFinancing = newState;
        this.setState({ newDealerInformation });
        break;

      default:
        throw new Error("Unknown checkbox ID");
    }
  };

  /**
   * Handles radio button state change.
   * @param {Object} event
   */
  handleRadioChange = async (event) => {
    let newDealerInformation = this.state.newDealerInformation;
    const { name, value } = event.target;
    switch (name) {
      case "wantEInvoice":
        newDealerInformation.wantEInvoice = value === "yes";
        this.setState({ newDealerInformation });
        break;
      case "differentInvoiceAddress":
        newDealerInformation.differentInvoiceAddress = value === "yes";
        this.setState({ newDealerInformation });
        break;
      case "hasOwnershipOver25Percent":
        newDealerInformation.hasOwnershipOver25Percent = value === "yes";
        this.setState({ newDealerInformation });
        break;
      case "hasHusqvarnaPortalUser":
        newDealerInformation.hasHusqvarnaPortalUser = value === "yes";
        this.setState({ newDealerInformation });
        break;

      default:
        throw new Error("Unknown radio button name");
    }
  };

  formatNewDealerInformation = (newDealerInformation) => {
    const {
      wantDemoFinancing,
      wantFloorplanFinancing,
      newDealerFinished,
      verifyNewDealer,
      newDealerName,
      organizationNumber,
      postAddress,
      nd_zipCode,
      nd_city,
      contactPerson,
      phoneContactPerson,
      emailContactPerson,
      invoiceEmail,
      differentInvoiceAddress,
      invoiceAddress,
      invoiceZipCode,
      invoiceCity,
      wantEInvoice,
      differentEInvoiceEmail,
      eInvoiceEmail,
      bankGiroPostGiro,
      bankAccountNumber,
      bicSwiftCode,
      clearingNumber,
      ibanNumber,
      bankName,
      husqvarnaNumber,
      hasHusqvarnaPortalUser,
      husqvarnaUserFirstName,
      husqvarnaUserLastName,
      husqvarnaUserEmail,
      husqvarnaUserId,
      hasOwnershipOver25Percent,
      beneficialOwners,
      newDealerSignatories,
    } = newDealerInformation;

    let desiredService = "";
    if (wantDemoFinancing && wantFloorplanFinancing) {
      desiredService = "Floorplan- och demofinansering";
    } else if (wantDemoFinancing) {
      desiredService = "Demofinansering";
    } else if (wantDemoFinancing) {
      desiredService = "Floorplanfinansering";
    }
    const beneficialOwnersHTML = beneficialOwners
      .map((owner, index) => {
        return `<p><strong>Beneficial Owner ${index + 1}:</strong><br/>
    - First Name: ${owner.firstName}<br/>
    - Last Name: ${owner.lastName}<br/>
    - Personal Number: ${owner.personalNumber}<br/>
    - Ownership Percentage: ${owner.ownershipPercentage}%<br/>
    - PEP: ${owner.isPEP ? "Yes" : "No"}</p>`;
      })
      .join("");

    const signatoriesHTML = newDealerSignatories
      .map((signatory, index) => {
        return `<p><strong>Signatory ${index + 1}:</strong><br/>
    - Email: ${signatory.emailSignatoryNewDealer}<br/>
    - Social Security Number: ${signatory.socialSecurityNumberSignatory}<br/>
    - Address: ${signatory.addressSignatory}<br/>
    - Zip Code: ${signatory.zipCodeSignatory}<br/>
    - City: ${signatory.citySignatory}</p>`;
      })
      .join("");

    return `
    <div style="font-family: Arial, sans-serif;">
      <h2>New Dealer Information</h2>
      <p><strong>New Dealer Finished:</strong> ${
        newDealerFinished ? "Yes" : "No"
      }</p>
      <p><strong>Verify New Dealer:</strong> ${
        verifyNewDealer ? "Yes" : "No"
      }</p>
      <p><strong>Name:</strong> ${newDealerName}</p>
      <p><strong>Organization Number:</strong> ${organizationNumber}</p>
      <p><strong>Post Address:</strong> ${postAddress}</p>
      <p><strong>Zip Code:</strong> ${nd_zipCode}</p>
      <p><strong>City:</strong> ${nd_city}</p>
      <p><strong>Contact Person:</strong> ${contactPerson}</p>
      <p><strong>Phone Contact Person:</strong> ${phoneContactPerson}</p>
      <p><strong>Email Contact Person:</strong> ${emailContactPerson}</p>
      <p><strong>Invoice Email:</strong> ${invoiceEmail}</p>
      <p><strong>Different Invoice Address:</strong> ${
        differentInvoiceAddress ? "Yes" : "No"
      }</p>
      <p><strong>Invoice Address:</strong> ${invoiceAddress}</p>
      <p><strong>Invoice Zip Code:</strong> ${invoiceZipCode}</p>
      <p><strong>Invoice City:</strong> ${invoiceCity}</p>
      <p><strong>Want E-Invoice:</strong> ${wantEInvoice ? "Yes" : "No"}</p>
      <p><strong>Different E-Invoice Email:</strong> ${
        differentEInvoiceEmail ? "Yes" : "No"
      }</p>
      <p><strong>E-Invoice Email:</strong> ${eInvoiceEmail}</p>
  
      <h3>Bank Details</h3>
      <p><strong>Bank Giro/PostGiro:</strong> ${bankGiroPostGiro}</p>
      <p><strong>Bank Account Number:</strong> ${bankAccountNumber}</p>
      <p><strong>BIC/Swift Code:</strong> ${bicSwiftCode}</p>
      <p><strong>Clearing Number:</strong> ${clearingNumber}</p>
      <p><strong>IBAN Number:</strong> ${ibanNumber}</p>
      <p><strong>Bank Name:</strong> ${bankName}</p>
  
      <h3>Husqvarna Information</h3>
      <p><strong>Husqvarna Number:</strong> ${husqvarnaNumber}</p>
      <p><strong>Has Husqvarna Portal User:</strong> ${
        hasHusqvarnaPortalUser ? "Yes" : "No"
      }</p>
      <p><strong>Husqvarna User First Name:</strong> ${husqvarnaUserFirstName}</p>
      <p><strong>Husqvarna User Last Name:</strong> ${husqvarnaUserLastName}</p>
      <p><strong>Husqvarna User Email:</strong> ${husqvarnaUserEmail}</p>
      <p><strong>Husqvarna User ID:</strong> ${husqvarnaUserId}</p>
  
      <h3>Desired Service</h3>
      <p>${desiredService}</p>
  
      <h3>Ownership Information</h3>
      <p><strong>Has Ownership Over 25%:</strong> ${
        hasOwnershipOver25Percent ? "Yes" : "No"
      }</p>
      ${beneficialOwnersHTML}
  
      <h3>Signatories</h3>
      ${signatoriesHTML}
    </div>
    `;
  };

  /* 
    Uploads data to the Onboaring excel file stored in s3 bucket amplio-shared-files.
  */

  uploadData = async function () {
    try {
      this.setState({ isLoading: true });

      var flatData = this.formatNewDealerInformation(
        this.state.newDealerInformation
      );

      if (this.state.renewalOfContract) {
        await this.handleRenewalContract(flatData);
      } else {
        await this.handleNewContract(flatData);
      }

      this.switchFormState("completed");
    } catch (error) {
      console.error("Upload failed", error);
      alert(
        "Information could not be sent. Please reload the page and try again."
      );
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleRenewalContract = async function (flatData) {
    var resp = await sendEmailToAddress(
      flatData,
      "support-4@www.ampliorental.se.eu1.r.hs-inbox.com",
      "Intresseanmälan " + this.state.newDealerInformation.newDealerName
    );

    if (resp?.status !== 200) {
      throw new Error("Failed to send email for renewal contract");
    }
  };

  handleNewContract = async function (flatData) {
    var contract = this.generateContractHTML();
    var pdfBlob = await this.generateContractPDF(contract);
    var base64PDF = await this.convertBlobToBase64(pdfBlob);

    //await this.downloadPDF(pdfBlob);

    var resp = await sendEmailToAddress(
      flatData,
      "support-4@www.ampliorental.se.eu1.r.hs-inbox.com",
      "Onboarding " + this.state.newDealerInformation.newDealerName,
      base64PDF,
      "Onboarding.pdf"
    );

    if (resp?.status !== 200) {
      throw new Error("Failed to send email for new contract");
    }
  };

  generateContractHTML = function () {
    return ReactDomServer.renderToStaticMarkup(
      React.createElement(FloorplanContract, {
        newDealerInformation: this.state.newDealerInformation,
        labels: this.state.labels,
      })
    );
  };

  generateContractPDF = async function (contract) {
    var additionalPdfUrl =
      configSource.ROOT +
      "getimage?company=hcs&country=" +
      this.state.params.lang.toLowerCase() +
      "&type=onboarding_message.pdf";
    var requestBody = {
      htmlContent: contract,
      additionalPdfUrl: additionalPdfUrl,
    };
    var response = await getContractOnboardingPDF(requestBody);

    if (!response.data) {
      throw new Error("Failed to generate PDF");
    }

    return new Blob([new Uint8Array(response.data)], {
      type: "application/pdf",
    });
  };

  convertBlobToBase64 = function (blob) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  downloadPDF = function (blob) {
    var url = window.URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = url;
    link.download = "Onboarding.pdf";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };
  /**
   *
   * @param {String} value
   *
   * Switches the current formState to new value.
   */
  switchFormState = (value) => {
    let formInformation = this.state.formInformation;
    formInformation.formState = value;
    this.setState(formInformation);
  };

  showInvalid = (newValue = true) => {
    let newFormInformation = this.state.formInformation;
    newFormInformation.showInvalid = newValue;
    this.setState({
      formInformation: newFormInformation,
    });
  };

  /**
   *
   * @returns Boolean
   */
  validateFormInputs = () => {
    if (configSource["DEBUG"]) return true;
    if (!this.state.newDealerInformation.newDealerFinished) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    this.showInvalid(false);
    return true;
  };

  /**
   *
   * @returns Boolean
   *
   * Check the params to validate that the form requested exists.
   */
  validateParams = () => {
    const allowedArgs = config.ALLOWED_ARGS;
    if (allowedArgs["hcs"].includes(this.props.params.lang.toLowerCase())) {
      return true;
    }
    return false;
  };

  render() {
    if (!this.validateParams()) return <NotFound />;
    if (this.state.firstRender) return;
    const disabled = this.state.newDealerInformation.verifyNewDealer
      ? false
      : true;
    return (
      <Stack className="form-wrapper" alignItems="stretch">
        <div>
          <Modal
            open={this.state.modalOpen}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 350, //700
                bgcolor: "background.paper",
                borderRadius: "12px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                p: 4,
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr", // auto 1fr",
                  gap: 4,
                  alignItems: "center", // Center content vertically in grid cells
                  height: "100%", // Ensure grid container takes full height
                }}
              >
                {/* First alternative */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // Distribute space between content and button
                    textAlign: "center",
                    height: "250px", // Set a common height for alignment
                  }}
                >
                  <Typography
                    id="simple-modal-title"
                    variant="h5"
                    component="h2"
                    sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
                  >
                    Intresseanmälan
                  </Typography>
                  <Typography variant="body1" id="simple-modal-description">
                    Befintliga kunder, som genomfört kundregistrering och
                    signerat ett låneramavtal under 2024, ombedes endast skicka
                    in en intresseanmälan för att ta del av Husqvarna
                    lagerfinansiering under 2025.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, backgroundColor: "#273A60" }}
                    onClick={() =>
                      this.setState({
                        modalOpen: false,
                        renewalOfContract: true,
                      })
                    }
                  >
                    TILL INTRESSEANMÄLAN
                  </Button>
                </Box>

                {/*<Divider orientation="vertical" flexItem />

                 Second alternative 
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // Distribute space between content and button
                    textAlign: "center",
                    height: "250px", // Set a common height for alignment
                  }}
                >
                  <Typography
                    id="simple-modal-title"
                    variant="h5"
                    component="h2"
                    sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
                  >
                    Registrering
                  </Typography>
                  <Typography variant="body1" id="simple-modal-description">
                    Nya kunder ombedes genomföra en komplett kundregistrering.
                    Efter granskning och godkännande av era uppgifter, kommer
                    ett låneramavtal att skickas till firmatecknare för digital
                    signering.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ mt: 2, backgroundColor: "#273A60" }}
                    onClick={() =>
                      this.setState({
                        modalOpen: false,
                        renewalOfContract: false,
                      })
                    }
                  >
                    TILL KUNDREGISTRERING
                  </Button>
                </Box>*/}
              </Box>
            </Box>
          </Modal>
        </div>
        {this.state.formInformation.formState === "edit" && (
          <div>
            <Header
              text={
                "Välkommen till intresseanmälan och registrering för Husqvarna lagerfinansieringstjänster.\n\nHusqvarna erbjuder återförsäljare en komplett lösning för att finansiera sitt lager och demosortiment av Husqvarna-produkter. Istället för att binda kapital inför säsong eller söka extern finansiering, kan ni som återförsäljare ta del av konkurrenskraftig finansiering på lagerförda produkter. Återbetalning sker enligt bestämd betalningsfrekvens eller vid tidigare lösen. Lagerfinansiering sker via Husqvarna Commercial Solutions."
              }
              imageSrc={this.state.headerImgSrc}
              params={this.state.params}
              imageWidth={"200px"}
              headerType={"header"}
              labels={this.state.labels}
              page={1}
            />
            <div>
              {/*<div className="titles-wrapp">
                <h1 className="titles">{this.state.labels.onboarding}</h1>
                <hr />
              </div>
              */}

              <InformationSection />
              <Divider />
              <div className="padding-top">
                <NewDealerFloorplan
                  renewalOfContract={this.state.renewalOfContract}
                  newDealer={this.state.newDealerInformation}
                  labels={this.state?.labels}
                  changeHandler={this.handleNewDealerInfoChange}
                  formInformation={this.state.formInformation}
                  checkHandler={this.handleChecked}
                  radioHandler={this.handleRadioChange}
                  params={this.state.params}
                  addSignatory={this.addSignatory}
                  removeSignatory={this.removeSignatory}
                  payment={false}
                />
              </div>

              {this.state.isLoading ? (
                <Box sx={{ textAlign: "right" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <div className="button-container-send">
                  <Button
                    sx={{
                      minWidth: "350px",
                      minHeight: "45px",
                      backgroundColor: "#273A60",
                    }}
                    onClick={() => {
                      if (this.validateFormInputs()) {
                        this.uploadData();
                      }
                    }}
                    variant="contained"
                    disabled={disabled}
                  >
                    <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
                    <span className="app-span-text">
                      {this.state.labels.send}{" "}
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.formInformation.formState === "completed" && (
          <div>
            <Header
              imageSrc={this.state.headerImgSrc}
              params={this.state.params}
              imageWidth={"200px"}
              headerType={"header"}
              labels={this.state.labels}
            />
            <div className="completedText">
              <h1>{this.state.labels.thank_you}</h1>

              <div>
                <p>
                  Din anmälan har skickats till HCS! HCS kan komma att kontakta
                  dig angående er anmälan via HCS-onboarding@ampliorental.se.
                </p>
              </div>
            </div>
          </div>
        )}
        <br></br>
      </Stack>
    );
  }
}

export default withParams(Hcs);
