import React from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment, Tooltip } from "@mui/material";
import debounce from "lodash.debounce";

export default class InputNumber extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf([""]),
    ]),
    min: PropTypes.number,
    max: PropTypes.number,
    tooltip: PropTypes.string,
    endAdornment: PropTypes.string,
    required: PropTypes.bool,
    sx: PropTypes.object,
    className: PropTypes.string,
  };

  /**
   * Check inputed value 1.5 seconds after last input.
   * If min > value or value > max alert user and change value.
   */
  validateMinMax = debounce((value) => {
    if (value === "") {
      this.props.changeHandler(this.props.min, this.props.id);
      return;
    }
    if (value > this.props.max) {
      alert(
        "The inputed value is bigger than allowed (" + this.props.max + ")"
      );
      this.props.changeHandler(this.props.max, this.props.id);
      return;
    }
    if (value < this.props.min) {
      alert(
        "The inputed value is smaller than allowed (" + this.props.min + ")"
      );
      this.props.changeHandler(this.props.min, this.props.id);
      return;
    }
    this.props.changeHandler(value, this.props.id);
  }, 1500);

  /**
   *
   * @param {Object} event - event
   *
   * If a new number is inputed
   */
  handleChange = (event) => {
    let value = event.target.value;
    value = event.target.value === "" ? "" : parseFloat(value);
    this.props.changeHandler(value, this.props.id);
    this.validateMinMax(value);
  };

  render() {
    return (
      <Tooltip
        title={<span style={{ fontSize: "12px" }}>{this.props.tooltip}</span>}
        placement="top"
      >
        <TextField
          label={this.props.label}
          type="number"
          className={this.props.className}
          value={this.props.defaultValue}
          required={this.props.required}
          onKeyDown={(event) => event.key === "event" && event.preventDefault()}
          onChange={this.handleChange}
          InputProps={{
            readOnly: this.props.readOnly,
            endAdornment: (
              <InputAdornment position="end">
                {this.props.endAdornment}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                {this.props.startAdornment}
              </InputAdornment>
            ),
            min: this.props.min,
            max: this.props.max,
          }}
          sx={Object.assign(this.props.sx, { width: "100%" })}
        />
      </Tooltip>
    );
  }
}

InputNumber.defaultProps = {
  min: 0,
  max: 100000000,
  endAdornment: "",
  required: false,
  sx: {},
};
