import React from "react";
import PropTypes from "prop-types";
import InputNumber from "../../component/Fields/InputNumber";
import ProductList from "../../component/Fields/ProductList";
import DisplayField from "../../component/Fields/DisplayField";
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@mui/material";
import debounce from "lodash.debounce";

export default class ArjoStandard extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    number: PropTypes.string,
    productValues: PropTypes.object,
    formInformation: PropTypes.object,
    labels: PropTypes.object,
    variables: PropTypes.object,
    products: PropTypes.object,
    updateProduct: PropTypes.func,
    calculateNewFee: PropTypes.func,
    productCategories: PropTypes.object,
  };

  state = {
    productList: {},
  };

  productFinished = debounce(() => {
    const data = {
      finished:
        this.props.productValues.quantity > 0 &&
        this.props.productValues.price > 0 &&
        this.props.productValues.name !== null &&
        (this.props.productValues.service > 0 ||
          this.props.params.lang !== "de"),
    };
    this.props.updateProduct(data, this.props.id);
  }, 100);

  finished = (fieldId) => {
    switch (fieldId) {
      case "quantity":
        return this.props.productValues.quantity > 0;
      case "price":
        return this.props.productValues.price > 0;
      case "name":
        return this.props.productValues.name !== null;
      case "service":
        return this.props.productValues.service > 0;
      default:
        return -1;
    }
  };

  componentDidMount() {
    if (this.props.params.lang.toLowerCase() === "de") {
      this.props.updateProduct({ servicePackage: "premium" }, this.props.id);
    }
    if (this.props.params.lang.toLowerCase() === "uk") {
      this.handleChange("Financial lease", "_leasingType");
    }
  }

  /**
   *
   * @param {Number} value - The new value of the field
   * @param {String} id    - id of the field the value is from
   *
   * Updates the field with new value.
   */

  handleChange = (value, id) => {
    const fieldId = id.split("_")[1];
    let data = {};
    if (fieldId === "productType") {
      data["name"] = null;
    }
    if (fieldId === "name") {
      value === null
        ? (data = {
            recomendedPrice: 0,
            recomendedServicePrices: 0,
            transferPrice: 0,
          })
        : (data = {
            recomendedPrice: this.props.products[value].price[0],
            recomendedServicePrices: this.props.products[value].service,
            transferPrice: this.props.products[value]?.transfer_price,
          });
    }
    data["recomendedServicePrice"] = this.getRecomendedServicePrice();
    data[fieldId] = value;
    if (fieldId === "quantity") {
      data["totalProductPrice"] = value * this.props.productValues.price;
    }
    if (fieldId === "price") {
      data["totalProductPrice"] = value * this.props.productValues.quantity;
    }
    this.props.updateProduct(data, this.props.id);
    this.productFinished();
    this.props.calculateNewFee();
  };

  getRecomendedServicePrice = () => {
    if (this.props.params.lang.toLowerCase() === "de") {
      return 0;
    }
    switch (this.props.productValues.servicePackage) {
      case "none":
        return 0;
      case "premium":
        return this.props.formInformation.contractLength < 60 ? 1 : 2;
      case "preventative":
        return this.props.formInformation.contractLength < 60 ? 3 : 4;
      default:
        throw Error("unknown service package");
    }
  };
  render() {
    const size = this.props.params.lang.toLowerCase() === "de" ? 6 : 3;

    return (
      <Grid container spacing={2} className="Product">
        {this.props.number === "1" ? (
          <Grid item xs={2}>
            <Tooltip
              title={
                <span style={{ fontSize: "12px" }}>
                  {this.props.labels.tooltip_leasing_type}
                </span>
              }
              placement="top"
            >
              <FormControl>
                <FormLabel>{this.props.labels.leasing_type}</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={this.props.formInformation.leasingType}
                  name="radio-buttons-group"
                  onChange={(e) => {
                    this.handleChange(e.target.value, "_leasingType");
                  }}
                >
                  {this.props.params.lang !== "de" ? (
                    <FormControlLabel
                      value={"Financial lease"}
                      control={<Radio />}
                      label={this.props.labels.financial_lease}
                    />
                  ) : (
                    <FormControlLabel
                      value={"Operational lease"}
                      control={<Radio />}
                      checked={true}
                      label={this.props.labels.operational_lease}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Tooltip>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <TextField
            id={this.props.id + "_title"}
            value={this.props.labels.product + " " + this.props.number}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={2}>
          <InputNumber
            label={this.props.labels.quantity}
            id={this.props.id + "_quantity"}
            defaultValue={this.props.productValues.quantity}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_quantity}
            className={
              this.props.formInformation.showInlvaid &&
              !this.finished("quantity")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={3}>
          <ProductList
            id={this.props.id + "_productType"}
            defaultValue={this.props.productValues.productType}
            changeHandler={this.handleChange}
            label={this.props.labels.equipment_type}
            products={this.props.productCategories}
            tooltip={this.props.labels.tooltip_product}
            required={true}
            className={
              this.props.formInformation.showInvalid && !this.finished("name")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <ProductList
            id={this.props.id + "_name"}
            defaultValue={this.props.productValues.name}
            changeHandler={this.handleChange}
            label={this.props.labels.choose_a_product}
            products={this.props.products}
            tooltip={this.props.labels.tooltip_product}
            required={true}
            className={
              this.props.formInformation.showInvalid && !this.finished("name")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        {this.props.params.lang.toLowerCase() === "de" && (
          <Grid item xs={6}>
            <DisplayField
              label={this.props.labels.recomended_price}
              id={this.props.id + "_recomendedPrice"}
              value={
                this.props.productValues.recomendedPrice !== undefined
                  ? this.props.productValues.recomendedPrice.toString()
                  : "0"
              }
              tooltip={this.props.labels.tooltip_product_price}
              endAdornment={this.props.labels.currency}
            />
          </Grid>
        )}
        <Grid item xs={size}>
          <InputNumber
            label={this.props.labels.salespersons_chosen_price}
            id={this.props.id + "_price"}
            defaultValue={this.props.productValues.price}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_product_price}
            endAdornment={this.props.labels.currency}
            className={
              this.props.formInformation.showInvalid && !this.finished("price")
                ? "invalid"
                : ""
            }
          />
        </Grid>
        {this.props.params.lang.toLowerCase() === "de" && (
          <Grid item xs={6}>
            <DisplayField
              label={this.props.labels.recomended_service_price}
              id={this.props.id + "_recomendedServicePrice"}
              value={
                this.props.productValues.recomendedServicePrices?.length >
                this.getRecomendedServicePrice()
                  ? this.props.productValues.recomendedServicePrices[
                      this.getRecomendedServicePrice()
                    ].toString()
                  : "0"
              }
              tooltip={this.props.labels.tooltip_product_price}
              endAdornment={this.props.labels.currency}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <InputNumber
            label={this.props.labels.salespersons_chosen_service_price}
            id={this.props.id + "_service"}
            defaultValue={this.props.productValues.service}
            changeHandler={this.handleChange}
            required={this.props.params.lang === "de"}
            tooltip={this.props.labels.tooltip_service_per_year}
            endAdornment={this.props.labels.currency}
            className={
              this.props.formInformation.showInvalid &&
              !this.finished("service") &&
              this.props.params.lang === "de"
                ? "invalid"
                : ""
            }
          />
        </Grid>
        {this.props.params.lang === "de" ? (
          <Grid item xs={6}>
            <DisplayField
              label={this.props.labels.transfer_price}
              id={this.props.id + "_transferPrice"}
              value={this.props.productValues.transferPrice.toString()}
              tooltip={this.props.labels.tooltip_transfer_price}
              endAdornment={this.props.labels.currency}
            />
          </Grid>
        ) : null}
        {this.props.params.lang === "de" ? (
          <Grid item xs={6}>
            <DisplayField
              label={this.props.labels.margin}
              id={this.props.id + "_margin"}
              value={
                this.props.productValues.price > 0
                  ? this.props.productValues.price -
                    this.props.productValues.transferPrice
                  : 0
              }
              tooltip={this.props.labels.tooltip_margin}
              endAdornment={this.props.labels.currency}
            />
          </Grid>
        ) : null}
        {this.props.formInformation.leasingType === "Operational lease" &&
          this.props.params.lang === "uk" && (
            <Grid item xs={12}>
              <TextField
                id={this.props.id + "_title"}
                value={this.props.labels.obs_residuals}
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
                fullWidth={true}
                multiline
              />
            </Grid>
          )}
        <Grid item xs={6}>
          <InputNumber
            label={this.props.labels.residual_value}
            id={this.props.id + "_residual"}
            defaultValue={this.props.productValues.residual}
            changeHandler={this.handleChange}
            required={true}
            tooltip={this.props.labels.tooltip_total_residual_value}
            endAdornment="%"
            min={0}
            max={this.props.params.lang.toLowerCase() === "uk" ? 10 : 20}
          />
        </Grid>
        {this.props.params.lang === "de" ? (
          <Grid item xs={6}>
            <InputNumber
              label={this.props.labels.interest_value}
              id={this.props.id + "_interest"}
              defaultValue={
                this.props.variables.function_interest !== undefined
                  ? this.props.variables.function_interest
                  : 10
              }
              changeHandler={this.handleChange}
              required={true}
              endAdornment="%"
              min={1}
              max={100}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Tooltip
            title={
              <span style={{ fontSize: "12px" }}>
                {this.props.labels.tooltip_service_package}
              </span>
            }
            placement="top"
          >
            <FormControl>
              <FormLabel>{this.props.labels.service_package}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={this.props.productValues.servicePackage}
                name="radio-buttons-group"
                onChange={(e) => {
                  this.handleChange(e.target.value, "_servicePackage");
                }}
              >
                {this.props.params.lang !== "de" ? (
                  <>
                    <FormControlLabel
                      value={"none"}
                      control={<Radio />}
                      label={this.props.labels.none}
                    />
                    <FormControlLabel
                      value={"preventative"}
                      control={<Radio />}
                      label={this.props.labels.preventative}
                    />
                    <FormControlLabel
                      value={"premium"}
                      control={<Radio />}
                      label={this.props.labels.premium}
                    />
                  </>
                ) : (
                  <FormControlLabel
                    value={"premium"}
                    control={<Radio />}
                    checked={true}
                    label={this.props.labels.premium}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
}
