import React from "react";
import TextBox from "../Fields/TextBox";
import "./css/FormRes.css";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

export default class Business extends React.Component {
  static propTypes = {
    labels: PropTypes.object,
    customerInformation: PropTypes.object,
    params: PropTypes.object,
    dateToString: PropTypes.func,
    orderChecked: PropTypes.bool,
  };

  render() {
    const size = this.props.params.comp === "arjo" ? 12 / 5 : 3;
    return (
      <>
        <Grid container spacing={2} className="CustomerInformation formPadding">
          <Grid item xs={4}>
            <TextBox
              title={this.props.labels.company}
              content={this.props.customerInformation.customerName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextBox
              title={this.props.labels.company_number}
              content={this.props.customerInformation.companyNumber}
            />
          </Grid>
          <Grid item xs={4}>
            <TextBox
              title={this.props.labels.label_invoice_email}
              content={this.props.customerInformation.customerEmail}
            />
          </Grid>
          {(this.props.params.lang.toLowerCase() === "at" ||
            this.props.params.lang.toLowerCase() === "de") && (
            <>
              <Grid item xs={4}>
                <TextBox
                  title={this.props.labels.label_address}
                  content={this.props.customerInformation.address}
                />
              </Grid>
              <Grid item xs={4}>
                <TextBox
                  title={this.props.labels.label_city}
                  content={this.props.customerInformation.city}
                />
              </Grid>
              <Grid item xs={4}>
                <TextBox
                  title={this.props.labels.label_zip_code}
                  content={this.props.customerInformation.zipCode}
                />
              </Grid>
            </>
          )}
        </Grid>
        {this.props.orderChecked &&
          Object.keys(this.props.customerInformation.signatories).map((id) => {
            return (
              <Grid
                container
                spacing={2}
                key={id}
                className="CustomerInformation formPadding"
              >
                <Grid item xs={size}>
                  <TextBox
                    title={this.props.labels.label_signatory_name}
                    content={
                      this.props.customerInformation.signatories[id]
                        .nameSignatory
                    }
                  />
                </Grid>
                {this.props.params.comp.toLowerCase() === "arjo" && (
                  <Grid item xs={size}>
                    <TextBox
                      title={this.props.labels.label_signatory_position}
                      content={
                        this.props.customerInformation.signatories[id]
                          .positionSignatory
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={size}>
                  <TextBox
                    title={this.props.labels.label_signatory_email}
                    content={
                      this.props.customerInformation.signatories[id]
                        .emailSignatory
                    }
                  />
                </Grid>
                <Grid item xs={size}>
                  <TextBox
                    title={this.props.labels.label_signatory_phone}
                    content={
                      this.props.customerInformation.signatories[id]
                        .phoneSignatory
                    }
                  />
                </Grid>
                {this.props.customerInformation.signatories[id]
                  .dateOfBirthCustomerSignatory === null ? (
                  <>
                    <Grid item xs={size}>
                      <TextBox
                        title={this.props.labels.social_security_number}
                        content={
                          this.props.customerInformation.signatories[id]
                            .socialSecurityNumberCustomerSignatory
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={size}>
                      <TextBox
                        title={this.props.labels.social_security_number}
                        content={this.props.dateToString(
                          this.props.customerInformation.signatories[id]
                            .dateOfBirthCustomerSignatory
                        )}
                      />
                    </Grid>
                  </>
                )}
                {this.props.params.lang.toLowerCase() === "at" && (
                  <>
                    <Grid item xs={4}>
                      <TextBox
                        title={this.props.labels.label_id_type}
                        content={
                          this.props.customerInformation.signatories[id]
                            .idTypeSignatory
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextBox
                        title={this.props.labels.label_id_number}
                        content={
                          this.props.customerInformation.signatories[id]
                            .idNumberSignatory
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextBox
                        title={this.props.labels.pep_label}
                        content={
                          this.props.customerInformation.signatories[id]
                            .pepSignatory
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            );
          })}
      </>
    );
  }
}
