import React from "react";
import {
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Tooltip,
} from "@mui/material";
import InputText from "./Fields/InputText";

import {
  addExcelFile,
  addImageFile,
  getFileAsExcel,
  login,
  isLoggedIn,
  logout,
  getOnboarding,
} from "../logic/ServerCalls";

import "./css/AdminMode.css";
import DisplayField from "./Fields/DisplayField";

// Allowed combinations for file download
const allowedFiles = {
  hcs: {
    countryCode: ["de", "dk", "fi", "fr", "ne", "no", "se", "uk"],
    type: [
      "Variables",
      "Labels",
      "LeasePlus",
      "UpCare",
      "FlexPro",
      "FlexPrivate",
      "Construction",
    ],
  },
  arjo: {
    countryCode: ["uk"],
    type: ["Variables", "Labels", "Standard"],
  },
};

export default class AdminMode extends React.Component {
  state = {
    company: "",
    countryCode: "",
    type: [],
    loggedIn: false,
    username: "",
    password: "",
    errorText: "",
    inputFiles: [],
    presignedUrl: "",
  };

  async componentDidMount() {
    this.checkIsLoggedIn();
  }

  /**
   * calls isLoggedIn function and checks if valid token.
   *
   * @returns boolean
   */
  checkIsLoggedIn = async () => {
    const res = await isLoggedIn();
    if (res.status === 200) {
      this.setState({ loggedIn: true });
      return true;
    } else if (res.response.status === 403) {
      this.setState({ errorText: res.response.data.error });
    }
    return false;
  };
  /**
   *
   * @param {String} value
   * @param {String} id
   *
   * Changes value of state object to value with a given id.
   *
   */
  handleChange = (value, id) => {
    let state = this.state;
    state[id] = value;
    this.setState(state);
  };

  /**
   * Sends given inputs to server and updates state with response.
   */
  login = async () => {
    let state = this.state;
    if (this.state.username !== "" && this.state.password !== "") {
      state["errorText"] = "";
      const res = await login(this.state.username, this.state.password);
      if (res?.status === 200) {
        state.loggedIn = true;
      } else {
        state["errorText"] = res.response.data.error;
      }
    } else {
      state["errorText"] = "Enter both username and password";
    }
    this.setState(state);
  };

  logout = async () => {
    await logout();
    this.setState({ loggedIn: false });
  };

  /**
   *
   * @returns undefined
   *
   * Downloads excel file from sever for each type in state.
   */
  handleClickDownload = () => {
    if (
      this.state.countryCode === "" ||
      this.state.type.lenght < 1 ||
      this.state.company === ""
    ) {
      alert("Bad request\nPlease use all the dropdowns");
      return;
    }
    this.state.type.forEach((type) => {
      getFileAsExcel(
        this.state.company.toLocaleLowerCase(),
        this.state.countryCode.toLocaleLowerCase(),
        type.toLocaleLowerCase()
      );
    });
  };

  /**
   *
   * @param {Array<File>} files
   * @returns undefined
   *
   * Adds inputed file to array in state.
   */
  handleUpload = (files) => {
    const fileNamePattern = /^[a-öA-Ö.-]+$/;
    let seq = this.state.inputFiles;

    for (let i = 0; i < files.length; i++) {
      if (!fileNamePattern.test(files[i].name)) {
        alert("Filename not recognized " + files[i].name);
        return;
      }
      let [info, extension] = files[i].name.split(".");
      const [company, countryCode, type] = info.toLowerCase().split("-");
      //console.log(company, countryCode, type);

      if (!Object.keys(allowedFiles).includes(company)) {
        alert("Unknown company " + files[i].name);
        return;
      }
      if (extension === "xls" || extension === "xlsx") {
        if (!allowedFiles[company]["countryCode"].includes(countryCode)) {
          alert("Unknown countryCode " + files[i].name);
          return;
        }
        if (
          !allowedFiles[company]["type"].some((element) => {
            //console.log(element.toLowerCase(), type);
            return element.toLowerCase() === type;
          })
        ) {
          alert("Unknown type " + files[i].name);
          return;
        }
        seq.push(files[i]);
      }
    }
    this.setState({
      inputFiles: seq,
    });
  };

  /**
   *
   * @returns undifined
   *
   * Calls Api to upload files in state array.
   */
  handleClickUpload = () => {
    if (!this.state.inputFiles.length) {
      alert("No files chosen for upload.");
      return;
    }
    for (const file of this.state.inputFiles) {
      const extension = file.name.split(".").at(-1);
      switch (extension) {
        case "jpg":
        case "png":
        case "svg":
          addImageFile(file);
          break;
        case "xlsx":
        case "xls":
          addExcelFile(file);
          break;
        default:
          throw Error("File extension not allowed");
      }
    }
  };

  /**
   *
   * @returns string
   *
   * Calls Api to get link to excel file that contains Onboarding information.
   */

  getOnboardingLink = async () => {
    const data = await getOnboarding();
    console.log(data.data.preSignedUrl);
    if (data.data?.preSignedUrl) {
      this.setState({
        presignedUrl: data.data.preSignedUrl,
      });
    }
  };

  /**
   *
   * @returns String
   *
   * Join filenames of files in state without file extention.
   */
  parseFileNames = () => {
    const fileNames = this.state.inputFiles.map(
      (file) => file.name.split(".")[0]
    );
    return fileNames !== undefined ? fileNames.join(", ") : "";
  };

  render() {
    return (
      <div>
        {this.state.loggedIn === false ? (
          <div className="login">
            <InputText
              label={"Username"}
              id={"username"}
              defaultValue={""}
              changeHandler={this.handleChange}
              debounce={0}
            />
            <br></br>
            <br></br>
            <InputText
              label={"Password"}
              id={"password"}
              defaultValue={""}
              password={true}
              changeHandler={this.handleChange}
              debounce={0}
            />
            <br></br>
            <br></br>
            <Button variant="contained" onClick={this.login}>
              Login
            </Button>

            <h5 className="errorText">{this.state.errorText}</h5>
          </div>
        ) : (
          <div>
            <div className="onboarding-container">
              <Button variant="contained" onClick={this.getOnboardingLink}>
                Get link to OnBoarding Excel
              </Button>
              {this.state.presignedUrl !== "" && (
                <a
                  href={this.state.presignedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OnBoarding File
                </a>
              )}
            </div>
            <div className="file-handler">
              <div className="args-input-wrapper">
                <FormControl>
                  <InputLabel>Company</InputLabel>
                  <Select
                    sx={{ width: "150px" }}
                    onChange={(event) => {
                      this.handleChange(event.target.value, event.target.name);
                    }}
                    value={this.state.company}
                    name="company"
                    label="Company"
                  >
                    <MenuItem value={"hcs"}>HCS</MenuItem>
                    <MenuItem value={"arjo"}>Arjo</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>Countrycode</InputLabel>
                  <Select
                    sx={{ width: "150px" }}
                    onChange={(event) => {
                      this.handleChange(event.target.value, event.target.name);
                    }}
                    value={this.state.countryCode}
                    name="countryCode"
                    label="Countrycode"
                  >
                    {allowedFiles[this.state.company]?.["countryCode"].map(
                      (countryCode) => {
                        return (
                          <MenuItem key={countryCode} value={countryCode}>
                            {countryCode}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>Type</InputLabel>
                  <Select
                    sx={{ width: "150px" }}
                    onChange={(event) => {
                      this.handleChange(event.target.value, event.target.name);
                    }}
                    value={this.state.type}
                    name="type"
                    label="Type"
                    multiple
                  >
                    {allowedFiles[this.state.company]?.["type"].map((type) => {
                      return (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <div>
                  <Button onClick={this.handleClickDownload}>Download</Button>
                </div>
              </div>
              <div>
                <Tooltip
                  title={
                    "Add either an Excel file or an Image file to the server.\nExcel files must have the format company-countryCode-type.\nImage files must have the format company-imageName.\nRecommendation is to download the latest verision of the file when changing a file."
                  }
                >
                  <div id="add-file">
                    <Input
                      type="file"
                      onChange={(event) => {
                        this.handleUpload(event.target.files);
                      }}
                      disableUnderline
                      inputProps={{
                        multiple: true,
                        accept:
                          "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpg, image/png",
                      }}
                    />
                    <Button onClick={this.handleClickUpload}>Upload</Button>
                    <DisplayField value={this.parseFileNames()} />
                  </div>
                </Tooltip>
              </div>
              <Button variant="contained" onClick={this.logout}>
                logout
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
