import React from "react";
import PropTypes from "prop-types";
import config from "../../res/config.json";
import { cssStyles } from "./pdfContractStyles";
import { formatNumber } from "../../logic/GeneralFunctions";

const emptyLabel = " ";
let objektNr = 0;

export default class CreateContract extends React.Component {
  static propTypes = {
    formInformation: PropTypes.object,
    productsValues: PropTypes.object,
    customerInformation: PropTypes.object,
    labels: PropTypes.object,
    accessories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    params: PropTypes.object,
    switchFormState: PropTypes.func,
    products: PropTypes.array,
    nrOfAccessories: PropTypes.number,
  };

  state = {
    headerImgSrc: "",
  };

  generateProducts = (key, objektNr) => {
    return (
      <>
        <tr className="contract-table-header">
          <th colSpan="4" className="contract-small-margin-top">
            {"Objekt #" + objektNr}
          </th>
        </tr>
        <tr>
          <td className="contract-td">
            <p className="contract-helper-text">
              {this.props.labels.object_type}
            </p>
            <p>{this.props.productsValues[key].name}</p>
          </td>
          <td className="contract-td">
            <p className="contract-helper-text">
              {this.props.labels.serial_numbers}
            </p>
            <p>
              {this.props.productsValues[key].serial.length > 0
                ? this.props.productsValues[key].serial[0]
                : ""}
            </p>
          </td>
          <td className="contract-td">
            <p className="contract-helper-text">
              {this.props.labels.product_fee} {this.props.labels.inc_vat}
            </p>
            <p>
              {formatNumber(
                this.props.productsValues[key].price,
                this.props.params.lang
              )}{" "}
              {this.props.labels.currency}
            </p>
          </td>
          <td className="contract-td">
            <p className="contract-helper-text">
              {this.props.labels.number_of_months}
            </p>
            <p>{this.props.formInformation.contractLength}</p>
          </td>
        </tr>
        <tr className="contract-table-header">
          <th colSpan={2} className="contract-half-space">
            <p className="contract-small-margin-top">
              {this.props.labels.lessor_obligations}
            </p>
          </th>
          <th
            colSpan={2}
            className="contract-half-space contract-small-margin-top"
          >
            <p className="contract-small-margin-top">
              {this.props.labels.insurance_responsability}
            </p>
          </th>
        </tr>
        <tr>
          <td colSpan={2} className="contract-td contract-half-space">
            <p className="contract-helper-text">{this.props.labels.service}</p>
            <p>{this.props.labels.positive_answer}</p>
          </td>
          <td colSpan={2} className="contract-td contract-half-space">
            <p>{this.props.labels.lessor}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p></p>
          </td>
        </tr>
      </>
    );
  };

  generateAccessories = (key, accessoryId, objektNr) => {
    return (
      <>
        <tr className="contract-table-header">
          <th colSpan="4" className="contract-small-margin-top">
            {"Objekt #" + objektNr}
          </th>
        </tr>
        <tr>
          <td className="contract-td">
            <p className="contract-helper-text">
              {this.props.labels.object_type}
            </p>
            <p>{this.props.productsValues[key].accessory[accessoryId]}</p>
          </td>
          <td className="contract-td">
            <p className="contract-helper-text">
              {this.props.labels.serial_numbers}
            </p>
            <p>
              {this.props.productsValues[key].serial.length > 1
                ? this.props.productsValues[key].serial[1]
                : ""}
            </p>
          </td>
          <td className="contract-td">
            <p className="contract-helper-text">
              {this.props.labels.product_fee} {this.props.labels.inc_vat}
            </p>
            <p>
              {formatNumber(
                this.props.productsValues[key].accessoryPrice[accessoryId],
                this.props.params.lang
              )}
              {this.props.labels.currency}
            </p>
          </td>
          <td className="contract-td">
            <p className="contract-helper-text">
              {this.props.labels.number_of_months}
            </p>
            <p>{this.props.formInformation.contractLength}</p>
          </td>
        </tr>
        <tr className="contract-table-header">
          <th colSpan={2} className="contract-half-space">
            <p className="contract-small-margin-top">
              {this.props.labels.lessor_obligations}
            </p>
          </th>
          <th
            colSpan={2}
            className="contract-half-space contract-small-margin-top"
          >
            <p className="contract-small-margin-top">
              {this.props.labels.insurance_responsability}
            </p>
          </th>
        </tr>
        <tr>
          <td colSpan={2} className="contract-td contract-half-space">
            <p className="contract-helper-text">{this.props.labels.service}</p>
            <p>{this.props.labels.if_applicable}</p>
          </td>
          <td colSpan={2} className="contract-td contract-half-space">
            <p>{this.props.labels.lessor}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p></p>
          </td>
        </tr>
      </>
    );
  };

  render() {
    return (
      <div className="wrapper">
        <style>{cssStyles}</style>
        <div id="contractPdf" className="contract-body">
          <div className="contract-header">
            <img
              src={
                config.ROOT +
                `getimage?company=hcs&country=images&type=logo_flat.png`
              }
              alt=""
              width="40%"
            ></img>
            <div>
              <h4 className="contract-table-header">
                {this.props.labels.contract_agreement.toUpperCase()}
              </h4>
              <table className="contract-table">
                <tr>
                  <td className="contract-td">
                    <p>{this.props.labels.contract_number}</p>
                  </td>
                  <td className="contract-td contract-min-width">
                    <p>{}</p>
                  </td>
                </tr>
                <tr>
                  <td className="contract-td">
                    <p>{this.props.labels.dealer_ref_nr}</p>
                  </td>
                  <td className="contract-td contract-min-width">
                    <p>{this.props.customerInformation.dealer_number}</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="contract-parties">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="4">{this.props.labels.parties.toUpperCase()}</th>
              </tr>
              <tr>
                <td colSpan="2">{this.props.labels.lessor}</td>
                <td colSpan="2">{this.props.labels.lessee}</td>
              </tr>
              <tr>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_customer_name}
                  </p>
                  <p>{this.props.labels.hcs_name}</p>
                </td>
                <td
                  colSpan="2"
                  className="contract-td contract-half-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.label_customer_name}
                  </p>
                  <p>
                    {this.props.customerInformation.customerFirstName +
                      " " +
                      this.props.customerInformation.customerLastName}
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.org_number}
                  </p>
                  <p>{this.props.labels.hcs_org_nr}</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.postal_adress}
                  </p>
                  <p>{this.props.labels.hcs_adress}</p>
                </td>
                <td
                  colSpan="1"
                  className="contract-td contract-quater-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.social_security_number}
                  </p>
                  <p>{this.props.customerInformation.socialSecurityNumber}</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.postal_adress}
                  </p>
                  <p>
                    {this.props.customerInformation.customerStreet +
                      " " +
                      this.props.customerInformation.customerHouseNumber}
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.zip_code}
                  </p>
                  <p>{this.props.labels.hcs_zip}</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_city}
                  </p>
                  <p>{this.props.labels.hcs_city}</p>
                </td>
                <td
                  colSpan="1"
                  className="contract-td contract-quater-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.zip_code}
                  </p>
                  <p>{this.props.customerInformation.customerZipCode}</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_city}
                  </p>
                  <p>{this.props.customerInformation.customerCity}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.contact_person}
                  </p>
                  <p>{emptyLabel}</p>
                </td>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.phone_number}
                  </p>
                  <p>{emptyLabel}</p>
                </td>
                <td
                  colSpan="2"
                  className="contract-td contract-half-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.phone_number}
                  </p>
                  <p>{this.props.customerInformation.customerPhone}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_email}
                  </p>
                  <p>{this.props.labels.hcs_email}</p>
                </td>
                <td
                  colSpan="2"
                  className="contract-td contract-half-space contract-border-thick"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.label_email}
                  </p>
                  <p>{this.props.customerInformation.customerEmail}</p>
                </td>
              </tr>
            </table>
            {this.props.params.lang === "dk" && (
              <>
                <h5 className="contract-small-margin-top">
                  <strong>LEASINGTAGERS LOVBESTEMTE OPSIGELSESDGANG</strong>
                </h5>
                <p className="contract-no-margin-top">
                  Leasingtager er berettiget til at opsige denne Aftale med 1
                  måneds varsel til udgangen af en måned, når der er gået
                  [5]/[11] måneder efter indgåelsen af denne Aftale.
                </p>
              </>
            )}
          </div>
          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="3">{this.props.labels.contact_dealer}</th>
              </tr>
              <tr>
                <td colSpan="1" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_customer_name}
                  </p>
                  <p>{this.props.customerInformation.dealerName}</p>
                </td>
                <td colSpan="1" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.phone_number}
                  </p>
                  <p>{this.props.customerInformation.dealerPhone}</p>
                </td>
                <td colSpan="1" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_email}
                  </p>
                  <p>{this.props.customerInformation.dealerEmail}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.postal_adress}
                  </p>
                  <p>{this.props.customerInformation.dealerAdress}</p>
                </td>
                <td colSpan="1" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.zip_code}
                  </p>
                  <p>{this.props.customerInformation.dealerZipCode}</p>
                </td>
                <td colSpan="1" className="contract-td contract-third-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_city}
                  </p>
                  <p>{this.props.customerInformation.dealerCity}</p>
                </td>
              </tr>
            </table>
          </div>
          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="4">
                  {this.props.labels.leasing_fee.toUpperCase()}
                </th>
              </tr>
              <tr>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.monthly_fee_currency}{" "}
                    {this.props.labels.inc_vat}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.formInformation.totalMonthlyFee,
                      this.props.params.lang
                    )}
                  </p>
                </td>
                <td colSpan="2" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.number_of_months}
                  </p>
                  <p>{this.props.formInformation.contractLength}</p>
                </td>
              </tr>
            </table>
          </div>
          <p>{this.props.labels.appendix_info}</p>
          <p>{this.props.labels.signature_info}</p>
          <div className="margin-top-large">
            <table className="contract-table">
              <tr className="contract-table-header">
                <th colSpan="4">
                  {this.props.labels.signatures.toUpperCase()}
                </th>
              </tr>
              <tr>
                <td colSpan="4">{this.props.labels.lessee}</td>
              </tr>
              <tr>
                <td colSpan="4" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.signature}
                  </p>
                  <p>{emptyLabel}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="contract-td contract-half-space">
                  <p className="contract-helper-text">
                    {this.props.labels.label_customer_name}
                  </p>
                  <p>{emptyLabel}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="contract-td contract-quater-space">
                  <p className="contract-helper-text">
                    {this.props.labels.date}
                  </p>
                  <p>{emptyLabel}</p>
                </td>
                <td
                  colSpan="3"
                  className="contract-td contract-three-quater-space"
                >
                  <p className="contract-helper-text">
                    {this.props.labels.label_city}
                  </p>
                  <p>{emptyLabel}</p>
                </td>
              </tr>
            </table>
          </div>
          <div className="page-break"></div>
          <div className="contract-header">
            <img
              src={
                config.ROOT +
                `getimage?company=hcs&country=images&type=logo_flat.png`
              }
              alt=""
              width="40%"
            ></img>
            <div>
              <h4 className="contract-table-header">
                {this.props.labels.contract_agreement.toUpperCase()}
              </h4>
              <table className="contract-table">
                <tr>
                  <td className="contract-td">
                    <p>{this.props.labels.contract_number}</p>
                  </td>
                  <td className="contract-td contract-min-width">
                    <p>{}</p>
                  </td>
                </tr>
                <tr>
                  <td className="contract-td">
                    <p>{this.props.labels.dealer_ref_nr}</p>
                  </td>
                  <td className="contract-td contract-min-width">
                    <p>{this.props.customerInformation.dealer_number}</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <table className="contract-table margin-top-large">
              <tr className="contract-table-header">
                <th>{this.props.labels.label_main_products.toUpperCase()}</th>
              </tr>
              {Object.keys(this.props.productsValues).map((id) => {
                if (this.props.productsValues[id].name !== null) {
                  objektNr = objektNr + 1;
                  return this.generateProducts(id, objektNr);
                }
                return <></>;
              })}
            </table>
          </div>
          {this.props.nrOfAccessories > 0 && (
            <div>
              <table className="contract-table margin-top-small">
                <tr className="contract-table-header">
                  <th colSpan="8">
                    {this.props.labels.accessory_products.toUpperCase()}
                  </th>
                </tr>
                <tbody>
                  {Object.keys(this.props.productsValues).map((id) => {
                    let rows = [];
                    for (const accessoryId in this.props.productsValues[id]
                      .accessory) {
                      const accessory =
                        this.props.productsValues[id].accessory[accessoryId];
                      if (accessory !== null) {
                        objektNr = objektNr + 1;
                        rows.push(
                          this.generateAccessories(id, accessoryId, objektNr)
                        );
                      }
                    }
                    return rows;
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}
