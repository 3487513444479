import React from "react";

import ArjoStandard from "./Forms/Arjo";
import Information from "../component/Information";

import Accessories from "../component/Accessories";

import { calculateTotalFee } from "../logic/Calculations";
import { useParams } from "react-router-dom";
import DisplayField from "../component/Fields/DisplayField";
import debounce from "lodash.debounce";

import FormRes from "../component/FormRes/FormRes";
import { getCountryInformation } from "../logic/ServerCalls";

import {
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import CustomerInformationBusiness from "../component/Information/Customer/Business";
import CustomerInformationIdentification from "../component/Information/Customer/Identification";
import QuoteCustomerInformation from "../component/Information/Customer/QuoteInformation";
import DealerInformation from "../component/Information/Dealer/DealerInformation";

import NotFound from "../component/NotFound";
import configSource from "../res/config.json";
import config from "./res/config.json";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import "./css/Arjo.css";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Arjo extends React.Component {
  state = {
    labels: {},
    variables: {},
    products: {},
    headerText: "",
    params: {},
    productsValues: {
      product1: {
        deliveryDate: null,
        name: null,
        quantity: 1,
        price: 0,
        service: 0,
        residual: 0,
        totalProductPrice: 0,
        finished: false,
        servicePackage: "none",
        recomendedPrice: 0,
        recomendedServicePrices: [],
        transferPrice: 0,
        productType: "Medical Beds",
      },
    },
    accessories: {
      accessories1: {
        AccessoriesName: "Installation",
        quantity: 1,
      },
      accessories2: {
        AccessoriesName: "Delivery",
        quantity: 1,
      },
      accessories3: {
        AccessoriesName: "Disposal of old equipment",
        quantity: 1,
      },
    },
    customerInformation: {
      businessFinished: false,
      identificationFinished: false,
      dealerFinished: false,
      dealerName: "",
      dealerEmail: "",
      dateOfBirth: null,
      socialSecurityNumber: "",
      address: "",
      signatories: {
        signatory1: {
          nameSignatory: "",
          positionSignatory: "",
          emailSignatory: "",
          phoneSignatory: "",
          socialSecurityNumberCustomerSignatory: "",
          dateOfBirthCustomerSignatory: null,
        },
      },
    },
    formInformation: {
      totalMonthlyFee: 0,
      totalPrice: 0,
      totalResidue: 0,
      totalAccessoriesPrice: 0,
      amortization: 0,
      formState: "edit",
      showInvalid: false,
      quoteChecked: false,
      orderChecked: false,
      idChecked: false,
      contractLength: 36,
      contractLengths: [36],
      customer: "Private",
      leasingType: "Operational lease",
      useCampaign: false,
      extraInfo: "",
      allContractLengths: false,
    },
    campaign: false,
    extraFiles: [],
    firstRender: true,
  };

  /**
   *  Gets called after first render.
   */
  componentDidMount() {
    if (this.validateParams()) {
      this.setState({
        firstRender: false,
        params: { ...this.props.params, comp: "arjo" },
      });
      this.SetFormInformation();
    }
  }

  /**
   * Requests labels, headerText, productlist and variables from server and puts them in state.
   */
  SetFormInformation = () => {
    (async () => {
      try {
        const comp = "arjo";
        const lang = this.props.params.lang.toLowerCase();
        const formType = this.props.params.formType.toLowerCase();
        let data = await getCountryInformation(comp, lang, formType);
        data = data.data;

        let state = this.state;
        if (formType === "standard") {
          state.formInformation.customer = "Business";
        } else {
          state.formInformation.customer = "Private";
        }
        const lastIndex = Math.floor(
          data["Products"]["contractLengths"].length - 1
        );
        state.labels = data["Labels"];
        state.variables = data["Variables"];
        state.products = data["Products"];
        state.headerText = data["HeaderText"];
        state.campaign = data["campaign"];
        state.formInformation.contractLengths =
          data["Products"]["contractLengths"];
        state.formInformation.contractLength =
          data["Products"]["contractLengths"][lastIndex];
        this.setState(state);
      } catch (error) {
        //TODO redirect
        console.log(error);
      }
    })();
  };

  /**
   * Passes values to function and sets state with result
   */
  newCalculationsProductFee = debounce(() => {
    const result = calculateTotalFee(
      this.state.productsValues,
      this.state.formInformation.contractLength,
      this.state.variables,
      this.state.accessories,
      this.state.params.formType.toLowerCase()
    );
    let formInformation = this.state.formInformation;
    Object.assign(formInformation, result);
    this.setState({ formInformation: formInformation });
  }, 500);

  /**
   *
   * @param {Object} value - Updated product information
   * @param {String} id    - Product id
   *
   * Updates given product with information from value
   */
  updateProduct = (value, id) => {
    let productsValues = this.state.productsValues;
    let formInformation = this.state.formInformation;
    let variables = this.state.variables;
    for (let key in value) {
      productsValues[id][key] = value[key];
    }
    if (value?.leasingType !== undefined) {
      formInformation.leasingType = value.leasingType;
      this.setState({
        formInformation: formInformation,
      });
    }
    this.setState({
      productsValues: productsValues,
    });
    if ("interest" in value) {
      variables["function_interest"] = value["interest"];
      this.setState({
        variables: variables,
      });
    }
  };

  /**
   *
   * @param {Array<File>} files
   *
   * Puts the files in the array in state.
   */
  addExtraFiles = (files) => {
    let extraFiles = this.state.extraFiles;
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      extraFiles.push(element);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * @param {File} file
   *
   * Removes given file from state if it exists.
   */
  removeExtraFiles = (file) => {
    let extraFiles = this.state.extraFiles;
    let index = extraFiles.indexOf(file);
    if (index !== -1) {
      extraFiles.splice(index, 1);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * Adds new object in productsValues with newId.
   * newId = highest oldId + 1.
   * Also populates the new product objects with deafult values.
   */
  addAnotherProduct = () => {
    const newId = Object.keys(this.state.productsValues).length + 1;

    const key = "product" + newId;

    let productsValues = this.state.productsValues;
    productsValues[key] = {};
    productsValues[key]["deliveryDate"] = null;
    productsValues[key]["name"] = null;
    productsValues[key]["quantity"] = 1;
    productsValues[key]["price"] = 0;
    productsValues[key]["service"] = 0;
    productsValues[key]["residual"] = 0;
    productsValues[key]["totalProductPrice"] = 0;
    productsValues[key]["servicePackage"] = "none";
    productsValues[key]["finished"] = false;
    productsValues[key]["recomendedPrice"] = 0;
    productsValues[key]["recomendedServicePrices"] = [];
    productsValues[key]["transferPrice"] = 0;

    this.setState({
      productsValues: productsValues,
    });
  };

  /**
   *
   * Removes the last product from state if there are more than one
   * and updates totalMonthlyFee in state.
   */
  removeProduct = () => {
    let productsValues = this.state.productsValues;
    const id = Object.keys(productsValues).at(-1);
    delete productsValues[id];
    this.setState({
      productsValues: productsValues,
    });
    this.newCalculationsProductFee();
  };

  /**
   * Adds a new Object to accessories.
   */
  addAccessories = () => {
    const key = Object.keys(this.state.accessories).length + 1;
    const id = "accessories" + key;
    let accessoreis = this.state.accessories;
    console.log(accessoreis);
    accessoreis[id] = {};
    this.setState({
      accessoreis: accessoreis,
    });
  };

  /**
   * Removes last object from accessories.
   */
  removeAccessories = () => {
    const id = Object.keys(this.state.accessories).at(-1);
    let accessoreis = this.state.accessories;
    delete accessoreis[id];
    this.setState({
      accessoreis: accessoreis,
    });
    this.newCalculationsProductFee();
  };

  /**
   * Adds a new object to signatories.
   */
  addSignatory = () => {
    const key =
      Object.keys(this.state.customerInformation.signatories).length + 1;
    const id = "signatory" + key;
    let signatories = this.state.customerInformation.signatories;
    signatories[id] = {
      nameSignatory: "",
      positionSignatory: "",
      emailSignatory: "",
      phoneSignatory: "",
      socialSecurityNumberCustomerSignatory: "",
      dateOfBirthCustomerSignatory: null,
    };
    this.setState({
      signatories: signatories,
    });
  };

  /**
   * Remove last object from signatories.
   */
  removeSignatory = () => {
    const id = Object.keys(this.state.customerInformation.signatories).at(-1);
    let signatories = this.state.customerInformation.signatories;
    delete signatories[id];
    this.setState({
      signatories: signatories,
    });
  };

  generateAccessories = (id) => {
    return (
      <Accessories
        id={id}
        key={id}
        labels={this.state.labels}
        handleAccessories={this.handleAccessories}
        accessories={this.state.accessories[id]}
        number={id.charAt(id.length - 1)}
      />
    );
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of customerInformation based on keys in info.
   */
  handleCustomerInfoChange = (info) => {
    let customerInformation = this.state.customerInformation;
    for (let id in info) {
      customerInformation[id] = info[id];
    }
    this.setState({
      customerInformation: customerInformation,
    });
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of newDealerInformation based on keys in info.
   */
  handleNewDealerInfoChange = (info) => {
    let newDealerInformation = this.state.newDealerInformation;
    for (let id in info) {
      newDealerInformation[id] = info[id];
    }
    this.setState({
      newDealerInformation: newDealerInformation,
    });
  };

  /**
   *
   * @param {Object} value
   * @param {String} id
   *
   * Updates the value of the accessory with key id with the new value.
   */
  handleAccessories = (value, id) => {
    let accessories = this.state.accessories;

    Object.entries(value).forEach(([key, val]) => {
      accessories[id][key] = val;
    });
    this.setState(accessories);
    this.newCalculationsProductFee();
  };

  handleFormInformationChange = (info) => {
    let formInformation = this.state.formInformation;
    for (let id in info) {
      formInformation[id] = info[id];
    }
    this.setState({
      formInformation: formInformation,
    });
  };

  /**
   *
   * @param {Object} event
   *
   * Handles checkbox check depending on case.
   */
  handleChecked = (event) => {
    let newState;
    let newCustomerState;
    let formInformation = this.state.formInformation;
    switch (event.target.id) {
      case "quote_check":
        newState = this.state.formInformation.quoteChecked ? false : true;
        if (newState && this.state.formInformation.orderChecked) {
          formInformation.orderChecked = false;
        }
        formInformation.quoteChecked = newState;
        this.setState({ formInformation: formInformation });
        break;
      case "order_check":
        newCustomerState = this.state.customerInformation;
        newState = this.state.formInformation.orderChecked ? false : true;
        if (!newState) {
          formInformation.idChecked = false;
        }
        if (newState && this.state.formInformation.quoteChecked) {
          formInformation.quoteChecked = false;
        }
        formInformation.orderChecked = newState;
        this.setState({
          formInformation: formInformation,
          customerInformation: newCustomerState,
        });
        break;
      case "id_checked":
        newState = this.state.formInformation.idChecked ? false : true;
        formInformation.idChecked = newState;
        this.setState({ formInformation: formInformation });
        break;
      case "all_contract_lengths":
        newState = this.state.formInformation.allContractLengths ? false : true;
        formInformation.allContractLengths = newState;
        this.setState({ formInformation: formInformation });
        break;
      default:
        throw Error("Unknown button ID");
    }
  };

  /**
   *
   * @param {String} value
   *
   * Switches the current formState to new value.
   */
  switchFormState = (value) => {
    let formInformation = this.state.formInformation;
    formInformation.formState = value;
    this.setState(formInformation);
  };

  /**
   *
   * @param {Object} event
   *
   * Updates the contractLenght in state to new value, calls for update of totalMonthlyFee.
   */
  changeContractLength = (event) => {
    let state = this.state;
    state.formInformation.contractLength = event.target.value;
    this.setState(state);
    this.newCalculationsProductFee();
  };

  showInvalid = (newValue = true) => {
    let newFormInformation = this.state.formInformation;
    newFormInformation.showInvalid = newValue;
    this.setState({
      formInformation: newFormInformation,
    });
  };

  /**
   *
   * @returns Boolean
   */
  validateFormInputs = () => {
    if (configSource["DEBUG"]) return true;
    if (this.state.formInformation.totalMonthlyFee < 0.1) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    if (
      this.state.formInformation.orderChecked &&
      !(
        (this.state.customerInformation.businessFinished ||
          this.state.customerInformation.privateFinished) &&
        this.state.customerInformation.identificationFinished &&
        this.state.customerInformation.dealerFinished
      )
    ) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    if (
      this.state.formInformation.quoteChecked &&
      !(
        this.state.customerInformation.quoteInformationFinished &&
        this.state.customerInformation.dealerFinished
      )
    ) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    for (let product in this.state.productsValues) {
      if (
        this.state.productsValues[product]["finished"] === undefined ||
        this.state.productsValues[product]["finished"] === false
      ) {
        this.showInvalid();
        alert("All required fields are not filled in");
        return false;
      }
    }
    this.showInvalid(false);
    return true;
  };

  /**
   *
   * @returns Boolean
   *
   * Check the params to validate that the form requested exists.
   */
  validateParams = () => {
    const allowedArgs = config.ALLOWED_ARGS;
    if (
      Object.keys(allowedArgs).includes("arjo") &&
      Object.keys(allowedArgs["arjo"]).includes(
        this.props.params.lang.toLowerCase()
      ) &&
      allowedArgs["arjo"][this.props.params.lang.toLowerCase()].includes(
        this.props.params.formType.toLowerCase()
      )
    ) {
      return true;
    }
    return false;
  };

  /**
   *
   * @param {string} id
   * @returns ReactElement || null
   *
   * Creates a ReactElement depending on formType and returns it.
   * If unknown formType return null.
   */

  generateProduct = (id) => {
    const standardProps = {
      key: id,
      id: id,
      number: id.charAt(id.length - 1),
      productValues: this.state.productsValues[id],
      formInformation: this.state.formInformation,
      labels: this.state?.labels,
      variables: this.state?.variables,
      params: this.state.params,
      updateProduct: this.updateProduct,
    };
    switch (this.state.params.formType.toLowerCase()) {
      case "standard":
        const arjoStandardProps = {
          products:
            this.state.products[this.state.productsValues[id].productType],
          productCategories: this.state.products.productCategories,
          calculateNewFee: this.newCalculationsProductFee,
        };
        return React.createElement(
          ArjoStandard,
          Object.assign(standardProps, arjoStandardProps)
        );
      default:
        return null;
    }
  };

  render() {
    if (!this.validateParams()) return <NotFound />;
    if (this.state.firstRender) return;

    const disabled = !(
      (this.state.formInformation.quoteChecked &&
        !this.state.formInformation.orderChecked) ||
      (this.state.formInformation.orderChecked &&
        this.state.formInformation.idChecked) ||
      configSource["DEBUG"]
    );

    const showAllcontractLengths =
      this.state.params.lang.toLowerCase() === "uk";
    const style_campaign = {
      color: "#003366",
      fontSize: "25px",
    };
    const style_campaign_extra = {
      color: "#003366",
      fontSize: "18px",
    };
    let showQuote = true;
    return (
      <Stack className="arjo-form-wrapper" alignItems="stretch">
        <div className="arjo-header">
          <div className="arjo-header-img">
            <img
              src={
                configSource.ROOT +
                "getimage?imageName=" +
                this.state.params.formType.toLowerCase() +
                ".jpg&company=arjo"
              }
              alt=""
              style={{ width: "300px" }}
            ></img>
          </div>
          <div className="arjo-vertical-line"></div>
          <div
            className="header-text"
            dangerouslySetInnerHTML={{
              __html: this.state.headerText?.text,
            }}
          />
        </div>
        {this.state.formInformation.formState === "edit" && (
          <div>
            <div>
              <strong>
                <span style={style_campaign}>
                  {this.state.headerText?.campaign}
                </span>
              </strong>
            </div>
            <div>
              <strong>
                <span style={style_campaign_extra}>
                  {this.state.headerText?.campaign_extra}
                </span>
              </strong>
            </div>
            {this.state.labels.arjo_information !== "" &&
              this.state.labels?.arjo_information !== undefined && (
                <div>
                  <Information
                    labels={this.state.labels}
                    changeHandler={this.handleFormInformationChange}
                  ></Information>
                </div>
              )}
            <div className="arjo-padding-top-small">
              <Tooltip title={this.state.labels.tooltip_upload_formdata}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    minWidth: "400px",
                    minHeight: "36.5px",
                    float: "right",
                    backgroundColor: "#0F5376",
                  }}
                >
                  <UploadIcon sx={{ marginLeft: "350px" }} />
                  <span className="arjo-app-span-text">
                    {this.state.labels.upload_formdata}
                  </span>
                  <input
                    type="file"
                    accept=".json"
                    hidden
                    onChange={(event) => {
                      var reader = new FileReader();
                      reader.addEventListener(
                        "load",
                        () => {
                          //jumps here when its done with 'readAsText'
                          let parsed = JSON.parse(reader.result);
                          Object.keys(parsed.productsValues).forEach((key) => {
                            if (
                              parsed.productsValues[key].deliveryDate !== null
                            ) {
                              parsed.productsValues[key].deliveryDate =
                                new Date(
                                  parsed.productsValues[key].deliveryDate
                                );
                            }
                          });
                          if (parsed.customerInformation.dateOfBirth !== null) {
                            parsed.customerInformation.dateOfBirth = new Date(
                              parsed.customerInformation.dateOfBirth
                            );
                          }
                          if (
                            parsed.formInformation.useCampaign &&
                            !this.state.campaign
                          ) {
                            parsed.formInformation.useCampaign = false;
                          }
                          this.setState(parsed);
                        },
                        false
                      );
                      reader.readAsText(event.target.files[0]);
                    }}
                  />
                </Button>
              </Tooltip>
            </div>
            <div className="arjo-titles-wrapp">
              <h1 className="arjo-titles">{this.state.labels.main_products}</h1>
              <hr />
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel id="contractLength-label">
                  {this.state.labels.contract_length}
                </InputLabel>
                <Select
                  labelId="contractLength-label"
                  id="contractLength-select"
                  value={this.state.formInformation.contractLength}
                  label={this.state.labels.contract_length}
                  onChange={this.changeContractLength}
                >
                  {this.state.formInformation.contractLengths.map((length) => {
                    return (
                      <MenuItem key={length} value={length}>
                        {length.toString() + " " + this.state.labels.months}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {Object.keys(this.state.productsValues).map((id) => {
              return this.generateProduct(id);
            })}
            <div className="arjo-padding-top row ">
              <Tooltip title={this.state.labels.tooltip_add_product || ""}>
                <Button
                  onClick={() => {
                    this.addAnotherProduct();
                  }}
                  variant="contained"
                  sx={{
                    backgroundColor: "#0F5376",
                  }}
                >
                  {"+"}
                </Button>
              </Tooltip>
              {Object.keys(this.state.productsValues).length > 1 && (
                <div className="arjo-padding-left">
                  <Tooltip
                    title={this.state.labels.tooltip_remove_product || ""}
                  >
                    <Button
                      onClick={() => {
                        this.removeProduct();
                      }}
                      color="error"
                      variant="contained"
                    >
                      {"-"}
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="arjo-padding-top">
              <div className="arjo-titles-wrapp">
                <h1 className="arjo-titles">
                  {this.state.labels.additional_cost_title}
                </h1>
                <hr />
              </div>
              <div className="arjo-accessories arjo-padding-bot">
                {Object.keys(this.state.accessories).map((id) => {
                  return this.generateAccessories(id);
                })}
              </div>
              <div className="row">
                <Tooltip title={this.state.labels.tooltip_add_accessory || ""}>
                  <Button
                    onClick={() => {
                      this.addAccessories();
                    }}
                    variant="contained"
                    sx={{
                      backgroundColor: "#0F5376",
                    }}
                  >
                    {"+"}
                  </Button>
                </Tooltip>
                {Object.keys(this.state.accessories).length > 0 && (
                  <div className="arjo-padding-left">
                    <Tooltip
                      title={this.state.labels.tooltip_remove_accessory || ""}
                    >
                      <Button
                        onClick={() => {
                          this.removeAccessories();
                        }}
                        variant="contained"
                        color="error"
                      >
                        {"-"}
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            <div className="arjo-padding-top">
              <hr />
              <Tooltip
                title={
                  <span style={{ fontSize: "12px" }}>
                    {this.state.labels.tooltip_order_quote || ""}
                  </span>
                }
                placement="top"
              >
                <FormGroup sx={{ width: "30%" }}>
                  {showQuote && (
                    <FormControlLabel
                      className="arjo-quote_order"
                      control={
                        <Checkbox
                          id="quote_check"
                          checked={this.state.formInformation.quoteChecked}
                          onChange={this.handleChecked}
                        />
                      }
                      label={this.state.labels.quote}
                    />
                  )}
                  <FormControlLabel
                    className="arjo-quote_order"
                    control={
                      <Checkbox
                        id="order_check"
                        checked={this.state.formInformation.orderChecked}
                        onChange={this.handleChecked}
                      />
                    }
                    label={this.state.labels.order}
                  />
                </FormGroup>
              </Tooltip>
              <hr />
            </div>
            {this.state.formInformation.orderChecked && (
              <div className="padding-top">
                <div className="titles-wrapp">
                  <h1 className="titles">
                    {this.state.labels.customer_information_title}
                  </h1>
                  <hr />
                </div>
                <CustomerInformationBusiness
                  changeHandler={this.handleCustomerInfoChange}
                  customerInformation={this.state.customerInformation}
                  formInformation={this.state.formInformation}
                  labels={this.state?.labels}
                  addSignatory={this.addSignatory}
                  removeSignatory={this.removeSignatory}
                  formType={this.state.params.formType.toLowerCase()}
                  params={this.state.params}
                />
                <div className="arjo-padding-top">
                  <CustomerInformationIdentification
                    customerInformation={this.state.customerInformation}
                    formInformation={this.state.formInformation}
                    labels={this.state?.labels}
                    params={this.state.params}
                    extraFiles={this.state.extraFiles}
                    idSelectHandler={this.handleIdSelect}
                    pepSelectHandler={this.handlePepSelect}
                    addExtraFiles={this.addExtraFiles}
                    changeHandler={this.handleCustomerInfoChange}
                    removeExtraFiles={this.removeExtraFiles}
                    checkHandler={this.handleChecked}
                    formType={this.state.params.formType.toLowerCase()}
                    productsValues={this.state.productsValues}
                  />
                </div>
              </div>
            )}
            {this.state.formInformation.quoteChecked && (
              <div className="padding-top">
                <div className="titles-wrapp">
                  <h1 className="titles">
                    {this.state.labels.customer_information_title}
                  </h1>
                  <hr />
                </div>
                <div>
                  <QuoteCustomerInformation
                    changeHandler={this.handleCustomerInfoChange}
                    customerInformation={this.state.customerInformation}
                    formInformation={this.state.formInformation}
                    labels={this.state?.labels}
                    params={this.state.params}
                    customerType={this.state.formInformation.customer}
                  />
                </div>
              </div>
            )}
            {this.state.formInformation.quoteChecked ||
            this.state.formInformation.orderChecked ? (
              <div className="arjo-padding-top">
                <div className="arjo-titles-wrapp">
                  <h1 className="arjo-titles">
                    {this.state.labels.dealer_information}
                  </h1>
                  <hr />
                </div>
                <DealerInformation
                  changeHandler={this.handleCustomerInfoChange}
                  customerInformation={this.state.customerInformation}
                  formInformation={this.state.formInformation}
                  labels={this.state?.labels}
                  params={this.state.params}
                  checkHandler={this.handleChecked}
                />
              </div>
            ) : null}
            <div className="padding-top totalValues">
              <DisplayField
                id="totalPrice"
                value={(
                  this.state.formInformation.totalPrice -
                  this.state.formInformation.totalAccessoriesPrice
                ).toString()}
                label={this.state.labels.total_product_price}
                currency={this.state.labels.currency}
                sx={{
                  width: "33%",
                }}
              />
              <DisplayField
                id="totalAccessoriesPrice"
                value={this.state.formInformation.totalAccessoriesPrice.toString()}
                label={
                  this.state.labels.label_total_price +
                  " " +
                  this.state.labels.additional_cost_title
                }
                currency={this.state.labels.currency}
                sx={{
                  width: "33%",
                }}
              />
              <DisplayField
                id="totalResidualPrice"
                value={this.state.formInformation.totalResidue.toString()}
                label={this.state.labels.total_residual_value}
                currency={this.state.labels.currency}
                sx={{
                  width: "33%",
                }}
              />
            </div>
            <div className="arjo-padding-top arjo-padding-bot">
              <DisplayField
                id="totalMonthlyFee"
                value={this.state.formInformation.totalMonthlyFee.toString()}
                label={this.state.labels.total_monthly_fee}
                currency={this.state.labels.currency}
              />
            </div>
            {this.state.formInformation.quoteChecked &&
              showAllcontractLengths && (
                <FormGroup sx={{ width: "50%" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="all_contract_lengths"
                        checked={this.state.formInformation.allContractLengths}
                        onChange={this.handleChecked}
                      />
                    }
                    label={
                      <Typography fontWeight="bold">
                        {this.state.labels.quote_all}
                      </Typography>
                    }
                  />
                </FormGroup>
              )}
            <div className="arjo-campaign">
              {this.state.campaign && (
                <>
                  <TextField
                    id={this.state.labels.campaign_description}
                    defaultValue={this.state.labels.campaign_description}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="standard"
                    fullWidth={true}
                  />
                  <FormControlLabel
                    className="arjo-campaign"
                    control={
                      <Checkbox
                        id="campaign"
                        checked={this.state.formInformation.useCampaign}
                        onChange={() => {
                          let state = this.state;
                          state.formInformation.useCampaign =
                            !state.formInformation.useCampaign;
                          this.setState(state);
                        }}
                      />
                    }
                    label={this.state.labels.campaign_checkbox}
                  />
                </>
              )}
            </div>
            <div className="arjo-button-container">
              <div className="arjo-button-container-row">
                <Tooltip title={this.state.labels.tooltip_download_formdata}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      minWidth: "415px",
                      minHeight: "45px",
                      backgroundColor: "#0F5376",
                    }}
                    onClick={() => {
                      const content = {
                        productsValues: this.state.productsValues,
                        accessories: this.state.accessories,
                        customerInformation: this.state.customerInformation,
                        formInformation: this.state.formInformation,
                      };

                      const filename = `arjo-${this.props.params.lang.toLowerCase()}-${this.props.params.formType.toLowerCase()}.json`;

                      const a = document.createElement("a");
                      const file = new Blob([JSON.stringify(content)], {
                        type: "json",
                      });
                      a.href = URL.createObjectURL(file);
                      a.download = filename;
                      a.click();

                      URL.revokeObjectURL(a.href);
                    }}
                  >
                    <DownloadIcon sx={{ marginLeft: "380px" }} />
                    <span className="arjo-app-span-text">
                      {this.state.labels.download_formdata}
                    </span>
                  </Button>
                </Tooltip>
                <Button
                  sx={{
                    minWidth: "350px",
                    minHeight: "45px",
                    backgroundColor: "#0F5376",
                  }}
                  onClick={() => {
                    if (this.validateFormInputs()) {
                      this.switchFormState("review");
                    }
                  }}
                  variant="contained"
                  disabled={disabled}
                >
                  <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
                  <span className="arjo-app-span-text">
                    {this.state.labels.preview_form}{" "}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        )}
        {this.state.formInformation.formState === "review" && (
          <div className="arjo-padding-top">
            <FormRes
              formInformation={this.state.formInformation}
              productsValues={this.state.productsValues}
              customerInformation={this.state.customerInformation}
              labels={this.state.labels}
              accessories={this.state.accessories}
              extraFiles={this.state.extraFiles}
              params={this.state.params}
              pdf={false}
              switchFormState={this.switchFormState}
              products={this.state.products}
              productAccessories={this.state.products?.accessories}
              variables={this.state.variables}
              newDealerInformation={this.state.newDealerInformation}
              changeContractLength={this.changeContractLength}
            ></FormRes>
          </div>
        )}
        {this.state.formInformation.formState === "completed" && (
          <div className="arjo-completedText">
            <h1>{this.state.labels.thank_you}</h1>
            {this.state.formInformation.orderChecked && (
              <>
                <div>
                  <p>{this.state.labels.order_sent}</p>
                </div>
                <div className="arjo-order_info">
                  <p>{this.state.labels.order_info}</p>
                </div>
              </>
            )}
            {this.state.formInformation.quoteChecked && (
              <div>
                <p>{this.state.labels.quote_downloaded}</p>
              </div>
            )}
          </div>
        )}
        <br></br>
      </Stack>
    );
  }
}

export default withParams(Arjo);
