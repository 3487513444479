import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import UploadFile from "@mui/icons-material/UploadFile";
import InputText from "../../Fields/InputText";
import debounce from "lodash.debounce";

import "../css/Identification.css";
import InputDate from "../../Fields/InputDate";
import { format, isValid } from "date-fns";

const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];
const formColor = { hcs: "#273A60", arjo: "#0F5376" };

export default class CustomerInformationIdentification extends React.Component {
  static propTypes = {
    idNumber: PropTypes.string,
    customerInformation: PropTypes.object,
    formInformation: PropTypes.object,
    labels: PropTypes.object,
    params: PropTypes.object,
    extraFiles: PropTypes.array,
    changeHandler: PropTypes.func,
    checkHandler: PropTypes.func,
    addExtraFiles: PropTypes.func,
    removeExtraFiles: PropTypes.func,
    formType: PropTypes.string,
    productsValues: PropTypes.object,
  };

  state = {
    idNumber: false,
    dateOfBirth: socialSecurityNumberCountries.includes(
      this.props.params.lang.toLowerCase()
    ),
    socialSecurityNumber: !socialSecurityNumberCountries.includes(
      this.props.params.lang.toLowerCase()
    ),
  };

  componentDidMount() {
    let newState = this.state;
    for (let key in newState) {
      if (
        this.props.customerInformation[key] !== undefined &&
        this.props.customerInformation[key] !== "" &&
        this.props.customerInformation[key] !== null
      ) {
        newState[key] = true;
      }
    }
    if (this.props.params.lang !== "at") {
      newState["idNumber"] = true;
    }
    if (this.props.formInformation.customer === "Business") {
      newState["idNumber"] = true;
      newState["socialSecurityNumber"] = true;
      newState["dateOfBirth"] = true;
      this.isFinished();
    }
    this.setState(newState);
  }

  isFinished = debounce(() => {
    const data = {
      identificationFinished: Object.values(this.state).every((finished) => {
        return finished;
      }),
    };
    this.props.changeHandler(data);
  }, 250);

  handleChange = (value, id) => {
    let newState = this.state;
    let data = {};

    if (id === "dateOfBirth" && isValid(value)) {
      const formattedDate = value ? format(value, "dd/MM/yyyy") : "";
      data["socialSecurityNumber"] = formattedDate;
      newState["dateOfBirth"] = true;
    }
    data[id] = value;
    this.props.changeHandler(data);
    this.isFinished();
    this.setState(newState);
  };

  handleChecked = (event) => {
    this.props.checkHandler(event);
  };

  handleAddfile = (event) => {
    let files = [];
    const allowedTypes = ["image/jpeg", "application/pdf", "image/png"];
    const maxFileSize = 5 * 1024 * 1024;
    for (let i = 0; i < event.target.files.length; i++) {
      if (!allowedTypes.includes(event.target.files[i].type)) {
        alert(this.props.labels.wrong_filetype);
        continue;
      }
      if (event.target.files[i].size > maxFileSize) {
        alert(this.props.labels.large_file);
        continue;
      }
      if (files.length + this.props.extraFiles.length > 4) {
        alert(this.props.labels.too_many_files);
        continue;
      }
      if (
        !this.props.extraFiles.some(
          (file) => file.name === event.target.files[i].name
        )
      ) {
        let element = event.target.files[i];
        files.push(element);
      } else {
        alert(this.props.labels.duplicate_filename);
      }
    }
    this.props.addExtraFiles(files);
    event.target.value = null;
    this.isFinished();
  };

  handleRemoveFile = (file) => {
    this.props.removeExtraFiles(file);
  };

  render() {
    const showRepurchase =
      this.props.params.formType.toLowerCase() === "leaseplus" &&
      this.props.params.comp.toLowerCase() === "hcs" &&
      Object.values(this.props.productsValues).some(
        (obj) => obj.name && obj.name.toLowerCase().includes("automower")
      );
    return (
      <Grid container spacing={2}>
        {this.props.formInformation.customer === "Private" && (
          <>
            {this.props.params.lang.toLowerCase() !== "at" ? null : (
              <Grid item xs={4}>
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>
                      {this.props.labels.tooltip_id_select}
                    </span>
                  }
                  placement="top"
                >
                  <TextField
                    className="id_select"
                    value={this.props.customerInformation.idType}
                    name={"idType"}
                    onChange={(event) => {
                      this.handleChange(event.target.value, event.target.name);
                    }}
                    select
                    label={this.props.labels.label_id_type + " *"}
                  >
                    {this.props.labels.id_select.map((option) => {
                      return (
                        <MenuItem value={option} key={option} id="test">
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Tooltip>
              </Grid>
            )}
            {this.props.params.lang.toLowerCase() !== "at" ? null : (
              <Grid item xs={4}>
                <InputText
                  id="idNumber"
                  label={this.props.labels.label_id_number}
                  changeHandler={this.handleChange}
                  defaultValue={this.props.customerInformation.idNumber}
                  tooltip={this.props.labels.tooltip_label_id_number}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.idNumber
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
            )}
            {this.props.params.lang.toLowerCase() !== "at" ? null : (
              <Grid item xs={4}>
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>
                      {this.props.labels.tooltip_pep}
                    </span>
                  }
                  placement="top"
                >
                  <TextField
                    className="id_select"
                    value={this.props.customerInformation.pep}
                    name={"pep"}
                    onChange={(event) => {
                      this.handleChange(event.target.value, event.target.name);
                    }}
                    select
                    label={this.props.labels.pep_label + " *"}
                  >
                    {this.props.labels.pep.map((option) => {
                      return (
                        <MenuItem value={option} key={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={6}>
              {socialSecurityNumberCountries.includes(
                this.props.params.lang.toLowerCase()
              ) ? (
                <InputText
                  id={"socialSecurityNumber"}
                  label={this.props.labels.social_security_number}
                  changeHandler={this.handleChange}
                  defaultValue={
                    this.props.customerInformation.socialSecurityNumber
                  }
                  required={true}
                  sx={{ width: "100%" }}
                  tooltip={this.props.labels.tooltip_social_security_number}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.socialSecurityNumber
                      ? "invalid"
                      : ""
                  }
                />
              ) : (
                <InputDate
                  id={"dateOfBirth"}
                  label={this.props.labels.social_security_number}
                  changeHandler={this.handleChange}
                  date={this.props.customerInformation.dateOfBirth}
                  tooltip={this.props.labels.tooltip_date_of_birth}
                  required={true}
                  sx={{ width: "100%" }}
                  maxDate={new Date()}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.dateOfBirth
                      ? "invalid"
                      : ""
                  }
                />
              )}
            </Grid>
            {showRepurchase ? (
              <Grid item xs={12}>
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>
                      {this.props.labels.tooltip_repurchase}
                    </span>
                  }
                  placement="top"
                >
                  <TextField
                    className="repurchase_select"
                    value={this.props.customerInformation.repurchase}
                    name={"repurchase"}
                    onChange={(event) => {
                      this.handleChange(event.target.value, event.target.name);
                    }}
                    select
                    label={this.props.labels.repurchase_label}
                    helperText={this.props.labels.repurchase_helper}
                  >
                    {[
                      this.props.labels.dealer_repurchase,
                      this.props.labels.hcs_repurchase,
                    ].map((option) => {
                      return (
                        <MenuItem value={option} key={option} id="test">
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Tooltip>
              </Grid>
            ) : null}
          </>
        )}
        <Grid item xs={12}>
          <Tooltip
            title={
              <span style={{ fontSize: "12px" }}>
                {this.props.labels.tooltip_id_check}
              </span>
            }
            placement="top"
          >
            <FormControlLabel
              className="id_checked"
              control={
                <Checkbox
                  id="id_checked"
                  checked={this.props.formInformation.idChecked}
                  onChange={this.handleChecked}
                />
              }
              label={this.props.labels.id_check + " *"}
              sx={{ width: "100%" }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="left">
            <Button
              variant="contained"
              component="label"
              sx={{
                backgroundColor:
                  formColor[this.props.params.comp.toLowerCase()],
                marginBottom: 0.5,
              }}
              startIcon={<UploadFile />}
            >
              {this.props.labels.upload_extra_files}
              <input
                type="file"
                accept="image/jpeg, application/pdf, image/png"
                multiple
                hidden
                onChange={this.handleAddfile}
              />
            </Button>
            <Typography variant="caption" display="block" gutterBottom>
              <strong>{this.props.labels.must_attach}</strong>
            </Typography>
          </Box>
          <div id="fileContents">
            {this.props.extraFiles.length > 0 && (
              <p>{this.props.labels.uploaded_files} </p>
            )}
            {this.props.extraFiles.map((file) => {
              return (
                <Button
                  variant="text"
                  className="file"
                  id={file.name}
                  key={file.name}
                  onClick={() => {
                    this.handleRemoveFile(file);
                  }}
                >
                  {file.name}
                </Button>
              );
            })}
          </div>
        </Grid>
        {this.props.params.lang === "fr" && (
          <div style={{ color: "red", fontSize: "large", fontWeight: "bold" }}>
            <p>
              <span>
                Attention ! Le client s'inscrit au paiement par prélèvement
                automatique via le lien sur la première facture. Ne pas envoyer
                le RIB au client.
              </span>
            </p>
            {this.props.params.formType === "leaseplus" && (
              <p>
                <span>
                  La facture d'électricité ou de téléphone du client doit être
                  téléchargée pour pouvoir envoyer la demande à HCS.
                </span>
              </p>
            )}
          </div>
        )}
      </Grid>
    );
  }
}
