import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ProductList from "./Fields/ProductList";

export default class LeasePlusAccessory extends React.Component {
  static propTypes = {
    accessory: PropTypes.string,
    accessories: PropTypes.array,
    labels: PropTypes.object,
    handleChange: PropTypes.func,
    accessoryId: PropTypes.number,
    id: PropTypes.string,
    products: PropTypes.array,
    formInformation: PropTypes.object,
    productValues: PropTypes.object,
  };

  /**
   *
   * @param {string} value
   * @param {string} id
   *
   * Updates chosen accessory
   */
  handleChange = (value, id) => {
    this.props.accessories[this.props.accessoryId] = value;
    this.props.handleChange(this.props.accessories, id);
  };

  finished = (fieldId, id) => {
    switch (fieldId) {
      case "accessory":
        return (
          this.props.productValues.accessory.length <= 1 ||
          this.props.accessory !== null
        );
      default:
        return -1;
    }
  };

  render() {
    return (
      <Grid item xs={4}>
        <ProductList
          id={this.props.id + "_accessory"}
          changeHandler={this.handleChange}
          defaultValue={this.props.accessory}
          label={this.props.labels.choose_an_accessory}
          products={this.props.products}
          tooltip={this.props.labels.tooltip_product_list}
          className={
            this.props.formInformation.showInvalid &&
            !this.finished("accessory", this.props.id)
              ? "invalid"
              : ""
          }
        />
      </Grid>
    );
  }
}
