import React from "react";
import "./css/InformationBox.css";
import PropTypes from "prop-types";

export default class InformationBox extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    ceoraCampaign: PropTypes.bool,
  };
  render() {
    return (
      <div className="informationbox_wrapper">
        {this.props.ceoraCampaign ? (
          <div
            className="ceora-information"
            dangerouslySetInnerHTML={{
              __html: this.props.content,
            }}
          />
        ) : (
          <div className="informationbox_content">
            <p className="text-margin">
              {this.props.content !== undefined ? this.props.content : ""}
            </p>
          </div>
        )}
      </div>
    );
  }
}
